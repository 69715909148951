import React from "react";
import MuiModal from "@mui/material/Modal";

import { Body, Footer, Header } from "./components";
import { useLive } from "./hooks";

import styles from "./style.module.scss";

export const LiveComponent = React.memo(() => {
  const { getModalProps, values } = useLive();
  return (
    <MuiModal {...getModalProps()}>
      <div className={styles[values.content]}>
        <Header />
        <Body />
        <Footer />
      </div>
    </MuiModal>
  );
});

LiveComponent.displayName = "LiveComponent";

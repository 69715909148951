import { createSelector } from "reselect";

import { RootState } from "src/types";

/**
 * Селекторы v3
 */

/**
 * v3
 * @param state - хранилище
 * @returns хранилище v3
 */
const getRoot = (state: RootState) => state.v3;

/**
 * v3 идентификатор текущей игры
 */
export const getViewId = createSelector([getRoot], (root) => root.viewId);

import React from "react";
import range from "lodash/range";
import cn from "classnames";

import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Carousel } from "react-responsive-carousel";

import { useCombined } from "./hooks";

import "./style.scss";

import styles from "./style.module.scss";

const SLIDES = () =>
  range(1, 7).map((slide) => (
    <div key={slide} className={styles[`slide-${slide}`]} />
  ));

export const CombinedComponent = React.memo(() => {
  const { getButtonProps, values } = useCombined();

  const renderArrowNext: (
    clickHandler: () => void,
    hasNext: boolean
  ) => React.ReactNode = (onClick, hasNext) =>
    hasNext && <button className={styles.next} onClick={onClick} />;

  const renderArrowPrev: (
    clickHandler: () => void,
    hasPrev: boolean
  ) => React.ReactNode = (onClick, hasPrev) =>
    hasPrev && <button className={styles.prev} onClick={onClick} />;

  return (
    <div className={cn(styles[values.content], styles[values.theme])}>
      <button {...getButtonProps(styles.close)} />
      <Carousel
        showStatus={false}
        showThumbs={false}
        renderArrowNext={renderArrowNext}
        renderArrowPrev={renderArrowPrev}
      >
        {SLIDES()}
      </Carousel>
    </div>
  );
});

CombinedComponent.displayName = "CombinedComponent";

import { createEntityAdapter } from "@reduxjs/toolkit";

import { Bet, Car, Game } from "src/types";

export const betsAdapter = createEntityAdapter<Bet>({
  selectId: (bet) => bet.uuid,
});

export const carsAdapter = createEntityAdapter<Car>({
  selectId: (car) => car.uuid,
});

export const gamesAdapter = createEntityAdapter<Game>({
  selectId: (game) => game.id,
});

import { createSelector } from "reselect";

import { getCars } from "src/selectors";

export const createGetCars = (gameId: number) =>
  createSelector([getCars], (cars) => cars.filter((car) => car.gameId === gameId));

export const createGetLap = (gameId: number) =>
  createSelector([createGetCars(gameId)], (cars) => {
    const сarsProgress = cars.map((car) => car.progress);
    const max = Math.max.apply(null, сarsProgress);

    if (max <= 33) {
      return 1;
    } else if (max > 33 && max <= 66) {
      return 2;
    } else {
      return 3;
    }
  });

import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { getMarket, getTheme } from "src/selectors";
import { useContent, useWindowSize } from "src/hooks";

import styles from "./style.module.scss";

export const useBackground = () => {
  const content = useContent();

  const market = useSelector(getMarket);
  const theme = useSelector(getTheme);

  const [height] = useWindowSize();

  const getBackgroundProps = React.useCallback(
    () => ({
      className: cn(styles[content], styles[theme]),
      style: { height: `calc(${height}px - 7rem)` } as React.CSSProperties,
    }),
    [content, height, theme]
  );

  const values = React.useMemo(
    () => ({
      content,
      market,
      styles,
    }),
    [content, market]
  );

  return { getBackgroundProps, values };
};

import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { createGetBetsByParams, getNextId } from "src/selectors";
import { sort } from "./utils";
import { useContent } from "src/hooks";

import styles from "./styles.module.scss";

export const useMobile = () => {
  const content = useContent();

  const nextId = useSelector(getNextId);

  const personal = useSelector(createGetBetsByParams([nextId], ["personal"], []));
  const tops = useSelector(createGetBetsByParams([nextId], ["top2", "top3"], []));

  const getBetsProps = React.useCallback(
    (index: number) => ({
      className: cn(styles.bets, {
        [styles.tops]: index === 2,
      }),
    }),
    []
  );

  const values = React.useMemo(
    () => ({
      content,
      styles,
      results: sort([...personal, ...tops]),
    }),
    [content, personal, tops]
  );

  return { getBetsProps, values };
};

import { Dispatch, Update } from "@reduxjs/toolkit";

import { Car } from "src/types";
import { coreActions } from "src/slices";

export const resetCar = (car: Car) => (dispatch: Dispatch) => {
  const update: Update<Car> = {
    id: car.uuid,
    changes: { status: "none", progress: 0, position: car.line },
  };

  dispatch(coreActions.carUpdated(update));
};

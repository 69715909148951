import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { AudioContext } from "src/contexts";
import { getTheme } from "src/selectors";
import { metrikaActions } from "src/actions";
import { tutorialActions } from "src/slices";
import { useAppDispatch } from "src/hooks";

import styles from "./style.module.scss";

export const useButton = () => {
  const dispatch = useAppDispatch();

  const theme = useSelector(getTheme);

  const { play } = React.useContext(AudioContext);

  const onClick = React.useCallback(() => {
    play("control");
    dispatch(tutorialActions.tutorialUpdated(true));
    dispatch(metrikaActions.send({ target: "TutorialClick" }));
  }, [dispatch, play]);

  const getButtonProps = React.useCallback(
    () => ({
      className: cn(styles.button, styles[theme]),
      onClick,
    }),
    [onClick, theme]
  );

  return { getButtonProps };
};

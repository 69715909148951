import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { createGetBetsByParams, getTheme } from "src/selectors";
import { getLocale } from "src/utils";
import { getText } from "src/components/total/model";
import { Props } from "./types";
import { useContent } from "src/hooks";

import styles from "./style.module.scss";

export const useTotal = (props: Props) => {
  const content = useContent();

  const theme = useSelector(getTheme);

  const bets = useSelector(
    createGetBetsByParams(
      [props.game.id],
      [],
      ["confirmed", "finish", "pending", "selected"]
    )
  );

  const isActive = bets.length > 0;
  const isWin = !!props.game.win;

  const getTotalProps = React.useCallback(
    () => ({
      className: cn(styles[content], styles[theme]),
    }),
    [content, theme]
  );

  const getCountProps = React.useCallback(
    () => ({
      className: cn(styles.count, {
        [styles.active]: isActive,
        [styles.hidden]: isWin,
      }),
    }),
    [isActive, isWin]
  );

  const getTextProps = React.useCallback(
    () => ({
      className: cn(styles.text, {
        [styles.active]: isActive,
      }),
    }),
    [isActive]
  );

  const getWinProps = React.useCallback(
    () => ({
      className: cn(styles.win, {
        [styles.hidden]: !isWin,
      }),
    }),
    [isWin]
  );

  const count = bets.length;
  const text = getText(count, isWin, isActive);
  const win = getLocale(props.game.win, 1);

  const values = React.useMemo(
    () => ({
      bets,
      content,
      count,
      isWin,
      styles,
      text,
      win,
    }),
    [bets, content, count, isWin, text, win]
  );

  return { getCountProps, getTextProps, getTotalProps, getWinProps, values };
};

import { Bet } from "src/types";
import { RACERS } from "src/constants";

export const sort = (bets: Array<Bet>) => {
  const result: Array<Bet> = [];

  RACERS.forEach((racer) => {
    const top1 = bets.find((bet) => bet.type === "top1" && bet.racers[0] === racer);
    const top2 = bets.find((bet) => bet.type === "top2" && bet.racers[0] === racer);
    const top3 = bets.find((bet) => bet.type === "top3" && bet.racers[0] === racer);
    [top1, top2, top3].forEach((top) => {
      if (top) {
        result.push(top);
      }
    });
  });

  return result;
};

import React from "react";

import { useTechBreak } from "./hooks";

export const TechBreakComponent = React.memo(() => {
  const { getTechBreakProps } = useTechBreak();
  return (
    <div {...getTechBreakProps()}>
      <span>ДАЛЕЕ ТЕХ. ПЕРЕРЫВ</span>
      <span>СКОРО ВЕРНЕМСЯ</span>
    </div>
  );
});

TechBreakComponent.displayName = "TechBreakComponent";

import React from "react";

import { animated } from "@react-spring/web";

import { Body, Header } from "./components";
import { useCoupons } from "./hooks";

export const CouponsComponent = React.memo(() => {
  const { getCouponsProps, values } = useCoupons();

  let view;

  switch (values.content) {
    case "desktop":
      view = (
        <div className={values.styles[values.content]}>
          <Header />
          <Body />
        </div>
      );
      break;
    case "mobile":
    case "laptop":
      view = (
        <animated.div {...getCouponsProps()}>
          <Header />
          <Body />
        </animated.div>
      );
      break;
    default:
      view = null;
      break;
  }

  return view;
});

CouponsComponent.displayName = "CouponsComponent";

import React from "react";

import { useSelector } from "react-redux";

import { createGetBetsByParams, getNextId } from "src/selectors";
import { sort } from "./utils";

import styles from "./styles.module.scss";

export const useDefault = () => {
  const nextId = useSelector(getNextId);

  const tops = useSelector(
    createGetBetsByParams([nextId], ["top1", "top2", "top3"], [])
  );

  const values = React.useMemo(
    () => ({
      styles,
      tops: sort(tops),
    }),
    [tops]
  );

  return { values };
};

import React from "react";

import { useSelector } from "react-redux";
import { useSpringValue } from "@react-spring/web";

import { createGetBetsByParams, getError, getNextId } from "src/selectors";
import { createGetOffsetDesktop } from "src/components/speedometer/model";
import { useAnimationFrame } from "src/hooks";

import styles from "./style.module.scss";

export const useDefault = () => {
  const error = useSelector(getError);
  const nextId = useSelector(getNextId);

  const bets = useSelector(createGetBetsByParams([nextId], [], []));
  const offset = useSelector(createGetOffsetDesktop(bets));

  const frustration = useFrustration(offset);

  const offsetDistance = React.useMemo(() => 100 - frustration * 100, [frustration]);

  const offsetDistanceValue = useSpringValue("100%");
  const offsetRotateValue = useSpringValue("auto 85deg");
  const strokeDasharrayValue = useSpringValue(1);
  const strokeDashoffsetValue = useSpringValue(-1);

  offsetDistanceValue.start(`${offsetDistance}%`, {
    config: {
      duration: 100,
    },
  });
  offsetRotateValue.start(`auto ${offsetDistance < 60 ? 95 : 85}deg`, {
    config: {
      duration: 100,
    },
  });
  strokeDasharrayValue.start(1);
  strokeDashoffsetValue.start((1 - frustration) * -1, {
    config: {
      duration: 100,
    },
  });

  React.useEffect(() => {
    offsetDistanceValue.start(`${offsetDistance}%`, {
      config: {
        duration: 100,
      },
    });
    offsetRotateValue.start(`auto ${offsetDistance < 60 ? 95 : 85}deg`, {
      config: {
        duration: 100,
      },
    });
    strokeDashoffsetValue.start((1 - frustration) * -1, {
      config: {
        duration: 100,
      },
    });
  }, [
    frustration,
    offsetDistance,
    offsetDistanceValue,
    offsetRotateValue,
    strokeDashoffsetValue,
  ]);

  const getGradientProps = React.useCallback(
    () =>
      ({
        stopColor: error !== "none" ? "#D02D3C" : "#3CE976",
      } as React.SVGProps<SVGStopElement>),
    [error]
  );

  const getIndicatorProps = React.useCallback(
    () => ({
      style: {
        offsetDistance: offsetDistanceValue,
        offsetRotate: offsetRotateValue,
      },
    }),
    [offsetDistanceValue, offsetRotateValue]
  );

  const getPathProps = React.useCallback(
    () => ({
      style: {
        strokeDasharray: strokeDasharrayValue,
        strokeDashoffset: strokeDashoffsetValue,
      },
    }),
    [strokeDasharrayValue, strokeDashoffsetValue]
  );

  const values = React.useMemo(
    () => ({
      styles,
    }),
    []
  );

  return { getGradientProps, getIndicatorProps, getPathProps, values };
};

export const useFrustration = (offset: number) => {
  const [frustration, setFrustration] = React.useState(offset);
  const [fps, setFps] = React.useState(15);

  React.useEffect(() => {
    if (offset === 0) {
      setFps(15);
    }
    if (offset === 0.9) {
      setFps(60);
    }
  }, [offset]);

  const animationHandler = React.useCallback(() => {
    const random =
      offset === 0 || offset === 1
        ? offset
        : offset === 0.9
        ? offset + Math.random() * 0.1
        : offset - Math.random() * 0.05;
    setFrustration(random);
  }, [offset]);

  useAnimationFrame(animationHandler, fps, offset);

  return frustration;
};

import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { betsAddedByTypeThunk } from "src/thunks";
import {
  createGetBetsByParams,
  createGetIsAnyGame,
  getNextId,
  getTheme,
  getViewId,
} from "src/selectors";
import { createGetTypes, getIsPlaying } from "src/widgets/markets/model";
import { sort } from "./utils";
import { Type } from "src/types";
import { useAppDispatch } from "src/hooks";

import styles from "./styles.module.scss";

export const useV3 = () => {
  const dispatch = useAppDispatch();

  const isPlaying = useSelector(getIsPlaying);
  const nextId = useSelector(getNextId);
  const viewId = useSelector(getViewId);
  const theme = useSelector(getTheme);

  const bets = useSelector(createGetBetsByParams([viewId], ["top2", "top3"], []));
  const isNextTechBreak = useSelector(createGetIsAnyGame([nextId], ["tech_break"]));
  const types = useSelector(createGetTypes(viewId));

  const getMarketProps = React.useCallback(
    () => ({
      className: cn(styles.market, styles[theme]),
    }),
    [theme]
  );

  const getButtonProps = React.useCallback(
    (type: Type) => ({
      className: cn(styles[type], {
        [styles.light]: !isPlaying || types.includes(type),
      }),
      onClick: () => dispatch(betsAddedByTypeThunk(type)),
      disabled: isNextTechBreak,
    }),
    [dispatch, isNextTechBreak, isPlaying, types]
  );

  const getLimitProps = React.useCallback(
    () => ({
      className: cn(styles.limit, { [styles.filter]: isPlaying }),
    }),
    [isPlaying]
  );

  const { top2, top3 } = sort(bets);

  const values = React.useMemo(
    () => ({
      styles,
      top2,
      top3,
    }),
    [top2, top3]
  );

  return { getButtonProps, getLimitProps, getMarketProps, values };
};

import { createSelector } from "reselect";

import { RootState } from "src/types";

/**
 * Селекторы модального окна
 */

/**
 * modal
 * @param state - хранилище
 * @returns хранилище modal
 */
const getRoot = (state: RootState) => state.modal;

/**
 * Селектор получения типа модального окна
 */
export const getModal = createSelector([getRoot], (root) => root.modal);

import { CupType } from "./types";

/* NEW CUPS */
import Cup1 from "src/assets/icons/cup-1-updated.svg";
import Cup2 from "src/assets/icons/cup-2-updated.svg";
import Cup3 from "src/assets/icons/cup-3-updated.svg";
import Cup4 from "src/assets/icons/cup-4-updated.svg";
import Cup5 from "src/assets/icons/cup-5-updated.svg";
import Cup6 from "src/assets/icons/cup-6-updated.svg";
import Cups2 from "src/assets/icons/cups-2-updated.svg";
import Cups2Empty from "src/assets/icons/cups-2-empty-updated.svg";
import Cups3 from "src/assets/icons/cups-3-updated.svg";
import Cups3Empty from "src/assets/icons/cups-3-empty-updated.svg";
import CupsCompetition from "src/assets/icons/cups-competition-updated.svg";
import Sum12 from "src/assets/icons/sum-1-2-updated.svg";
import Sum12Empty from "src/assets/icons/sum-1-2-empty-updated.svg";
import Sum123 from "src/assets/icons/sum-1-2-3-updated.svg";
import Sum123Empty from "src/assets/icons/sum-1-2-3-empty-updated.svg";

/* OLD CUPS */
import Cup1Old from "src/assets/icons/cup-1.svg";
import Cup2Old from "src/assets/icons/cup-2.svg";
import Cup3Old from "src/assets/icons/cup-3.svg";
import Cup4Old from "src/assets/icons/cup-4.svg";
import Cup5Old from "src/assets/icons/cup-5.svg";
import Cup6Old from "src/assets/icons/cup-6.svg";
import Cups2Old from "src/assets/icons/cups-2.svg";
import Cups3Old from "src/assets/icons/cups-3.svg";
import Sum12Old from "src/assets/icons/sum-1-2.svg";
import Sum123Old from "src/assets/icons/sum-1-2-3.svg";

export const NEW_CUPS: Record<CupType, string> = {
  competition: CupsCompetition,
  cup1: Cup1,
  cup2: Cup2,
  cup3: Cup3,
  cup4: Cup4,
  cup5: Cup5,
  cup6: Cup6,
  cups2: Cups2,
  cups2empty: Cups2Empty,
  cups3: Cups3,
  cups3empty: Cups3Empty,
  sum12: Sum12,
  sum12empty: Sum12Empty,
  sum123: Sum123,
  sum123empty: Sum123Empty,
};

export const OLD_CUPS: Record<CupType, string> = {
  competition: CupsCompetition,
  cup1: Cup1Old,
  cup2: Cup2Old,
  cup3: Cup3Old,
  cup4: Cup4Old,
  cup5: Cup5Old,
  cup6: Cup6Old,
  cups2: Cups2Old,
  cups2empty: Cups2Empty,
  cups3: Cups3Old,
  cups3empty: Cups3Empty,
  sum12: Sum12Old,
  sum12empty: Sum12Empty,
  sum123: Sum123Old,
  sum123empty: Sum123Empty,
};

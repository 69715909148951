import React from "react";

import { useSelector } from "react-redux";
import { useTransition } from "@react-spring/web";

import { getTutorial } from "src/selectors";
import { useContent } from "src/hooks";

export const useTutorial = () => {
  const content = useContent();

  const tutorialTransition = useTutorialTransition();

  const values = React.useMemo(
    () => ({
      content,
      tutorial: tutorialTransition,
    }),
    [content, tutorialTransition]
  );

  return { values };
};

export const useTutorialTransition = () => {
  const tutorial = useSelector(getTutorial);

  return useTransition(tutorial, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });
};

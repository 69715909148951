import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { AudioContext, CouponsSwipeContext } from "src/contexts";
import { getView } from "src/selectors";
import { useContent } from "src/hooks";

import styles from "./style.module.scss";

export const useControls = () => {
  const content = useContent();

  const view = useSelector(getView);

  const { play } = React.useContext(AudioContext);
  const { onClose } = React.useContext(CouponsSwipeContext);

  const getHideProps = React.useCallback(
    () => ({
      className: cn(styles.hide),
      onClick: () => {
        play("tab");
        onClose();
      },
    }),
    [onClose, play]
  );

  const getBetWrapperProps = React.useCallback(
    () => ({
      className: cn(styles.wrapper, {
        [styles.hidden]: view === "curr",
      }),
    }),
    [view]
  );

  const values = React.useMemo(
    () => ({
      content,
      styles,
    }),
    [content]
  );

  return { getBetWrapperProps, getHideProps, values };
};

import find from "lodash/find";

import { createSelector } from "reselect";

import { Bet, Quotation, Type } from "src/types";
import { createGetBetsByParams } from "src/selectors";
import { getHashCode } from "src/slices";

export const createGetMaxMinRate = (bet: Bet, types: Array<Type>) =>
  createSelector([createGetBetsByParams([bet.gameId], types, [])], (bets) => {
    const rates = bets.map((bet) => bet.rate);
    const max = Math.max.apply(null, rates);
    const min = Math.min.apply(null, rates);
    return [max, min];
  });

export const createGetQuotation = (bet: Bet) =>
  createSelector([createGetBetsByParams([], [], [])], (bets): Quotation => {
    const gameId = bet.gameId - 1;
    const hashCode = getHashCode(gameId, bet.type, bet.places, bet.racers);
    const prev = find(bets, { hashCode });
    const odd = bet.rate;
    const old = prev?.rate || 0;
    return !odd || !old
      ? Quotation.None
      : odd > old
      ? Quotation.Up
      : odd < old
      ? Quotation.Down
      : Quotation.None;
  });

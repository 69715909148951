import React from "react";

import { animated } from "@react-spring/web";

import { useLoader } from "./hooks";

import styles from "./style.module.scss";

import Title from "src/app/assets/images/title.svg";

export const LoaderComponent = () => {
  const { values } = useLoader();
  return values.loader(
    (style, open) =>
      open && (
        <animated.div className={styles.loader} style={style}>
          <div className={styles.sprite} />
          <img src={Title} />
        </animated.div>
      )
  );
};

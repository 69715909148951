import { Bet } from "src/types";
import { getLocale } from "src/utils";

export const getText = (count: number, isWin: boolean, isActive: boolean) => {
  if (isWin) {
    return "ВЫИГРЫШ НА СУММУ";
  }
  if (isActive) {
    return getLabel(count, ["СТАВОК", "СТАВКИ", "СТАВКА", "СТАВОК"]);
  }
  return "СТАВОК НЕТ";
};

export const getLabel = (count: number, declinations: Array<string>) => {
  const n = Math.abs(count) % 100;
  const n1 = n % 10;
  if (n > 10 && n < 20) {
    return declinations[0];
  }
  if (n1 > 1 && n1 < 5) {
    return declinations[1];
  }
  if (n1 === 1) {
    return declinations[2];
  }
  return declinations[3];
};

export const getBalances = (bets: Array<Bet>) => {
  const result: Array<{ amount: string; id: number }> = [];

  const balancesById = bets.reduce(
    (all, { amount, balanceId }) => ({
      ...all,
      [balanceId]: amount + (all[balanceId] || 0),
    }),
    {} as Record<number, number>
  );

  for (const balanceId in balancesById) {
    const balance = balancesById[balanceId];

    if (balance) {
      const id = +balanceId;
      const amount = getLocale(balance, id);
      result.push({ amount, id });
    }
  }
  return result;
};

import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { AppDispatch, Content } from "./types";
import { getContent } from "./selectors";

export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAnimationFrame = (
  animationHandler: () => void,
  fps = 60,
  deps: unknown
) => {
  const frameRef = React.useRef(0);
  const lastFrameTimeRef = React.useRef(performance.now());

  const fpsInterval = 1000 / fps;

  const animate = React.useCallback(() => {
    const now = performance.now();
    const elapsed = now - lastFrameTimeRef.current;

    if (elapsed > fpsInterval) {
      animationHandler();
      lastFrameTimeRef.current = now;
    }

    frameRef.current = requestAnimationFrame(animate);
  }, [animationHandler, fpsInterval]);

  React.useEffect(() => {
    frameRef.current = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(frameRef.current);
  }, [animate, fps, deps]);
};

export const useWindowSize = () => {
  const [size, setSize] = React.useState<Array<number>>([
    window.innerHeight,
    window.innerWidth,
  ]);

  React.useLayoutEffect(() => {
    const updateSize = () => setSize([window.innerHeight, window.innerWidth]);
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return size;
};

export const useContent = () => {
  let content = useSelector(getContent);

  const isMobile = useMediaQuery({ maxAspectRatio: "4/6" });
  const isMobileDevice = new RegExp(
    /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i
  ).test(navigator.userAgent);

  if (isMobile || isMobileDevice) {
    content = "mobile";
  }

  const isLaptop = useMediaQuery({ maxAspectRatio: "1/1", minAspectRatio: "4/6" });

  if (isLaptop) {
    content = "laptop";
  }

  return content as Content;
};

export const useClickOutsideRef = (callback: () => void) => {
  const ref = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const cancelHandler = (event: MouseEvent) => {
      const target = event && (event.target as Element);
      if (ref.current && !ref.current.contains(target)) {
        callback();
      }
    };

    document.addEventListener("mousedown", cancelHandler);

    return () => document.removeEventListener("mousedown", cancelHandler);
  }, [callback]);

  return ref;
};

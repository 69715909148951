import React from "react";

import { animated } from "@react-spring/web";

import { Props } from "./types";
import { useMarketsSwipe } from "./hooks";

export const SwipeComponent = (props: Props) => {
  const { getSwipeProps } = useMarketsSwipe(props);
  return <animated.div {...getSwipeProps()}>{props.children}</animated.div>;
};

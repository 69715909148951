import React from "react";

import { config, useTransition } from "@react-spring/web";
import { useSelector } from "react-redux";

import { createGetCarsByGameId, getGameId } from "src/selectors";

import styles from "./style.module.scss";

export const useCars = () => {
  const carsTransition = useCarsTransition();

  const values = React.useMemo(
    () => ({
      cars: carsTransition,
      styles,
    }),
    [carsTransition]
  );

  return { values };
};

export const useCarsTransition = () => {
  const gameId = useSelector(getGameId);

  const cars = useSelector(createGetCarsByGameId(gameId));

  const carsSorted = [...cars].sort((a, b) => a.position - b.position);

  return useTransition(
    carsSorted.map((cars, index) => {
      const y = `${index * 100}%`;
      return { ...cars, y };
    }),
    {
      keys: (line) => line.line,
      enter: ({ y }) => ({ y }),
      update: ({ y }) => ({ y }),
      config: { ...config.default, duration: 500 },
    }
  );
};

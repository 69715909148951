import React from "react";

import { useSelector } from "react-redux";
import { useSound } from "use-sound";

import { getVolume } from "src/selectors";
import { Sound } from "./types";

import Amount from "src/shared/assets/sound/amount.mp3";
import Bet from "src/shared/assets/sound/bet.mp3";
import Control from "src/shared/assets/sound/control.mp3";
import Error from "src/shared/assets/sound/error.mp3";
import Odd from "src/shared/assets/sound/odd.mp3";
import Step from "src/shared/assets/sound/step.mp3";
import Success from "src/shared/assets/sound/success.mp3";
import Tab from "src/shared/assets/sound/tab.mp3";
import Win from "src/shared/assets/sound/win.mp3";

export const useAudio = () => {
  const volume = useSelector(getVolume);

  const [playAmount] = useSound(Amount, { volume });
  const [playBet] = useSound(Bet, { volume });
  const [playControl] = useSound(Control, { volume });
  const [playError] = useSound(Error, { volume });
  const [playOdd] = useSound(Odd, { volume });
  const [playStep] = useSound(Step, { volume });
  const [playSuccess] = useSound(Success, { volume });
  const [playTab] = useSound(Tab, { volume });
  const [playWin] = useSound(Win, { volume });

  const play = React.useCallback(
    (type: Sound) => {
      switch (type) {
        case "amount":
          playAmount();
          break;
        case "bet":
          playBet();
          break;
        case "control":
          playControl();
          break;
        case "error":
          playError();
          break;
        case "odd":
          playOdd();
          break;
        case "step":
          playStep();
          break;
        case "success":
          playSuccess();
          break;
        case "tab":
          playTab();
          break;
        case "win":
          playWin();
          break;
      }
    },
    [
      playAmount,
      playBet,
      playControl,
      playError,
      playOdd,
      playStep,
      playSuccess,
      playTab,
      playWin,
    ]
  );

  const getProviderProps = React.useCallback(
    () => ({
      value: {
        play,
      },
    }),
    [play]
  );

  return { getProviderProps };
};

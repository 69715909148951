import React from "react";

import { useSelector } from "react-redux";
import { useTransition } from "@react-spring/web";

import { createGetBetsByParams, getGameId, getKind } from "src/selectors";
import { Props } from "./types";
import { useContent } from "src/hooks";

import styles from "./style.module.scss";

export const useList = (props: Props) => {
  const content = useContent();

  const scrollRef = React.useRef<HTMLDivElement>(null);

  const gameId = useSelector(getGameId);
  const kind = useSelector(getKind);

  React.useEffect(() => {
    if (gameId === props.gameId && scrollRef.current) {
      switch (kind) {
        case "race_start":
        case "race_finish": {
          scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
          break;
        }
        default:
          break;
      }
    }
  }, [gameId, kind, props.gameId]);

  const listTransition = useListTransition(props.gameId);

  const values = React.useMemo(
    () => ({
      content,
      styles,
      coupons: listTransition,
    }),
    [content, listTransition]
  );

  return { scrollRef, values };
};

export const useListTransition = (gameId: number) => {
  const bets = useSelector(
    createGetBetsByParams(
      [gameId],
      [],
      ["confirmed", "finish", "pending", "selected"]
    )
  );

  const betsSorted = [...bets].sort((a, b) => b.updated - a.updated);

  return useTransition(
    betsSorted.map((bet, index) => ({
      ...bet,
      index,
    })),
    {
      keys: betsSorted.map((bet) => bet.uuid),
      from: ({ index }) => ({ x: `100%`, y: `${index * 100}%` }),
      enter: ({ index }) => ({ x: `0%`, y: `${index * 100}%` }),
      update: ({ index }) => ({ x: `0%`, y: `${index * 100}%` }),
      leave: ({ index }) => ({ x: `-100%`, y: `${index * 100}%` }),
      config: { friction: 80, mass: 5, tension: 500 },
    }
  );
};

import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { Content, Market } from "src/types";
import { getMarket, getTheme } from "src/selectors";
import { useContent, useWindowSize } from "src/hooks";

import styles from "./style.module.scss";

const MARKETS: Record<Content, Array<Market>> = {
  desktop: ["result", "competition", "sum"],
  mobile: ["result", "competition", "sum"],
  laptop: ["result", "competition", "sum"],
  v3: [],
};

export const useMarkets = () => {
  const content = useContent();

  const market = useSelector(getMarket);
  const theme = useSelector(getTheme);

  const markets = MARKETS[content];

  const [height] = useWindowSize();

  const getMarketsProps = React.useCallback(
    () => ({
      className: cn(styles[content]),
    }),
    [content]
  );

  const getMarketsV3Props = React.useCallback(
    () => ({
      className: cn(styles[content], styles[theme]),
      style: { height: `calc(${height}px - 7rem)` } as React.CSSProperties,
    }),
    [content, height, theme]
  );

  const values = React.useMemo(
    () => ({
      content,
      market,
      markets,
      styles,
    }),
    [content, market, markets]
  );

  return { getMarketsProps, getMarketsV3Props, values };
};

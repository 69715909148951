import React from "react";

import { SxProps } from "@mui/material";
import { useSelector } from "react-redux";

import { getModal } from "src/selectors";
import { useContent } from "src/hooks";

export const useTechBreak = () => {
  const content = useContent();

  const modal = useSelector(getModal);

  const getModalProps = React.useCallback(
    () => ({
      open: modal.tech_break,
      sx: { backdropFilter: "blur(5px)" } as SxProps,
    }),
    [modal.tech_break]
  );

  const values = React.useMemo(
    () => ({
      content,
    }),
    [content]
  );

  return { getModalProps, values };
};

import React from "react";

import { useSelector } from "react-redux";

import { createGetBetsByParams, getNextId } from "src/selectors";
import { sort } from "./utils";

import styles from "./styles.module.scss";

export const useDesktop = () => {
  const nextId = useSelector(getNextId);

  const personal = useSelector(createGetBetsByParams([nextId], ["personal"], []));
  const tops = useSelector(createGetBetsByParams([nextId], ["top2", "top3"], []));

  const values = React.useMemo(
    () => ({
      styles,
      results: sort([...tops, ...personal]),
    }),
    [personal, tops]
  );

  return { values };
};

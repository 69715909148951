import React from "react";

import { contentActions } from "src/slices";
import { loggerActions, socketActions } from "src/actions";
import { useAppDispatch, useContent } from "src/hooks";

export const useApp = () => {
  useSubscribe();
  useConnect();

  const content = useContent();

  const values = React.useMemo(
    () => ({
      content,
    }),
    [content]
  );

  return { values };
};

export const useSubscribe = () => {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    window.top?.postMessage({ message: "liverace_init" }, "*");

    const messageHandler = function (event: MessageEvent) {
      if (typeof event.data === "object" && event.data["accessToken"]) {
        sessionStorage.setItem("token", event.data["accessToken"]);
      }

      if (typeof event.data === "object" && event.data["balanceId"]) {
        sessionStorage.setItem("balanceId", event.data["balanceId"]);
      }

      if (typeof event.data === "string") {
        try {
          const data = JSON.parse(event.data);
          const action = data["action"];
          switch (action) {
            case "sound_muted": {
              const mute = data["mute"];
              if (mute == "1") {
                dispatch(contentActions.volumeUpdated(0));
              }
              if (mute == "0") {
                dispatch(contentActions.volumeUpdated(0.5));
              }
              break;
            }
          }
        } catch (error) {
          dispatch(loggerActions.log("IFRAME: PARSE ERROR"));
        }
      }
    };

    const offlineHandler = function () {
      dispatch(socketActions.close());
    };

    window.addEventListener("message", messageHandler);
    window.addEventListener("offline", offlineHandler);

    return () => {
      window.removeEventListener("message", messageHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, [dispatch]);
};

export const useConnect = () => {
  const dispatch = useAppDispatch();

  const mountRef = React.useRef(false);

  React.useEffect(() => {
    if (!mountRef.current) {
      mountRef.current = true;

      dispatch(socketActions.connect());
    }
  }, [dispatch]);
};

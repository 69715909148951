import React from "react";
import cn from "classnames";

import { Props } from "./types";
import { useBet } from "./hooks";

import styles from "./style.module.scss";

export const BetComponent = (props: Props) => {
  const { getCupProps, getLineProps, getLinesProps, values } = useBet(props);
  return (
    <div className={styles[values.content]}>
      <div className={styles.data}>
        <div
          {...getLinesProps(
            cn(styles.lines, styles[`lines-${props.bet.racers.join("-")}`])
          )}
        >
          {props.bet.racers.map((line) => (
            <span
              key={line}
              {...getLineProps(cn(styles.line, { [styles.even]: line % 2 === 0 }))}
            >
              {line}
            </span>
          ))}
        </div>
        <div className={styles.type}>
          <img {...getCupProps()} />
        </div>
      </div>
      <span className={styles.sum}>{values.win}</span>
    </div>
  );
};

import { createSelector } from "reselect";

import { RootState } from "src/types";

/**
 * Селекторы хранилища контента
 */

/**
 * content
 * @param state - хранилище
 * @returns хранилище content
 */
const getRoot = (state: RootState) => state.content;

/**
 * Селектор машинок
 */
export const getContent = createSelector([getRoot], (root) => root.content);

/**
 * Тема интерфейса
 */
export const getTheme = createSelector([getRoot], (root) => root.theme);

/**
 * Версия интерфейса
 */
export const getVersion = createSelector([getRoot], (root) => root.version);

/**
 * Громкость звука
 */
export const getVolume = createSelector([getRoot], (root) => root.volume);

import React from "react";

import { Control, Step } from "src/components";
import { useControls } from "./hooks";

export const ControlsComponent = React.memo(() => {
  const { getStepProps, values } = useControls();
  return (
    <div className={values.styles.controls}>
      <Control type="double" text="УДВОИТЬ" />
      <Step {...getStepProps()} />
      <Control type="repeat" text="ПОВТОРИТЬ" />
    </div>
  );
});

ControlsComponent.displayName = "ControlsComponent";

import React from "react";

import MarketCompetition from "./components/market-competition";
import MarketResult from "./components/market-result";
import MarketSum from "./components/market-sum";
import MarketTop from "./components/market-top";

/** LAZY LOADING
 *  const Market3From6 = React.lazy(() => import("./components/market-3-from-6"));
 *  const MarketCompetition = React.lazy(
 *    () => import("./components/market-competition")
 *  );
 *  const MarketResult = React.lazy(() => import("./components/market-result"));
 *  const MarketSum = React.lazy(() => import("./components/market-sum"));
 *  const MarketTop = React.lazy(() => import("./components/market-top"));
 */

import { Props } from "./types";

export const MarketComponent = (props: Props) => {
  let market;

  switch (props.market) {
    case "competition":
      market = <MarketCompetition />;
      break;
    case "result":
      market = <MarketResult />;
      break;
    case "sum":
      market = <MarketSum />;
      break;
    case "tops":
      market = <MarketTop />;
      break;
    default:
      market = <div />;
      break;
  }

  return <React.Suspense>{market}</React.Suspense>;
};

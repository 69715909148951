import React from "react";

import { useSelector } from "react-redux";

import { createGetBetsByParams } from "src/selectors";
import { getBalances } from "src/components/total/model";
import { Props } from "./types";
import { useContent } from "src/hooks";

import styles from "./style.module.scss";
import { getDataTestId } from "src/utils";

export const useBalances = (props: Props) => {
  const content = useContent();

  const bets = useSelector(
    createGetBetsByParams(
      [props.game.id],
      [],
      ["confirmed", "finish", "pending", "selected"]
    )
  );

  const getBalanceProps = React.useCallback(
    (id: number) => ({
      ...getDataTestId(`coupons-amount-${id}`),
    }),
    []
  );

  const balances = getBalances(bets);

  const values = React.useMemo(
    () => ({
      balances,
      content,
      styles,
    }),
    [balances, content]
  );

  return { getBalanceProps, values };
};

import React from "react";
import range from "lodash/range";
import cn from "classnames";

import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Carousel } from "react-responsive-carousel";

import { State } from "./types";
import { useMobile } from "./hooks";

import "./style.scss";

import styles from "./style.module.scss";

const TIMELAPSES = (state: State) =>
  range(1, 7).map((slide) => (
    <div
      key={slide}
      className={cn(styles[`timelapse-${slide}`], {
        [styles.active]: state.index + 1 === slide,
        [styles.play]: state.mode === "play",
        [styles.pause]: state.mode === "pause",
        [styles.done]: state.index + 1 > slide,
      })}
    />
  ));

const SLIDES = () =>
  range(1, 7).map((slide) => (
    <div key={slide} className={styles[`slide-${slide}`]} />
  ));

export const MobileComponent = React.memo(() => {
  const { getButtonProps, getCarouselProps, values } = useMobile();

  return (
    <div className={styles[values.content]}>
      <div className={styles.timelapse}>{TIMELAPSES(values.state)}</div>
      <button {...getButtonProps(styles.close)} />
      <Carousel {...getCarouselProps()}>{SLIDES()}</Carousel>
    </div>
  );
});

MobileComponent.displayName = "MobileComponent";

import find from "lodash/find";

import { createSelector } from "reselect";

import { Game } from "src/types";
import { getGameId, getGames } from "src/selectors";

export const getTabs = createSelector([getGameId, getGames], (gameId, games) => {
  const result: Array<Game> = [];

  const nextId = gameId + 1;
  const lastId = nextId + 1;

  const play = find(games, { id: gameId });
  const next = find(games, { id: nextId });
  const last = find(games, { id: lastId });

  [play, next, last].forEach((game) => {
    if (game) {
      result.push(game);
    }
  });

  return result;
});

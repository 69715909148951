import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { amountActions } from "src/slices";
import { getAmount, getDynamic, getTheme } from "src/selectors";
import { useAppDispatch } from "src/hooks";

import styles from "./style.module.scss";

export const useV3 = () => {
  const dispatch = useAppDispatch();

  const amount = useSelector(getAmount);
  const theme = useSelector(getTheme);

  const getPanelProps = React.useCallback(
    () => ({
      className: cn(styles.panel, styles[theme]),
    }),
    [theme]
  );

  const getAmountsProps = React.useCallback(
    () => ({
      onClick: (value: number) => dispatch(amountActions.amountUpdated(value)),
      value: amount,
    }),
    [amount, dispatch]
  );

  const { getStepProps } = useStep();

  return { getAmountsProps, getPanelProps, getStepProps };
};

export const useStep = () => {
  const dispatch = useAppDispatch();

  const amount = useSelector(getAmount);
  const dynamic = useSelector(getDynamic);

  const [value, setValue] = React.useState<number>(0);

  React.useEffect(() => {
    setValue(dynamic.min);
  }, [dynamic]);

  const onChange = React.useCallback(
    (value: number) => {
      dispatch(amountActions.amountUpdated(value));
      setValue(value);
    },
    [dispatch]
  );

  const onClick = React.useCallback(() => {
    dispatch(amountActions.amountUpdated(value));
  }, [dispatch, value]);

  const getStepProps = React.useCallback(
    () => ({
      max: dynamic.max,
      min: dynamic.min,
      onChange,
      onClick,
      selected: amount === value,
      step: dynamic.step,
      value,
    }),
    [amount, dynamic, onChange, onClick, value]
  );

  return { getStepProps };
};

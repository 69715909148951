import { Bet } from "src/types";
import { LINES_COLOR } from "src/constants";

export const getTitle = (bet: Bet) => {
  switch (bet.type) {
    case "competition":
      return "СОРЕВНОВАНИЕ";
    case "personal":
      return `РЕЗУЛЬТАТ: ${bet.places.toString()} МЕСТО`;
    case "top1":
      return "РЕЗУЛЬТАТ: 1 МЕСТО";
    case "top2":
      return "РЕЗУЛЬТАТ: 1-2 МЕСТО";
    case "top3":
      return "РЕЗУЛЬТАТ: 1-3 МЕСТО";
    case "sumtop2":
      return "СУММА: 1 и 2 МЕСТА";
    case "sumtop3":
      return "СУММА: 1, 2 и 3 МЕСТО";
    case "bottom":
      return "3 ИЗ 6: ПОБЕДИТ < 3.5";
    case "even":
      return "3 ИЗ 6: ПОБЕДИТ ЧЕТ.";
    case "odd":
      return "3 ИЗ 6: ПОБЕДИТ НЕЧЕТ.";
    case "upper":
      return "3 ИЗ 6: ПОБЕДИТ > 3.5";
    default:
      return "";
  }
};

export const getBackground = (bet: Bet) => {
  if (bet.type === "sumtop2" || bet.type === "sumtop3") {
    return bet.status === "confirmed" ? "#0b0c22" : "#060917";
  }

  if (bet.racers.length === 1) {
    return LINES_COLOR[bet.racers[0]];
  }

  if (bet.racers.length === 2) {
    const color1 = LINES_COLOR[bet.racers[0]];
    const color2 = LINES_COLOR[bet.racers[1]];
    return `linear-gradient(105deg, ${color1} 0%, ${color1} 50%, ${color2} 50%)`;
  }

  if (bet.racers.length === 3) {
    const color1 = LINES_COLOR[bet.racers[0]];
    const color2 = LINES_COLOR[bet.racers[1]];
    const color3 = LINES_COLOR[bet.racers[2]];
    return `linear-gradient(to right, ${color1} 0%, ${color1} 33%, ${color2} 33%, ${color2} 66%, ${color3} 66%)`;
  }

  return "transparent";
};

export const getStatus = (bet: Bet, gameId: number) => {
  switch (bet.status) {
    case "none":
      return "";
    case "confirmed":
      return bet.gameId === gameId ? "ИДЕТ ГОНКА" : "ОЖИДАЕМ ГОНКУ";
    case "pending":
    case "selected":
      return "ОТМЕНИТЬ";
    case "finish":
      return bet.win > 0 ? "ВЫИГРЫШ" : "ФИНИШ";
    default:
      return "";
  }
};

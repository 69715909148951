import React from "react";

import { useContent } from "src/hooks";

export const useWinAnimation = () => {
  const content = useContent();

  const values = React.useMemo(
    () => ({
      content,
    }),
    [content]
  );

  return { values };
};

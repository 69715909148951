import React from "react";
import cn from "classnames";

import { getDataTestId } from "src/utils";
import { Props } from "./types";
import { useContent } from "src/hooks";

import styles from "./style.module.scss";

const TEXTS = ["ДО ГОНКИ", "ГОНКА"];

export const useGame = (props: Props) => {
  const content = useContent();

  const isExpected = props.game.status === "expected";
  const isStarted = props.game.status === "started";
  const isFinished = props.game.status === "finished";

  const isHidden = isStarted || isFinished;

  const getGameProps = React.useCallback(
    () => ({
      className: cn(styles[content], {
        [styles.hide]: isHidden,
      }),
    }),
    [content, isHidden]
  );

  const getIdProps = React.useCallback(
    () => ({
      className: cn(styles.id),
      ...getDataTestId("progress-game-id"),
    }),
    []
  );

  const getStatusProps = React.useCallback(
    () => ({
      className: cn(styles.status),
      ...getDataTestId("progress-game-status"),
    }),
    []
  );

  const status = isExpected ? TEXTS[0] : TEXTS[1];

  const values = React.useMemo(
    () => ({
      status,
    }),
    [status]
  );

  return { getGameProps, getIdProps, getStatusProps, values };
};

import React from "react";
import MuiModal from "@mui/material/Modal";

import { useTechBreak } from "./hooks";

import styles from "./style.module.scss";

const TEXTS = ["ТЕХНИЧЕСКИЙ ПЕРЕРЫВ", "Ведутся работы, мы скоро к вам вернемся."];

export const TechBreakComponent = React.memo(() => {
  const { getModalProps, values } = useTechBreak();
  return (
    <MuiModal {...getModalProps()}>
      <div className={styles[values.content]}>
        <div className={styles.content}>
          <span className={styles.title}>{TEXTS[0]}</span>
          <span className={styles.description}>{TEXTS[1]}</span>
        </div>
      </div>
    </MuiModal>
  );
});

TechBreakComponent.displayName = "TechBreakComponent";

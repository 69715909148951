import React from "react";

import { useHeader } from "./hooks";

import styles from "./style.module.scss";

export const HeaderComponent = React.memo(() => {
  const { values } = useHeader();

  let view;

  switch (values.content) {
    case "desktop": {
      view = <div className={styles[values.content]}>ВЫИГРЫШ!</div>;
      break;
    }
    case "mobile":
    case "laptop": {
      view = <div className={styles[values.content]}>ВЫИГРЫШ {values.win}</div>;
      break;
    }
    case "v3": {
      view = (
        <div className={styles[values.content]}>
          <span>ВЫИГРЫШ</span>
          <span>{values.win}</span>
        </div>
      );
      break;
    }
    default:
      view = <div />;
      break;
  }

  return view;
});

HeaderComponent.displayName = "HeaderComponent";

import React from "react";
import cn from "classnames";

import { useTransition } from "@react-spring/web";
import { useSelector } from "react-redux";

import { createGetLap } from "src/components/status/model";
import { getDataTestId } from "src/utils";
import { Props } from "./types";
import { useContent } from "src/hooks";

import styles from "./style.module.scss";

export const useStatusLaps = (props: Props) => {
  const content = useContent();

  const lap = useSelector(createGetLap(props.game.id));

  const lapTestId = React.useMemo(() => {
    switch (lap) {
      case 1:
        return "progress-first-lap";
      case 2:
        return "progress-second-lap";
      case 3:
        return "progress-third-lap";
      default:
        return "progress-unknown-lap";
    }
  }, [lap]);

  const isStarted = props.game.status === "started";
  const isFinished = props.game.status === "finished";

  const getLapsProps = React.useCallback(
    () => ({
      className: cn(styles[content], {
        [styles.padded]: isStarted || isFinished,
      }),
    }),
    [content, isFinished, isStarted]
  );

  const getLapProps = React.useCallback(
    () => ({
      ...getDataTestId(lapTestId),
    }),
    [lapTestId]
  );

  const getStartProps = React.useCallback(
    () => ({
      ...getDataTestId("progress-start"),
    }),
    []
  );

  const getFinishProps = React.useCallback(
    () => ({
      ...getDataTestId("progress-finish"),
    }),
    []
  );

  const lapsTransition = useLapsTransition(lap);

  const values = React.useMemo(
    () => ({
      styles,
      laps: lapsTransition,
    }),
    [lapsTransition]
  );

  return { getFinishProps, getLapProps, getLapsProps, getStartProps, values };
};

export const useLapsTransition = (lap: number) => {
  return useTransition(lap, {
    from: { transform: "translate3d(0px, -100%, 0px)" },
    enter: { transform: "translate3d(0px, 0%, 0px)" },
    leave: { transform: "translate3d(0px, 100%, 0px)" },
    config: { duration: 500 },
  });
};

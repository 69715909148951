import { Bet } from "src/types";
import { RACERS } from "src/constants";

export const sort = (bets: Array<Bet>) => {
  const result: Record<number, Record<number, Bet>> = {};

  RACERS.forEach((first) => {
    result[first] = {};
    RACERS.forEach((second) => {
      const bet = bets.find(
        (bet) =>
          bet.type === "competition" &&
          bet.racers[0] === first &&
          bet.racers[1] === second
      );
      if (bet) {
        result[first][second] = bet;
      }
    });
  });

  return result;
};

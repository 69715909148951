import { createSlice } from "@reduxjs/toolkit";

import { SETTINGS_INITIAL_STATE } from "./constants";
import { socketActions } from "src/actions";
import { State } from "./types";

const STATE: State = {
  message: null,
  settings: SETTINGS_INITIAL_STATE,
  statistics: [],
};

export const metaSlice = createSlice({
  name: "meta",
  initialState: STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(socketActions.message, (state, action) => {
        state.message = action.payload;
      })
      .addCase(socketActions.messageInit, (state, action) => {
        state.settings = action.payload.payload.settings;
        state.statistics = action.payload.payload.statistics;
      })
      .addCase(socketActions.messageRaceFinish, (state, action) => {
        state.statistics = action.payload.payload.statistics;
      })
      .addCase(socketActions.close, () => STATE);
  },
});

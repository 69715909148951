import React from "react";

import { Game, Laps, Pending, TechBreak, Time } from "./components";
import { Props } from "./types";
import { useStatus } from "./hooks";

export const StatusComponent = React.memo((props: Props) => {
  const {
    getBorderProps,
    getGameProps,
    getLapsProps,
    getTimeProps,
    getStatusProps,
  } = useStatus(props);
  let content;

  switch (props.game.status) {
    case "started":
    case "play":
    case "finished":
      content = (
        <>
          <Game {...getGameProps()} />
          <Laps {...getLapsProps()} />
        </>
      );
      break;
    case "expected":
      content = (
        <>
          <Game {...getGameProps()} />
          <Time {...getTimeProps()} />
        </>
      );
      break;
    case "pending":
      content = <Pending />;
      break;
    case "tech_break":
      content = <TechBreak />;
      break;
    default:
      break;
  }
  return (
    <div {...getStatusProps()}>
      <img {...getBorderProps()} />
      {content}
    </div>
  );
});

StatusComponent.displayName = "StatusComponent";

import React from "react";

import { Controls, Views } from "./components";
import { Total } from "src/components";
import { useBody } from "./hooks";

export const BodyComponent = () => {
  const { getBodyProps, values } = useBody();

  let view = null;

  if (!values.game) {
    return view;
  }

  switch (values.content) {
    case "desktop": {
      view = (
        <div {...getBodyProps()}>
          <Total game={values.game} />
          <div className={values.styles.wrapper}>
            <Views />
          </div>
        </div>
      );
      break;
    }
    case "mobile":
    case "laptop": {
      view = (
        <div {...getBodyProps()}>
          <Total game={values.game} />
          <div className={values.styles.wrapper}>
            <Views />
          </div>
          <Controls />
        </div>
      );
    }
  }

  return view;
};

import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { AudioContext } from "src/contexts";
import { coreActions } from "src/slices";
import { getDataTestId } from "src/utils";
import { getMarket } from "src/selectors";
import { Market } from "src/types";
import { useAppDispatch, useContent } from "src/hooks";

import styles from "./style.module.scss";

export const useHeader = () => {
  const content = useContent();

  const dispatch = useAppDispatch();

  const market = useSelector(getMarket);

  const { play } = React.useContext(AudioContext);

  const getHeaderProps = React.useCallback(
    () => ({
      className: cn(styles[content], styles[market]),
    }),
    [content, market]
  );

  const getButtonProps = React.useCallback(
    (value: Market) => ({
      className: cn(styles.button, styles[value], {
        [styles.selected]: value === market,
      }),
      ["data-selected"]: value === market ? "true" : "false",
      onClick: () => {
        play("tab");
        dispatch(coreActions.marketUpdated(value));
      },
      ...getDataTestId(`button-market-${value}`),
    }),
    [dispatch, market, play]
  );

  return { getButtonProps, getHeaderProps };
};

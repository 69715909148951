import { Middleware, MiddlewareAPI, isAnyOf } from "@reduxjs/toolkit";

import { coreActions, couponsActions, createBetsThunk } from "src/slices";
import { createGetIsAnyBet, getGameId, getNextId } from "src/selectors";
import { socketActions } from "src/actions";

export const couponsMiddleware: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    const response = next(action);

    const isAnyOfActions = isAnyOf(
      coreActions.betsUpdated,
      createBetsThunk.pending,
      socketActions.messageInit,
      socketActions.messageStart,
      socketActions.messageRaceFinish
    );

    if (isAnyOfActions(action)) {
      const state = api.getState();

      const gameId = getGameId(state);
      const nextId = getNextId(state);

      switch (action.type) {
        case coreActions.betsUpdated.type:
        case createBetsThunk.pending.type: {
          api.dispatch(couponsActions.viewUpdated("next"));
          break;
        }
        case socketActions.messageInit.type: {
          const isAnyCurr = createGetIsAnyBet(gameId, "confirmed")(state);
          const isAnyNext = createGetIsAnyBet(nextId, "confirmed")(state);
          if (isAnyCurr && isAnyNext) {
            api.dispatch(couponsActions.viewUpdated("curr"));
          } else if (isAnyNext) {
            api.dispatch(couponsActions.viewUpdated("next"));
          }
          break;
        }
        case socketActions.messageStart.type: {
          const isAnyPend = createGetIsAnyBet(gameId, "pending")(state);
          const isAnyConf = createGetIsAnyBet(gameId, "confirmed")(state);
          if (isAnyPend || isAnyConf) {
            api.dispatch(couponsActions.viewUpdated("curr"));
          }
          break;
        }
        case socketActions.messageRaceFinish.type: {
          const isAny = createGetIsAnyBet(gameId, "finish")(state);
          if (isAny) {
            api.dispatch(couponsActions.viewUpdated("curr"));
          }
          break;
        }
      }
    }

    return response;
  };

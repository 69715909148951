import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";
import { useSpring, useSpringRef } from "@react-spring/web";

import { createGetOffsetV3 } from "src/components/speedometer/model";
import { getNextId, getTheme } from "src/selectors";
import { useAnimationFrame } from "src/hooks";

import styles from "./style.module.scss";

export const useV3 = () => {
  const nextId = useSelector(getNextId);
  const theme = useSelector(getTheme);

  const offset = useSelector(createGetOffsetV3(nextId));

  const frustration = useFrustration(offset);

  const progressSpring = useProgressSpring(frustration);
  const progressRevertSpring = useProgressRevertSpring(frustration);

  const getSpeedometerProps = React.useCallback(
    () => ({
      className: cn(styles.speedometer, styles[theme]),
    }),
    [theme]
  );

  const getProgressProps = React.useCallback(
    () => ({
      className: cn(styles.progress),
      style: progressSpring,
    }),
    [progressSpring]
  );

  const getProgressRevertProps = React.useCallback(
    () => ({
      className: cn(styles.progress),
      style: progressRevertSpring,
    }),
    [progressRevertSpring]
  );

  const values = React.useMemo(
    () => ({
      styles,
    }),
    []
  );

  return { getProgressProps, getProgressRevertProps, getSpeedometerProps, values };
};

export const useProgressSpring = (frustration: number) => {
  const speedometerApi = useSpringRef();
  const speedometerSpring = useSpring({
    ref: speedometerApi,
    from: { clipPath: `polygon(0 0, 50% 0, 50% 100%, 0% 100%)` },
    config: { duration: 0 },
  });

  React.useEffect(() => {
    speedometerApi.start({
      to: async (next) => {
        await next({
          clipPath: `polygon(0 0, ${frustration}% 0, ${
            frustration - (frustration === 0 ? 0 : 0.4)
          }% 100%, 0% 100%)`,
          config: { duration: 0 },
        });
      },
    });
  }, [frustration, speedometerApi]);

  return speedometerSpring;
};

export const useProgressRevertSpring = (frustration: number) => {
  const speedometerApi = useSpringRef();
  const speedometerSpring = useSpring({
    ref: speedometerApi,
    from: { clipPath: `polygon(100% 0, 100% 0, 100% 100%, 100% 100%)` },
    config: { duration: 0 },
  });

  React.useEffect(() => {
    speedometerApi.start({
      to: async (next) => {
        await next({
          clipPath: `polygon(${100 - frustration}% 0, 100% 0, 100% 100%, ${
            100 - frustration + (frustration === 0 ? 0 : 0.4)
          }% 100%)`,
          config: { duration: 0 },
        });
      },
    });
  }, [frustration, speedometerApi]);

  return speedometerSpring;
};

export const useFrustration = (offset: number) => {
  const [frustration, setFrustration] = React.useState(offset);
  const [fps, setFps] = React.useState(15);

  React.useEffect(() => {
    if (offset === 0) {
      setFps(30);
    }
    if (offset === 50) {
      setFps(60);
    }
  }, [offset]);

  const animationHandler = React.useCallback(() => {
    const random =
      offset === 0 || offset === 50
        ? offset
        : offset === 50
        ? offset + Math.random() * 5
        : offset - Math.random() * 2.5;
    setFrustration(random);
  }, [offset]);

  useAnimationFrame(animationHandler, fps, offset);

  return frustration;
};

import React from "react";

import { useSelector } from "react-redux";
import { useTransition } from "@react-spring/web";

import { getTabs } from "src/widgets/info/model";

import styles from "./style.module.scss";

export const useV3 = () => {
  const tabsTransition = useTabsTransition();

  const values = React.useMemo(
    () => ({
      styles,
      tabs: tabsTransition,
    }),
    [tabsTransition]
  );

  return { values };
};

export const useTabsTransition = () => {
  const tabs = useSelector(getTabs);

  const STEP = 98;

  return useTransition(
    tabs.map((game, index) => ({ game, index })),
    {
      keys: tabs.map((game) => game.id),
      from: ({ index }) => ({ x: `${index * STEP + STEP}%` }),
      enter: ({ index }) => ({ x: `${index * STEP}%` }),
      update: ({ index }) => ({ x: `${index * STEP}%` }),
      leave: ({ index }) => ({ x: `${index * STEP - STEP}%` }),
      config: { tension: 500, friction: 150, mass: 10 },
    }
  );
};

import React from "react";

import { Arrow, Body, Header, Hint } from "./components";
import { Player } from "src/components";
import { useMobile } from "./hooks";

export const MobileComponent = React.memo(() => {
  const { getInfoProps, values } = useMobile();
  return (
    <div {...getInfoProps()}>
      <Arrow />
      <div className={values.styles.content}>
        <Header />
        <Body />
      </div>
      <Player />
      <Hint />
    </div>
  );
});

MobileComponent.displayName = "MobileComponent";

import React from "react";

import { useHeader } from "./hooks";

import styles from "./style.module.scss";

export const HeaderComponent = React.memo(() => {
  const { values } = useHeader();
  return (
    <div className={styles[values.content]}>
      <span>ПРЯМАЯ ТРАНСЛЯЦИЯ</span>
    </div>
  );
});

HeaderComponent.displayName = "HeaderComponent";

export const getUrl = (protocol: "https" | "wss") => {
  const url = new URL(window.location.href);

  switch (process.env.NODE_ENV) {
    case "development": {
      url.protocol = "ws";
      url.pathname = "connect";
      break;
    }
    case "production": {
      url.protocol = protocol;
      url.pathname = "ws";
      break;
    }
    default:
      break;
  }

  setParams(url);

  return url;
};

export const setParams = (url: URL) => {
  const token = url.searchParams.get("token");
  const balanceId = url.searchParams.get("balanceId");

  if (token) {
    sessionStorage.setItem("token", token);
  }
  if (balanceId) {
    sessionStorage.setItem("balanceId", balanceId);
  }
};

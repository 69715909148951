import React from "react";

import { animated } from "@react-spring/web";

import { Combined, Mobile } from "./components";
import { useTutorial } from "./hooks";

import styles from "./style.module.scss";

export const TutorialComponent = React.memo(() => {
  const { values } = useTutorial();

  let view: React.ReactNode;

  switch (values.content) {
    case "desktop":
    case "v3":
      view = <Combined />;
      break;
    case "mobile":
    case "laptop":
      view = <Mobile />;
      break;
    default:
      view = null;
  }

  return values.tutorial(
    (style, open) =>
      open && (
        <animated.div className={styles.tutorial} style={style}>
          {view}
        </animated.div>
      )
  );
});

TutorialComponent.displayName = "TutorialComponent";

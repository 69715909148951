import React from "react";

import { useHint } from "./hooks";

export const HintComponent = () => {
  const { getHintProps, getImgProps } = useHint();
  return (
    <div {...getHintProps()}>
      <img {...getImgProps()} />
    </div>
  );
};

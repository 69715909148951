import EgPlayer from "@atm/eg-player";

import { PLAYER_SETTINGS } from "./constants";

export class Player {
  player: EgPlayer | null;

  constructor() {
    this.player = null;
  }

  play() {
    if (!this.player) {
      this.player = new EgPlayer({ ...PLAYER_SETTINGS });

      this.player.on("STARTED", () => {
        if (this.player) {
          this.player.uiStylesSet("loader", "display: none;");
        }
      });

      this.player.on("READY", () => {
        if (this.player) {
          this.player.start({ game: "carracing", view: "main", quality: "360p" });
        }
      });

      this.player.init();
    }
  }

  stop() {
    if (this.player) {
      this.player.stop();
      this.player = null;
    }
  }
}

import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { getTops } from "src/selectors";
import { useContent } from "src/hooks";

import styles from "./style.module.scss";

export const useBody = () => {
  const content = useContent();

  const tops = useSelector(getTops);

  const getBodyProps = React.useCallback(
    () => ({
      className: cn(styles[content], {
        [styles.open]: tops,
      }),
    }),
    [content, tops]
  );

  const values = React.useMemo(
    () => ({
      styles,
    }),
    []
  );

  return { getBodyProps, values };
};

import React from "react";

import { animated } from "@react-spring/web";

import { Car } from "./components";
import { useCars } from "./hooks";

export const CarsComponent = React.memo(() => {
  const { values } = useCars();
  return (
    <div className={values.styles.cars}>
      <div className={values.styles.header}>ПРОГРЕСС&nbsp;ГОНКИ</div>
      <div className={values.styles.body}>
        {values.cars((style, car) => (
          <animated.div className={values.styles.car} style={style}>
            <Car car={car} />
          </animated.div>
        ))}
      </div>
    </div>
  );
});

CarsComponent.displayName = "CarsComponent";

import React from "react";

import { Cup, Line } from "src/shared/components";
import { Odd } from "src/components";
import { useMobile } from "./hooks";

const CUPS = [
  [
    <Cup key="cup1" type="cup1" variant="new" />,
    <Cup key="cup2" type="cup2" variant="new" />,
  ],
  [
    <Cup key="cup1" type="cup1" variant="new" />,
    <Cup key="cup2" type="cup2" variant="new" />,
  ],
];

export const MobileComponent = () => {
  const { values } = useMobile();
  return (
    <div className={values.styles[values.content]}>
      <div className={values.styles.header}>
        <div className={values.styles.cups}>{CUPS[0]}</div>
        <div className={values.styles.cups}>{CUPS[1]}</div>
      </div>
      <div className={values.styles.body}>
        {values.competitions.map((competition, index) => (
          <div key={index} className={values.styles.bets}>
            {competition.map((bet, index) => (
              <div key={index} className={values.styles.bet}>
                <div className={values.styles.lines}>
                  <Line line={bet.racers[0]} />
                  <Line line={bet.racers[1]} />
                </div>
                <Odd odd={bet} />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

import { Middleware, MiddlewareAPI } from "@reduxjs/toolkit";

import { Kind } from "src/types";
import { Socket } from "src/services";
import { socketActions } from "src/actions";

export const socketMiddleware =
  (socket: Socket): Middleware =>
  (api: MiddlewareAPI) =>
  (next) =>
  (action) => {
    const response = next(action);

    switch (action.type) {
      case socketActions.connect.type: {
        socket.connect();

        socket.onopen(() => {
          api.dispatch(socketActions.open());
        });

        socket.onclose(() => {
          api.dispatch(socketActions.close());

          setTimeout(() => api.dispatch(socketActions.connect()), 3000);
        });

        socket.onmessage((ev) => {
          const data = JSON.parse(ev.data);

          const kind: Kind = data.kind;

          api.dispatch(socketActions.message(data));

          switch (kind) {
            case "init": {
              api.dispatch(socketActions.messageInit(data));
              break;
            }
            case "start_expected": {
              api.dispatch(socketActions.messageStartExpected(data));
              break;
            }
            case "start": {
              api.dispatch(socketActions.messageStart(data));
              break;
            }
            case "race_start": {
              api.dispatch(socketActions.messageRaceStart(data));
              break;
            }
            case "car_progress": {
              api.dispatch(socketActions.messageCarProgress(data));
              break;
            }
            case "car_finish": {
              api.dispatch(socketActions.messageCarFinish(data));
              break;
            }
            case "race_finish": {
              api.dispatch(socketActions.messageRaceFinish(data));
              break;
            }
            case "finish": {
              api.dispatch(socketActions.messageFinish(data));
              break;
            }
            case "win": {
              api.dispatch(socketActions.messageWin(data));
              break;
            }
            case "abort": {
              api.dispatch(socketActions.messageAbort(data));
              break;
            }
            case "tech_break": {
              api.dispatch(socketActions.messageTechBreak(data));
              break;
            }
            case "end_tech_break": {
              api.dispatch(socketActions.messageEndTechBreak(data));
              break;
            }
            default:
              break;
          }
        });

        break;
      }
      case socketActions.close.type: {
        socket.disconnect();
        break;
      }
    }

    return response;
  };

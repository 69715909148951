import React from "react";

import { useLimit } from "./hooks";

export const LimitMobileComponent = React.memo(() => {
  const { getLimitProps, values } = useLimit();
  return (
    <div {...getLimitProps()}>
      <span>{values.max} ₽</span>
      <span>MAX. СТАВКА</span>
    </div>
  );
});

LimitMobileComponent.displayName = "LimitMobileComponent";

import { createSelector } from "reselect";

import { createGetBetsByParams, getError, getLoading } from "src/selectors";
import { Status } from "./types";

export const createGetCount = (gameId: number) =>
  createSelector(
    [
      getLoading,
      createGetBetsByParams([gameId], [], ["pending"]),
      createGetBetsByParams([gameId], [], ["selected"]),
    ],
    (loading, pending, selected) => (loading ? pending.length : selected.length)
  );

export const createGetStatus = (gameId: number) =>
  createSelector(
    [createGetCount(gameId), getError, getLoading],
    (count, error, loading): Status => {
      const isError = error !== "none";
      const isActive = count > 0;
      const isDisabled = isError || !isActive;
      const isLoading = loading;
      if (isError) {
        return "error";
      } else if (isLoading) {
        return "loading";
      } else if (isDisabled) {
        return "disabled";
      } else {
        return "basic";
      }
    }
  );

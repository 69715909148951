import React from "react";

import { Limit } from "src/components";
import { Odd } from "src/components";
import { RACERS } from "src/constants";
import { Type } from "src/types";
import { useV3 } from "./hooks";

import Line1Cup2 from "src/assets/icons/line-1-cup-2.svg";
import Line2Cup2 from "src/assets/icons/line-2-cup-2.svg";
import Line3Cup2 from "src/assets/icons/line-3-cup-2.svg";
import Line4Cup2 from "src/assets/icons/line-4-cup-2.svg";
import Line5Cup2 from "src/assets/icons/line-5-cup-2.svg";
import Line6Cup2 from "src/assets/icons/line-6-cup-2.svg";
import Line1Cup1 from "src/assets/icons/line-1-cup-1.svg";
import Line2Cup1 from "src/assets/icons/line-2-cup-1.svg";
import Line3Cup1 from "src/assets/icons/line-3-cup-1.svg";
import Line4Cup1 from "src/assets/icons/line-4-cup-1.svg";
import Line5Cup1 from "src/assets/icons/line-5-cup-1.svg";
import Line6Cup1 from "src/assets/icons/line-6-cup-1.svg";

const CUPS_FIRST: Record<number, string> = {
  1: Line1Cup1,
  2: Line2Cup1,
  3: Line3Cup1,
  4: Line4Cup1,
  5: Line5Cup1,
  6: Line6Cup1,
};

const CUPS_SECOND: Record<number, string> = {
  1: Line1Cup2,
  2: Line2Cup2,
  3: Line3Cup2,
  4: Line4Cup2,
  5: Line5Cup2,
  6: Line6Cup2,
};

const LINES = (
  propsGetter: (
    type: Type,
    racer: number,
    invert: boolean
  ) => React.ButtonHTMLAttributes<HTMLButtonElement>,
  invert: boolean
) =>
  RACERS.map((racer) => (
    <button key={racer} {...propsGetter("competition", racer, invert)}>
      <img src={invert ? CUPS_SECOND[racer] : CUPS_FIRST[racer]} />
    </button>
  ));

export const V3Component = () => {
  const { getButtonProps, getLimitProps, getMarketProps, values } = useV3();
  return (
    <div {...getMarketProps()}>
      <div className={values.styles.header}>
        <div className={values.styles.lines}>{LINES(getButtonProps, true)}</div>
        <div className={values.styles.empty} />
      </div>
      <div className={values.styles.body}>
        <div className={values.styles.bets}>
          {RACERS.map((second) =>
            RACERS.map((first) => (
              <div key={`${second}${first}`}>
                {values.competitions[second] &&
                  values.competitions[second][first] && (
                    <Odd odd={values.competitions[second][first]} />
                  )}
              </div>
            ))
          )}
          <div {...getLimitProps()}>
            <Limit market="competition" types={["competition"]} />
          </div>
        </div>
        <div className={values.styles.lines}>{LINES(getButtonProps, false)}</div>
      </div>
    </div>
  );
};

import { store } from "./store";

/**
 * Контрактор
 */
export type Contractor = "offline" | "online" | "demo";

/**
 * Тип интерфейса
 */
export type Content = "desktop" | "mobile" | "laptop" | "v3";

/**
 * Тип темы
 */
export type Theme = "default" | "dark" | "markets";

/**
 * Динамика коэффициентов
 */
export enum Quotation {
  Up = "up",
  Down = "down",
  Same = "same",
  None = "none",
}

/**
 * Операции со ставками
 */
export type Operation = "double" | "undo" | "repeat";

/**
 * Маркеты
 */
export type Market =
  | "competition"
  | "result"
  | "sum"
  | "tops"
  | "3-from-6"
  | "personal";

/**
 * Типизация для Redux
 */
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

/**
 * Базовые пропсы компонента
 */
export type BaseProps = {
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
};

/**
 * Тип машинки
 * @property gameId - идентификатор игры
 * @property line - номер линии
 * @property ms - время финиша
 * @property position - позиция машинки
 * @property progress - прогресс машинки
 * @property status - статус машинки
 * @property uuid - уникальный идентификатор машинки
 */
export type Car = {
  gameId: number;
  line: number;
  ms: number;
  position: number;
  progress: number;
  status: "none" | "start" | "progress" | "finish";
  uuid: string;
};

/**
 * Тип номинала
 */
export type Type =
  | "competition"
  | "personal"
  | "sumtop2"
  | "sumtop3"
  | "top1"
  | "top2"
  | "top3"
  | "even"
  | "odd"
  | "bottom"
  | "upper";

/**
 * Ставка
 * @property added - время добавления ставки
 * @property amount - сумма ставки
 * @property balanceId - тип баланса
 * @property gameId - идентификатор игры
 * @property hashCode - хэшкод ставки
 * @property id - идентификатор ставки в БД
 * @property limit - флаг лимита
 * @property places - места, которые займут гонщики
 * @property racers - гонщики, которые участвуют в ставке
 * @property rate - коэффициент
 * @property status - статус ставки
 * @property sum - выигрыш ставки
 * @property type - тип ставки
 * @property updated - время обновления ставки
 * @property uuid - уникальный идентификатор ставки
 * @property win - выигрыш ставки
 */
export type Bet = {
  added: number;
  amount: number;
  balanceId: number;
  gameId: number;
  hashCode: number;
  id: number;
  limit: boolean;
  places: Array<number>;
  racers: Array<number>;
  rate: number;
  status: "none" | "selected" | "pending" | "confirmed" | "finish";
  type: Type;
  updated: number;
  uuid: string;
  win: number;
};

/**
 * Игра (Гонка)
 * @property id - уникальный идентификатор ставки
 * @property betsUuids - идентификаторы ставок, связанных с игрой
 * @property status - статус игры
 * @property win - сумма выигрыша игры
 */
export type Game = {
  id: number;
  expect: number;
  status:
    | "unknown"
    | "pending"
    | "expected"
    | "started"
    | "play"
    | "finished"
    | "tech_break"
    | "abort";
  win: number;
};

/**
 * Коэффициенты
 * @property competition - соревнование
 * @property personal - результат
 * @property sumtop2 - сумма 2
 * @property sumtop3 - сумма 3
 * @property (deprecated) top1 - 1 место
 * @property top2 - 1-2 место
 * @property top3 - 1-3 место
 * @property (deprecated) even - 3 из 6
 * @property (deprecated) odd - 3 из 6
 * @property (deprecated) bottom - 3 из 6
 * @property (deprecated) upper - 3 из 6
 */
export type Odds = {
  competition: Array<Array<number>>;
  personal: Array<Array<number>>;
  sumtop2: Record<number, number>;
  sumtop3: Record<number, number>;
  top1: Array<number>;
  top2: Array<number>;
  top3: Array<number>;
  even: number;
  odd: number;
  bottom: number;
  upper: number;
};

/**
 * Режим отображения текущей или следующей игры в купонной области
 */
export type View = "curr" | "next";

/**
 * Типы ошибок
 */
export type Error = "error_net" | "error_cash" | "error_limit" | "none";

/**
 * Тип события
 */
export type Kind =
  | "none"
  | "offline"
  | "init"
  | "start_expected"
  | "start"
  | "race_start"
  | "car_progress"
  | "car_finish"
  | "race_finish"
  | "finish"
  | "win"
  | "abort"
  | "tech_break"
  | "end_tech_break";

/**
 * Динамический номинал
 * @property min - минимальный номинал
 * @property max - максимальный номинал
 * @property step - шаг номинала
 */
export type Dynamic = {
  min: number;
  max: number;
  step: number;
};

/**
 * Лимит
 * @property min - минимальный лимит
 * @property max - максимальный лимит
 */
export type Limit = {
  min: number;
  max: number;
};

/**
 * Настройки клуба
 * @propert betSums - статичные номиналы
 * @property betLimits - лимиты
 * @property dynamic - динамический номинал
 * @property winLimit - лимит выигрыша
 * @property version - версия интерфейса
 */
export type Settings = {
  betSums: Array<number>;
  betLimits: Record<Type, Limit> & {
    dynamic: Dynamic;
    winLimit: number;
  };
  version: Version;
};

/**
 * Версия интерфейса
 */
export type Version = "2.3" | "3.0" | "3.1" | "3.2";

/**
 * Описание типов сообщений
 */

/**
 * Сообщение
 * @property datetime - время сообщения
 * @property game_id - идентификатор игры
 * @property kind - тип события
 */
export type Message = {
  datetime: string;
  game_id: number;
  kind: Kind;
};

/**
 * Сообщение инициализации
 */
export type MessageInit = Message & {
  payload: {
    bets: Array<{
      amount: number;
      balance_id: number;
      game_id: number;
      id: number;
      line?: number;
      rate: number;
      second?: number;
      type: Type;
    }> | null;
    move_start_datetime: string;
    odds: Odds;
    prev_odds: Odds;
    start_expected: string;
    status: "normal" | "abort" | "tech_break";
    statistics: Array<Array<number>>;
    settings: Settings;
    tech_break_expected: boolean;
  };
};

/**
 * Сообщение ожидания старта
 */
export type MessageStartExpected = Message & {
  start_expected: string;
};

/**
 * Сообщение старта
 */
export type MessageStart = Message & {
  tech_break_expected: boolean;
  payload: {
    odds: Odds;
  };
};

/**
 * Сообщение старта фактического на трассе
 */
export type MessageRaceStart = Message & {
  payload: {
    move_start_datetime: string;
  };
};

/**
 * Сообщение прогресса машинок
 */
export type MessageCarProgress = Message & {
  payload: {
    progress: Array<{
      line: number;
      progress: number;
    }>;
  };
};

/**
 * Сообщение финиша машинки
 */
export type MessageCarFinish = Message & {
  payload: {
    line: number;
    race_ms: number;
  };
};

/**
 * Сообщение финиша гонки
 */
export type MessageRaceFinish = Message & {
  payload: {
    line_pos: Array<number>;
    racer_pos: Array<number>;
    statistics: Array<Array<number>>;
  };
};

/**
 * Сообщение выигрыша
 */
export type MessageWin = Message & {
  payload: Array<{
    bet_id: number;
    win: number;
  }>;
};

import React from "react";

import { animated } from "@react-spring/web";

import { Props } from "./types";
import { useProgress } from "./hooks";

export const ProgressComponent = (props: Props) => {
  const { getCabinProps, getCarProgressProps, getWheelProps, getWindProps } =
    useProgress(props);
  return (
    <animated.div {...getCarProgressProps()}>
      <img {...getCabinProps()} />
      <img {...getWindProps()} />
      <animated.img {...getWheelProps()} />
      <animated.img {...getWheelProps()} />
    </animated.div>
  );
};

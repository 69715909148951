import { createAsyncThunk } from "@reduxjs/toolkit";

import { createBets } from "./api";
import { createGetBetsByParams, getNextId } from "src/selectors";
import { Error, RootState } from "src/types";
import { getBody } from "./utils";
import { RPCErrorCode, RPCResponse, RejectValue, Return } from "./types";

export const createBetsThunk = createAsyncThunk<
  Return,
  void,
  { rejectValue: RejectValue }
>("createBets", async (_, { getState, rejectWithValue }) => {
  const state = getState() as RootState;

  const gameId = getNextId(state);

  const bets = createGetBetsByParams([gameId], [], ["pending"])(state);

  try {
    if (process.env.NODE_ENV === "development") {
      return { bets, gameId, ids: [] };
    }

    const body = getBody(bets, gameId);

    const response = await createBets(body);
    const data: RPCResponse = await response.json();

    if (data.error && data.error.code) {
      switch (data.error && data.error.code) {
        case RPCErrorCode.ErrorLimit:
          throw "error_limit" as Error;
        case RPCErrorCode.ErrorBalance:
          throw "error_cash" as Error;
        default:
          throw "error_net" as Error;
      }
    }

    if (response.ok) {
      const ids = data?.result?.bet_ids || [];
      return { bets, gameId, ids };
    } else {
      throw "error_net" as Error;
    }
  } catch (error) {
    if ((error as Error) === "error_cash") {
      return rejectWithValue({ bets, error: "error_cash", gameId });
    } else if ((error as Error) === "error_limit") {
      return rejectWithValue({ bets, error: "error_limit", gameId });
    } else {
      return rejectWithValue({ bets, error: "error_net", gameId });
    }
  }
});

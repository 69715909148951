import React from "react";

import { useSelector } from "react-redux";

import { getGame } from "src/selectors";

import styles from "./style.module.scss";

export const useBroadcast = () => {
  const game = useSelector(getGame);

  const values = React.useMemo(
    () => ({
      game,
      styles,
    }),
    [game]
  );

  return { values };
};

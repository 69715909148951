import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { amountActions, mobileActions } from "src/slices";
import { Error } from "src/types";
import { getAmount, getEdit, getError, getErrorMessage } from "src/selectors";
import { getBounds } from "src/widgets/panel/model";
import { PopupIcon } from "./components";
import { useAppDispatch, useClickOutsideRef, useContent } from "src/hooks";

import styles from "./style.module.scss";

const POPUP_ERROR_ICON: Record<Error, PopupIcon> = {
  error_cash: "wallet",
  error_limit: "nominals",
  error_net: "repeat",
  none: "none",
};

export const useMobile = () => {
  const content = useContent();

  const dispatch = useAppDispatch();

  const error = useSelector(getError);
  const errorMessage = useSelector(getErrorMessage);

  const { edit, getStepProps, outsideRef, setStep, step } = useStep();

  const getPanelProps = React.useCallback(
    () => ({
      className: cn(styles[content], { [styles.visible]: edit }),
      ref: outsideRef,
    }),
    [content, edit, outsideRef]
  );

  const getPopupErrorProps = React.useCallback(
    () => ({
      icon: POPUP_ERROR_ICON[error],
      show: error !== "none",
      text: errorMessage,
    }),
    [error, errorMessage]
  );

  const getAmountsProps = React.useCallback(
    () => ({
      onClick: (value: number) => {
        dispatch(amountActions.amountUpdated(value));
        setStep(value);
      },
      value: step,
    }),
    [dispatch, setStep, step]
  );

  return {
    getAmountsProps,
    getPanelProps,
    getPopupErrorProps,
    getStepProps,
  };
};

let closeTimeout: NodeJS.Timeout;

export const useStep = () => {
  const dispatch = useAppDispatch();

  const amount = useSelector(getAmount);
  const bounds = useSelector(getBounds);
  const edit = useSelector(getEdit);

  const [min, max] = bounds;

  const [step, setStep] = React.useState<number>(0);

  React.useEffect(() => {
    if (closeTimeout) {
      clearTimeout(closeTimeout);
    }

    closeTimeout = setTimeout(
      () => dispatch(mobileActions.editUpdated(false)),
      3000
    );

    return () => clearTimeout(closeTimeout);
  }, [amount, dispatch]);

  React.useEffect(() => {
    setStep(min);
  }, [min]);

  const outsideRef = useClickOutsideRef(() => {
    if (closeTimeout) {
      clearTimeout(closeTimeout);
    }

    dispatch(mobileActions.editUpdated(false));
  });

  const getStepProps = React.useCallback(
    () => ({
      max,
      min,
      onChange: (value: number) => dispatch(amountActions.amountUpdated(value)),
      onClick: () => dispatch(mobileActions.editUpdated(true)),
      selected: true,
      step: step,
      value: amount,
    }),
    [amount, dispatch, max, min, step]
  );

  return { edit, getStepProps, outsideRef, setStep, step };
};

import React from "react";

import { Limit } from "src/components";
import { Odd } from "src/components";
import { SUMS } from "src/constants";
import { useV3 } from "./hooks";

import Sum12 from "src/assets/icons/sum-1-2-updated.svg";
import Sum123 from "src/assets/icons/sum-1-2-3-updated.svg";

export const V3Component = () => {
  const { getButtonProps, getLimitProps, getMarketProps, getSumProps, values } =
    useV3();
  return (
    <div {...getMarketProps()}>
      <div className={values.styles.header}>
        <div />
        <div className={values.styles.racers}>
          {SUMS.map((sum) => (
            <span key={sum} {...getSumProps(sum)}>
              {sum}
            </span>
          ))}
        </div>
        <div />
      </div>
      <div className={values.styles.body}>
        <div className={values.styles.cups}>
          <button {...getButtonProps("sumtop2")}>
            <img src={Sum12} />
          </button>
          <button {...getButtonProps("sumtop3")}>
            <img src={Sum123} />
          </button>
        </div>
        <div className={values.styles.bets}>
          {SUMS.map((sum) => (
            <React.Fragment key={sum}>
              <div>{values.sumtop2[sum] && <Odd odd={values.sumtop2[sum]} />}</div>
              <div>{values.sumtop3[sum] && <Odd odd={values.sumtop3[sum]} />}</div>
            </React.Fragment>
          ))}
          <div {...getLimitProps()}>
            <Limit market="sum" types={["sumtop2", "sumtop3"]} />
          </div>
        </div>
        <div className={values.styles.cups}>
          <button {...getButtonProps("sumtop2")}>
            <img src={Sum12} />
          </button>
          <button {...getButtonProps("sumtop3")}>
            <img src={Sum123} />
          </button>
        </div>
      </div>
    </div>
  );
};

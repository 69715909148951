import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { createGetOffset } from "src/pages/v3/model";
import { getNextId, getTheme } from "src/selectors";
import { useAnimationFrame } from "src/hooks";

import styles from "./style.module.scss";

export const useLight = () => {
  const nextId = useSelector(getNextId);
  const theme = useSelector(getTheme);

  const offset = useSelector(createGetOffset(nextId));

  const frustration = useFrustration(offset);

  const getLightProps = React.useCallback(
    () => ({
      className: cn(styles.light, styles[theme]),
      style: { opacity: frustration } as React.CSSProperties,
    }),
    [frustration, theme]
  );

  return { getLightProps };
};

export const useFrustration = (offset: number) => {
  const [frustration, setFrustration] = React.useState(offset);
  const [fps, setFps] = React.useState(15);

  React.useEffect(() => {
    if (offset === 0) {
      setFps(30);
    }
    if (offset === 1) {
      setFps(60);
    }
  }, [offset]);

  const animationHandler = React.useCallback(() => {
    const random =
      offset === 0 || offset === 1
        ? offset
        : offset === 1
        ? offset + Math.random() * 0.25
        : offset - Math.random() * 0.25;
    setFrustration(random);
  }, [offset]);

  useAnimationFrame(animationHandler, fps, offset);

  return frustration;
};

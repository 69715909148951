import { createSelector } from "reselect";

import { RootState } from "src/types";

/**
 * Селекторы туториала
 */

/**
 * tutorial
 * @param state - хранилище
 * @returns хранилище tutorial
 */
const getRoot = (state: RootState) => state.tutorial;

/**
 * Селектор получения режима отображения туториала
 */
export const getTutorial = createSelector([getRoot], (root) => root.tutorial);

import React from "react";

import { getFixed } from "src/components/odd/model";
import { Props } from "./types";
import { useOdd } from "./hooks";

export const OddComponent = (props: Props) => {
  const {
    getButtonProps,
    getCornerProps,
    getPrevProps,
    getRateProps,
    getSumProps,
    values,
  } = useOdd(props);

  if (values.isWin) {
    return (
      <button {...getButtonProps()}>
        <div className={values.styles.win}>
          <span>ВЫИГРЫШ</span>
          <span>{values.win}</span>
        </div>
      </button>
    );
  }

  return (
    <button {...getButtonProps()}>
      <div {...getCornerProps()} />
      <div className={values.styles.data}>
        <div className={values.styles.main}>
          <div {...getPrevProps()} />
          <div {...getRateProps()}>
            <span>{getFixed(props.odd.rate, 0)}</span>
            <span>.</span>
            <span>{getFixed(props.odd.rate, 1)}</span>
          </div>
        </div>
        <span {...getSumProps()}>{values.amount}</span>
      </div>
    </button>
  );
};

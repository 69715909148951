import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { socketActions } from "src/actions";
import { State } from "./types";

const STATE: State = {
  edit: false,
  live: false,
  tops: false,
};

export const mobileSlice = createSlice({
  name: "mobile",
  initialState: STATE,
  reducers: {
    editUpdated: (state, action: PayloadAction<boolean>) => {
      state.edit = action.payload;
    },
    liveUpdated: (state, action: PayloadAction<boolean>) => {
      state.live = action.payload;
    },
    topsUpdated: (state, action: PayloadAction<boolean>) => {
      state.tops = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(socketActions.close, () => STATE);
  },
});

export const mobileActions = {
  editUpdated: mobileSlice.actions.editUpdated,
  liveUpdated: mobileSlice.actions.liveUpdated,
  topsUpdated: mobileSlice.actions.topsUpdated,
};

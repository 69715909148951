import React from "react";
import cn from "classnames";

import { CouponsSwipeContext } from "src/contexts";
import { useContent } from "src/hooks";

import styles from "./style.module.scss";

export const useCoupons = () => {
  const content = useContent();

  const { style } = React.useContext(CouponsSwipeContext);

  const getCouponsProps = React.useCallback(
    () => ({
      className: cn(styles[content]),
      style,
    }),
    [content, style]
  );

  const values = React.useMemo(
    () => ({
      content,
      styles,
    }),
    [content]
  );

  return { getCouponsProps, values };
};

import React from "react";

import { BaseProps } from "src/types";
import { useSvg } from "./hooks";

export const DoubleComponent = (props: BaseProps) => {
  const { getSvgProps } = useSvg(props);
  return (
    <svg {...getSvgProps()}>
      <g filter="url(#filter0_i_1128_1749)">
        <path d="M9.04247 25.6699L9.68045 20.9924C10.0275 18.4479 11.1202 16.8763 13.8298 14.3692L16.6208 11.8246C18.1946 10.3653 18.536 9.54202 18.8219 7.44652L19.1077 5.35101C19.3271 3.74197 18.6806 3.44261 17.8023 3.44261C16.8858 3.44261 16.0608 3.89165 15.8515 5.42586L15.4177 8.60653H11.4462L11.8647 5.53811C12.3547 1.94583 14.5676 0 18.3864 0C22.3197 0 23.8416 1.72131 23.3465 5.35101L23.0096 7.82071C22.6575 10.4027 21.9671 11.8246 19.3874 14.2195L16.2067 17.1008C14.9359 18.2982 14.1185 18.9718 13.8429 20.9924L13.6796 22.1899H20.8206L20.346 25.6699H9.04247Z" />
        <path d="M1.21957e-08 19.8468L3.03732 12.9968L1.92029 6.91H4.2375L4.8813 11.1841L6.72997 6.91H8.83299L6.04253 13.0922L7.28267 19.8468H4.96546L4.17388 14.943L2.10302 19.8468H1.21957e-08Z" />
      </g>
      <defs>
        <filter
          id="filter0_i_1128_1749"
          x="0"
          y="0"
          width="23.4369"
          height="26.517"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.847091" />
          <feGaussianBlur stdDeviation="0.423545" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.105882 0 0 0 0 0.133333 0 0 0 0 0.223529 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_1128_1749"
          />
        </filter>
      </defs>
    </svg>
  );
};

import React from "react";

import { Icon } from "./components";
import { Props } from "./types";
import { useControl } from "./hooks";

export const ControlComponent = (props: Props) => {
  const { getButtonProps, values } = useControl(props);
  return (
    <button {...getButtonProps()}>
      <Icon type={props.type} />
      <span>{values.text}</span>
    </button>
  );
};

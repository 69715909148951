import React from "react";

import { animated } from "@react-spring/web";

import { List } from "./components";
import { useViews } from "./hooks";

export const ViewsComponent = () => {
  const { getViewsProps, values } = useViews();
  return (
    <animated.div {...getViewsProps()}>
      {values.views((style, view) => (
        <animated.div className={values.styles.view} style={style}>
          <List gameId={view.id} />
        </animated.div>
      ))}
    </animated.div>
  );
};

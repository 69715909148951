import React from "react";

import { animated } from "@react-spring/web";

import { useV3 } from "./hooks";

export const V3Component = () => {
  const { getProgressProps, getProgressRevertProps, getSpeedometerProps } = useV3();
  return (
    <div {...getSpeedometerProps()}>
      <animated.div {...getProgressProps()} />
      <animated.div {...getProgressRevertProps()} />
    </div>
  );
};

import React from "react";

import { Props } from "./types";
import { useGame } from "./hooks";

export const GameComponent = (props: Props) => {
  const { getGameProps, getIdProps, getStatusProps, values } = useGame(props);
  return (
    <div {...getGameProps()}>
      <span {...getStatusProps()}>{values.status}</span>
      <span {...getIdProps()}>{props.game.id}</span>
    </div>
  );
};

import React from "react";
import MuiModal from "@mui/material/Modal";

import { useOffline } from "./hooks";

import styles from "./style.module.scss";

const TEXTS = ["ОШИБКА СОЕДИНЕНИЯ", "Ожидайте, мы уже решаем проблему."];

export const OfflineComponent = React.memo(() => {
  const { getModalProps, values } = useOffline();
  return (
    <MuiModal {...getModalProps()}>
      <div className={styles[values.content]}>
        <div className={styles.content}>
          <span className={styles.title}>{TEXTS[0]}</span>
          <span className={styles.description}>{TEXTS[1]}</span>
        </div>
      </div>
    </MuiModal>
  );
});

OfflineComponent.displayName = "OfflineComponent";

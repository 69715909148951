import React from "react";

import { SxProps } from "@mui/material";
import { useSelector } from "react-redux";

import { AudioContext } from "src/contexts";
import { getDataTestId } from "src/utils";
import { getModal } from "src/selectors";
import { initConfetti } from "./utils";
import { modalActions } from "src/slices";
import { useAppDispatch, useContent } from "src/hooks";

export const useWin = () => {
  const content = useContent();

  const dispatch = useAppDispatch();

  const modal = useSelector(getModal);

  const canvasRef = React.useRef(null);

  const { play } = React.useContext(AudioContext);

  React.useEffect(() => {
    if (modal.win) {
      play("win");
      setTimeout(() => canvasRef.current && initConfetti(canvasRef.current), 0);
      setTimeout(() => {
        dispatch(modalActions.modalUpdated({ modal: "win", value: false }));
      }, 6000);
    }
  }, [dispatch, modal.win, play]);

  const getModalProps = React.useCallback(
    () => ({
      open: modal.win,
      sx: { backdropFilter: "blur(5px)" } as SxProps,
    }),
    [modal.win]
  );

  const getWinProps = React.useCallback(
    (className: string) => ({
      className,
      ...getDataTestId("win"),
    }),
    []
  );

  const values = React.useMemo(
    () => ({
      content,
    }),
    [content]
  );

  return {
    canvasRef,
    getModalProps,
    getWinProps,
    values,
  };
};

import { createSelector } from "reselect";

import { getDynamic, getSettings } from "src/selectors";

export const getAmounts = createSelector([getSettings], (settings) => {
  const [amount1, amount2, amount3, amount4] = settings.betSums;
  return [amount1 || 0, amount2 || 0, amount3 || 0, amount4 || 0];
});

export const getBounds = createSelector(
  [getAmounts, getDynamic],
  (amounts, dynamic) => [amounts[0], dynamic.max || 0]
);

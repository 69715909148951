import React from "react";

import { Market } from "src/types";
import { Props } from "./types";
import { useHeader } from "./hooks";

const TITLE: Record<Market, string> = {
  ["3-from-6"]: "3 ИЗ 6",
  ["competition"]: "СОРЕВНОВАНИЕ",
  ["result"]: "РЕЗУЛЬТАТ",
  ["sum"]: "СУММА ПОБЕДИТЕЛЕЙ",
  ["tops"]: "ПРИЗОВЫЕ МЕСТА",
  ["personal"]: "",
};

export const HeaderComponent = (props: Props) => {
  const { getButtonProps, getHeaderProps } = useHeader();
  return (
    <div {...getHeaderProps()}>
      {props.markets.map((market) => (
        <button key={market} {...getButtonProps(market)}>
          {TITLE[market]}
        </button>
      ))}
    </div>
  );
};

import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { getMarket } from "src/selectors";
import { useContent } from "src/hooks";

import styles from "./style.module.scss";

export const useMarketsBody = () => {
  const content = useContent();

  const market = useSelector(getMarket);

  const getBodyProps = React.useCallback(
    () => ({
      className: cn(styles[content], styles[market]),
    }),
    [content, market]
  );

  return { getBodyProps };
};

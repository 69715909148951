import React from "react";

import { config, useTransition } from "@react-spring/web";
import { useSelector } from "react-redux";

import { Content } from "src/types";
import { createGetCarsByGameId } from "src/selectors";
import { Props } from "./types";
import { useContent } from "src/hooks";

import styles from "./style.module.scss";

const SIZES: Record<Content, Array<number>> = {
  desktop: [],
  mobile: [6.5, 11],
  laptop: [4.5, 7.5],
  v3: [2, 3.5],
};

const STEPS: Record<Content, Array<number>> = {
  desktop: [],
  mobile: [110, 220],
  laptop: [110, 230],
  v3: [110, 250],
};

export const usePositions = (props: Props) => {
  const content = useContent();

  const positionsTransition = usePositionsTransition(props);

  const values = React.useMemo(
    () => ({
      content,
      positions: positionsTransition,
      styles,
    }),
    [content, positionsTransition]
  );

  return { values };
};

export const usePositionsTransition = (props: Props) => {
  const cars = useSelector(createGetCarsByGameId(props.game.id));

  const content = useContent();

  const [small, large] = SIZES[content];
  const [first, last] = STEPS[content];

  const carsSorted = [...cars].sort((a, b) => a.position - b.position);

  return useTransition(
    carsSorted.map((car, index) => {
      const x = `${index * first + (index > 2 ? last : 0)}%`;
      const height = `${index > 2 ? small : large}rem`;
      const width = `${index > 2 ? small : large}rem`;
      return { ...car, height, index, width, x };
    }),
    {
      keys: (car) => car.line,
      enter: ({ height, width, x }) => ({ height, width, x }),
      update: ({ height, width, x }) => ({ height, width, x }),
      config: { ...config.default, duration: 500 },
    }
  );
};

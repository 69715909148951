import { Bet } from "src/types";
import { RACERS } from "src/constants";

export const sort = (bets: Array<Bet>) => {
  const places: Array<Bet> = [];
  const tops: Array<Bet> = [];

  RACERS.forEach((racer) => {
    const top2 = bets.find((bet) => bet.racers[0] === racer && bet.type === "top2");
    const top3 = bets.find((bet) => bet.racers[0] === racer && bet.type === "top3");

    if (top2 && top3) {
      tops.push(top2, top3);
    }
  });

  RACERS.forEach((racer) => {
    for (let place = 1; place <= 6; place++) {
      const personal = bets.find(
        (bet) =>
          bet.racers[0] === racer &&
          bet.places[0] === place &&
          bet.type === "personal"
      );
      if (personal) {
        places.push(personal);
      }
    }
  });

  return [places, tops];
};

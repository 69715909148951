import React from "react";

import { BaseProps } from "src/types";
import { Context } from "./types";
import { useAudio } from "./hooks";

export const AudioContext = React.createContext<Context>({} as Context);

export const AudioProvider = (props: BaseProps) => {
  const { getProviderProps } = useAudio();
  return (
    <AudioContext.Provider {...getProviderProps()}>
      {props.children}
    </AudioContext.Provider>
  );
};

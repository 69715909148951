import React from "react";

import { Statistics } from "src/components";
import { Tab } from "./components";
import { useV3 } from "./hooks";

export const V3Component = React.memo(() => {
  const { values } = useV3();
  return (
    <div className={values.styles.tabs}>
      <Statistics />
      {values.tabs((style, tab) => (
        <Tab game={tab.game} style={style} />
      ))}
    </div>
  );
});

V3Component.displayName = "V3Component";

import React from "react";

import { Balances } from "./components";
import { Props } from "./types";
import { useTotal } from "./hooks";

export const TotalComponent = (props: Props) => {
  const { getCountProps, getTextProps, getWinProps, getTotalProps, values } =
    useTotal(props);

  let view = null;

  switch (values.content) {
    case "desktop":
    case "mobile":
    case "laptop": {
      view = (
        <div {...getTotalProps()}>
          {values.count > 0 && (
            <>
              <span className={values.styles.label}>СУММА СТАВОК:</span>
              <Balances game={props.game} />
            </>
          )}
        </div>
      );
      break;
    }
    case "v3": {
      view = (
        <div {...getTotalProps()}>
          <span {...getCountProps()}>{values.count}</span>
          <span {...getTextProps()}>{values.text}</span>
          <span {...getWinProps()}>{values.win}</span>
          {!values.isWin && <Balances game={props.game} />}
        </div>
      );
    }
  }

  return view;
};

import { createSelector } from "reselect";

import { RootState } from "src/types";

/**
 * Селекторы мобильной версии
 */

/**
 * mobile
 * @param state - хранилище
 * @returns хранилище mobile
 */
const getRoot = (state: RootState) => state.mobile;

/**
 * Селектор получения режима редактирования номинала
 */
export const getEdit = createSelector([getRoot], (root) => root.edit);

/**
 * Селектор получения режима просмотра эфира
 */
export const getLive = createSelector([getRoot], (root) => root.live);

/**
 * Селектор получения режима просмотра статистики
 */
export const getTops = createSelector([getRoot], (root) => root.tops);

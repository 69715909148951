import { createAction } from "@reduxjs/toolkit";

import {
  MessageCarFinish,
  MessageCarProgress,
  MessageInit,
  Message,
  MessageRaceFinish,
  MessageRaceStart,
  MessageStartExpected,
  MessageStart,
  MessageWin,
} from "src/types";

export const socketActions = {
  close: createAction("socket/close"),
  connect: createAction("socket/connect"),
  open: createAction("socket/open"),
  message: createAction<Message>("socket/message"),
  messageInit: createAction<MessageInit>("socket/messageInit"),
  messageStartExpected: createAction<MessageStartExpected>(
    "socket/messageStartExpected"
  ),
  messageStart: createAction<MessageStart>("socket/messageStart"),
  messageRaceStart: createAction<MessageRaceStart>("socket/messageRaceStart"),
  messageCarProgress: createAction<MessageCarProgress>("socket/messageCarProgress"),
  messageCarFinish: createAction<MessageCarFinish>("socket/messageCarFinish"),
  messageRaceFinish: createAction<MessageRaceFinish>("socket/messageRaceFinish"),
  messageFinish: createAction<Message>("socket/messageFinish"),
  messageWin: createAction<MessageWin>("socket/messageWin"),
  messageAbort: createAction<Message>("socket/messageAbort"),
  messageTechBreak: createAction<Message>("socket/messageTechBreak"),
  messageEndTechBreak: createAction<Message>("socket/messageEndTechBreak"),
};

import React from "react";

import { Double, Repeat, Undo } from "src/shared/components/icons";
import { Props } from "./types";

export const IconComponent = (props: Props) => {
  let icon = null;

  switch (props.type) {
    case "double":
      icon = <Double />;
      break;
    case "repeat":
      icon = <Repeat />;
      break;
    case "undo":
      icon = <Undo />;
      break;
  }

  return icon;
};

import React from "react";

import { Props } from "./types";
import { useStep } from "./hooks";

export const StepComponent = (props: Props) => {
  const { getDecProps, getIncProps, getStepProps, getValueProps } = useStep(props);
  return (
    <div {...getStepProps()}>
      <button {...getDecProps()} />
      <button {...getValueProps()}>
        <span>{props.value}</span>
      </button>
      <button {...getIncProps()} />
    </div>
  );
};

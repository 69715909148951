import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";
import { useSpring } from "@react-spring/web";
import { useSwipeable } from "react-swipeable";

import { getEdit, getLive } from "src/selectors";
import { useContent, useWindowSize } from "src/hooks";

import styles from "./style.module.scss";

export const useCouponsSwipe = () => {
  const content = useContent();

  const edit = useSelector(getEdit);
  const live = useSelector(getLive);

  const [open, setOpen] = React.useState<boolean>(false);

  const [max, setMax] = React.useState<number>(0);
  const [min, setMin] = React.useState<number>(0);

  const windowSize = useWindowSize();

  const [{ height }, api] = useSpring(
    () => ({ height: open ? max : min, immediate: true }),
    [max, min, open]
  );

  const onMax = React.useCallback((value: number) => {
    setMax(value);
  }, []);

  const onMin = React.useCallback((value: number) => {
    setMin(value);
  }, []);

  const onOpen = React.useCallback(() => {
    api.start({ height: max, immediate: true });

    setOpen(true);
  }, [api, max]);

  const onClose = React.useCallback(() => {
    api.start({ height: min, immediate: true });

    setOpen(false);
  }, [api, min]);

  const onUpdate = React.useCallback(() => {
    if (open) {
      onClose();
    } else {
      onOpen();
    }
  }, [onClose, onOpen, open]);

  const handlers = useSwipeable({
    onSwipedDown: ({ absY }) => {
      if (open) {
        const isInside = absY < windowSize[0] * 0.08;
        if (isInside) {
          onOpen();
        } else {
          onClose();
        }
      }
    },
    onSwipedUp: ({ absY }) => {
      if (!open) {
        const isInside = absY < windowSize[0] * 0.08;
        if (isInside) {
          onClose();
        } else {
          onOpen();
        }
      }
    },
    onSwiping: ({ absY, deltaY }) => {
      let height = 0;

      if (open) {
        height = deltaY > 0 ? max - absY : max;
      }
      if (!open) {
        height = deltaY < 0 ? min + absY : min;
      }

      api.start({ height, immediate: true });
    },
    trackMouse: true,
  });

  /**
   * SWIPE CONTROL
   */
  const refHead = React.useCallback(
    (node: HTMLDivElement) => {
      if (!node) return;
      handlers.ref(node);
      const resizeObserver = new ResizeObserver(() => {
        onMin(node.offsetHeight);
      });
      resizeObserver.observe(node);
    },
    [handlers, onMin]
  );

  /**
   * SWIPE TRANSFORM
   */
  const refWrap = React.useCallback(
    (node: HTMLDivElement) => {
      if (!node) return;
      const resizeObserver = new ResizeObserver(() => {
        onMax(node.offsetHeight);
      });
      resizeObserver.observe(node);
    },
    [onMax]
  );

  const getWrapperProps = React.useCallback(
    () => ({
      className: cn(styles[content], {
        [styles.live]: live,
        [styles.edit]: edit,
      }),
      ref: refWrap,
    }),
    [content, edit, live, refWrap]
  );

  const getProviderProps = React.useCallback(
    () => ({
      value: {
        handlers,
        onClose,
        onMax,
        onMin,
        onUpdate,
        refHead,
        refWrap,
        style: { height },
      },
    }),
    [handlers, height, onClose, onMax, onMin, onUpdate, refHead, refWrap]
  );

  return { getProviderProps, getWrapperProps };
};

import { Bet } from "src/types";
import { SUMS } from "src/constants";

export const sort = (bets: Array<Bet>) => {
  const sumtop2: Record<number, Bet> = {};
  const sumtop3: Record<number, Bet> = {};

  SUMS.forEach((sum) => {
    const bet = bets.find((bet) => bet.type === "sumtop2" && bet.racers[0] === sum);
    if (bet) {
      sumtop2[sum] = bet;
    }
  });

  SUMS.forEach((sum) => {
    const bet = bets.find((bet) => bet.type === "sumtop3" && bet.racers[0] === sum);
    if (bet) {
      sumtop3[sum] = bet;
    }
  });

  return { sumtop2, sumtop3 };
};

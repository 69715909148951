import React from "react";

import { getDataTestId } from "src/utils";
import { modalActions } from "src/slices";
import { useAppDispatch, useContent } from "src/hooks";

export const useFooter = () => {
  const content = useContent();

  const dispatch = useAppDispatch();

  const getButtonProps = React.useCallback(
    (className: string) => ({
      className,
      onClick: () =>
        dispatch(modalActions.modalUpdated({ modal: "win", value: false })),
      ...getDataTestId("win__continue"),
    }),
    [dispatch]
  );

  const values = React.useMemo(
    () => ({
      content,
    }),
    [content]
  );

  return { getButtonProps, values };
};

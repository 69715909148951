import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";
import { useSpring, useSpringRef } from "@react-spring/web";

import { getKind } from "src/selectors";
import { Props } from "./types";
import { resetCar } from "./actions";
import { useAppDispatch } from "src/hooks";

import styles from "./style.module.scss";

export const useCar = (props: Props) => {
  useReset(props);

  const carSpring = useCarSpring(props);

  const getCarProps = React.useCallback(
    () => ({
      className: cn(styles.car, styles[`line-${props.car.line}`], {
        [styles[`line-finished-${props.car.line}`]]: props.car.status === "finish",
        [styles.first]: props.car.status === "finish" && props.car.position === 1,
        [styles.second]: props.car.status === "finish" && props.car.position === 2,
        [styles.third]: props.car.status === "finish" && props.car.position === 3,
      }),
      style: carSpring,
    }),
    [carSpring, props.car.line, props.car.position, props.car.status]
  );
  return { getCarProps };
};

export const useReset = (props: Props) => {
  const dispatch = useAppDispatch();

  const kind = useSelector(getKind);

  React.useEffect(() => {
    if (kind === "finish") {
      setTimeout(() => dispatch(resetCar(props.car)), props.car.line * 100 + 15000);
    }
  }, [dispatch, kind, props.car]);
};

export const useCarSpring = (props: Props) => {
  const progressCarApi = useSpringRef();
  const progressCarSpring = useSpring({
    ref: progressCarApi,
    from: { transform: "perspective(1000px) rotateX(0deg)" },
    config: { duration: 0 },
  });

  React.useEffect(() => {
    if (props.car.status === "none") {
      progressCarApi.start({
        to: async (next) => {
          await next({
            transform: "perspective(1000px) rotateX(360deg)",
            config: { duration: 1000 },
          });
          await next({
            transform: "perspective(1000px) rotateX(0deg)",
            config: { duration: 0 },
          });
        },
      });
    }
  }, [progressCarApi, props.car.status]);

  return progressCarSpring;
};

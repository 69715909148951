import { createSelector } from "reselect";

import { RootState } from "src/types";

/**
 * Селекторы области купонов
 */

/**
 * coupons
 * @param state - хранилище
 * @returns хранилище coupons
 */
const getRoot = (state: RootState) => state.coupons;

/**
 * Селектор получения режима отображения
 */
export const getView = createSelector([getRoot], (root) => root.view);

import React from "react";

import { Cup } from "src/shared/components";
import { Limit } from "src/components";
import { Odd } from "src/components";
import { useDesktop } from "./hooks";

const CUPS = [
  <Cup key="sum12" type="sum12" variant="old" />,
  <Cup key="sum123" type="sum123" variant="old" />,
];

export const DesktopComponent = () => {
  const { values } = useDesktop();
  return (
    <>
      <div className={values.styles.market}>
        <div className={values.styles.header}>{CUPS}</div>
        <div className={values.styles.body}>
          {values.sums.map((bets, index) => (
            <div key={index} className={values.styles.bets}>
              {bets.map((bet, index) => (
                <div key={index} className={values.styles.bet}>
                  <span className={values.styles.racers}>{bet.racers}</span>
                  <Odd odd={bet} />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <Limit market="sum" types={["sumtop2", "sumtop3"]} />
    </>
  );
};

DesktopComponent.displayName = "DesktopComponent";

import React from "react";

import { useSelector } from "react-redux";

import { createGetGameById, getGameId } from "src/selectors";
import { getDataTestId, getLocale } from "src/utils";
import { useContent } from "src/hooks";

export const useBody = () => {
  const content = useContent();

  const gameId = useSelector(getGameId);

  const game = useSelector(createGetGameById(gameId));

  const win = getLocale(game?.win || 0, 1);

  const getLabelProps = React.useCallback(
    (className: string) => ({
      className,
      ...getDataTestId("win__label"),
    }),
    []
  );

  const values = React.useMemo(
    () => ({
      content,
      win,
    }),
    [content, win]
  );

  return { getLabelProps, values };
};

import React from "react";

import { BaseProps } from "src/types";
import { useSvg } from "./hooks";

export const RepeatComponent = (props: BaseProps) => {
  const { getSvgProps } = useSvg(props);
  return (
    <svg {...getSvgProps()}>
      <g filter="url(#filter0_i_1128_1763)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.573 0.617188L24.5819 6.89415L14.8181 14.3202L12.8468 11.6122L16.5375 8.80519H8.90336C7.68833 8.80519 6.56947 9.78102 6.4043 10.9848L5.46636 17.8207L1.37699 21.3873L2.8043 10.9848C3.23973 7.81125 6.18947 5.23861 9.39272 5.23861H16.2759L14.0824 3.51944L16.573 0.617188ZM23.3363 11.4801L21.8411 22.378C21.4056 25.5515 18.4559 28.1242 15.2526 28.1242H7.09822L9.19549 30.5289L6.30387 33.0508L0.451736 26.3409L8.14518 19.631L10.3447 22.1529L7.58759 24.5576H15.742C16.957 24.5576 18.0759 23.5818 18.2411 22.378L19.247 15.0467L23.3363 11.4801Z"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_1128_1763"
          x="0.451721"
          y="0.617188"
          width="24.1302"
          height="33.3318"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.898182" />
          <feGaussianBlur stdDeviation="0.449091" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.105882 0 0 0 0 0.133333 0 0 0 0 0.223529 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_1128_1763"
          />
        </filter>
      </defs>
    </svg>
  );
};

import React from "react";

import { Props } from "./types";
import { useTime } from "./hooks";

export const TimeComponent = (props: Props) => {
  const { getMsProps, getScProps, getTimeProps, values } = useTime(props);
  return (
    <div {...getTimeProps()}>
      <span {...getScProps()}>{values.sc}</span>
      <span {...getMsProps()}>{values.ms}</span>
    </div>
  );
};

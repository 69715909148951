import React from "react";

import { SxProps } from "@mui/material";
import { useSelector } from "react-redux";

import { getModal } from "src/selectors";
import { modalActions } from "src/slices";
import { useAppDispatch, useContent } from "src/hooks";

export const useLive = () => {
  const content = useContent();

  const dispatch = useAppDispatch();

  const modal = useSelector(getModal);

  React.useEffect(() => {
    return () => {
      if (modal.live) {
        dispatch(modalActions.modalUpdated({ modal: "live", value: false }));
      }
    };
  }, [dispatch, modal.live]);

  const getModalProps = React.useCallback(
    () => ({
      open: modal.live,
      sx: { backdropFilter: "blur(5px)" } as SxProps,
    }),
    [modal.live]
  );

  const values = React.useMemo(
    () => ({
      content,
    }),
    [content]
  );

  return { getModalProps, values };
};

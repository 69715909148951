import React from "react";

import { Line } from "src/shared/components";
import { Props } from "./types";
import { useResult } from "./hooks";

export const ResultComponent = (props: Props) => {
  const { getCupProps, getTimeProps, values } = useResult(props);
  return (
    <div className={values.styles.result}>
      <Line line={props.car.line} testId="p-line" />
      <img {...getCupProps()} />
      <div {...getTimeProps()}>
        <span>{values.sc}</span>
        <span>{values.ms}</span>
      </div>
    </div>
  );
};

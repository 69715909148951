import React from "react";

import { useSelector } from "react-redux";

import { createGetBetsByParams, getNextId } from "src/selectors";
import { sort } from "./utils";
import { useContent } from "src/hooks";

import styles from "./styles.module.scss";

export const useMobile = () => {
  const content = useContent();

  const nextId = useSelector(getNextId);

  const bets = useSelector(
    createGetBetsByParams([nextId], ["sumtop2", "sumtop3"], [])
  );

  const { sumtop2, sumtop3 } = sort(bets);

  const values = React.useMemo(
    () => ({
      content,
      styles,
      sumtop2,
      sumtop3,
    }),
    [content, sumtop2, sumtop3]
  );

  return { values };
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { socketActions } from "src/actions";
import { State } from "./types";

const STATE: State = {
  viewId: 0,
};

export const v3Slice = createSlice({
  name: "v3",
  initialState: STATE,
  reducers: {
    viewIdUpdated: (state, action: PayloadAction<number>) => {
      state.viewId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(socketActions.close, () => STATE);
  },
});

export const v3Actions = {
  viewIdUpdated: v3Slice.actions.viewIdUpdated,
};

import { Bet } from "src/types";
import { PLACES, RACERS } from "src/constants";

export const sort = (bets: Array<Bet>) => {
  const places: Record<number, Record<number, Bet>> = {};

  RACERS.forEach((racer) => {
    places[racer] = {};
    PLACES.forEach((place) => {
      const bet = bets.find(
        (bet) =>
          bet.type === "personal" &&
          bet.racers[0] === racer &&
          bet.places[0] === place
      );
      if (bet) {
        places[racer][place] = bet;
      }
    });
  });

  return places;
};

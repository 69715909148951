import React from "react";

import { useSelector } from "react-redux";

import { getModal } from "src/selectors";

export const useModal = () => {
  const modal = useSelector(getModal);

  const values = React.useMemo(
    () => ({
      modal,
    }),
    [modal]
  );

  return { values };
};

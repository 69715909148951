import { Bet } from "src/types";
import { RACERS } from "src/constants";

export const sort = (bets: Array<Bet>) => {
  const top2: Record<number, Bet> = [];
  const top3: Record<number, Bet> = [];

  RACERS.forEach((racer) => {
    const top = bets.find((bet) => bet.racers[0] === racer && bet.type === "top2");
    if (top) {
      top2[racer] = top;
    }
  });

  RACERS.forEach((racer) => {
    const top = bets.find((bet) => bet.racers[0] === racer && bet.type === "top3");
    if (top) {
      top3[racer] = top;
    }
  });

  return { top2, top3 };
};

import React from "react";

import { Bet } from "src/components";
import { useControls } from "./hooks";

export const ControlsComponent = React.memo(() => {
  const { getBetWrapperProps, getHideProps, values } = useControls();
  return (
    <div className={values.styles[values.content]}>
      <button {...getHideProps()}>СВЕРНУТЬ</button>
      <div {...getBetWrapperProps()}>
        <Bet />
      </div>
    </div>
  );
});

ControlsComponent.displayName = "ControlsComponent";

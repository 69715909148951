import React from "react";

import { useTransition } from "@react-spring/web";
import { useSelector } from "react-redux";

import { getKind } from "src/selectors";

export const useLoader = () => {
  const loaderTransition = useLoaderTransition();

  const values = React.useMemo(
    () => ({
      loader: loaderTransition,
    }),
    [loaderTransition]
  );

  return { values };
};

export const useLoaderTransition = () => {
  const kind = useSelector(getKind);
  const isLoader = kind === "none";
  return useTransition(isLoader, {
    from: { opacity: 1 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });
};

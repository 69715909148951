import React from "react";

import { Props } from "./types";
import { useBalances } from "./hooks";

export const BalancesComponent = (props: Props) => {
  const { getBalanceProps, values } = useBalances(props);
  return (
    <div className={values.styles[values.content]}>
      {values.balances.map((balance, index) => (
        <React.Fragment key={balance.id}>
          {index > 0 && <span> + </span>}
          <span {...getBalanceProps(balance.id)}>{balance.amount}</span>
        </React.Fragment>
      ))}
    </div>
  );
};

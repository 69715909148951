import { getUrl } from "./utils";

export class Socket {
  socket: WebSocket | null;

  constructor() {
    this.socket = null;
  }

  connect() {
    if (!this.socket) {
      const url = getUrl("wss");

      this.socket = new WebSocket(url);
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
  }

  onopen(callback: ((ev: Event) => void) | null) {
    if (this.socket) {
      this.socket.onopen = callback;
    }
  }

  onclose(callback: ((ev: Event) => void) | null) {
    if (this.socket) {
      this.socket.onclose = callback;
    }
  }

  onmessage(callback: ((ev: MessageEvent) => void) | null) {
    if (this.socket) {
      this.socket.onmessage = callback;
    }
  }
}

import React from "react";

import { Positions, Status } from "src/components";
import { useHeader } from "./hooks";

export const HeaderComponent = () => {
  const { getHeaderProps, values } = useHeader();
  return (
    <div {...getHeaderProps()}>
      {values.game && <Positions game={values.game} />}
      {values.game && <Status game={values.game} />}
    </div>
  );
};

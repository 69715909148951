export const getLabel = (count: number, declinations: Array<string>) => {
  const n = Math.abs(count) % 100;
  const n1 = n % 10;
  if (n > 10 && n < 20) {
    return declinations[0];
  }
  if (n1 > 1 && n1 < 5) {
    return declinations[1];
  }
  if (n1 === 1) {
    return declinations[2];
  }
  return declinations[3];
};

import React from "react";

import { useBackground } from "./hooks";

export const BackgroundComponent = React.memo(() => {
  const { getBackgroundProps, values } = useBackground();
  return (
    <div {...getBackgroundProps()}>
      <div className={values.styles.jaw} />
      <div className={values.styles.main} />
    </div>
  );
});

BackgroundComponent.displayName = "BackgroundComponent";

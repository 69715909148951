import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { getDataTestId } from "src/utils";
import { getVersion } from "src/selectors";
import { useContent } from "src/hooks";

import styles from "./style.module.scss";

export const useVersion = () => {
  const content = useContent();

  const version = useSelector(getVersion);

  const getVersionProps = React.useCallback(
    () => ({
      className: cn(styles[content]),
      ...getDataTestId("version"),
    }),
    [content]
  );

  const values = React.useMemo(
    () => ({
      styles,
      version,
    }),
    [version]
  );

  return { getVersionProps, values };
};

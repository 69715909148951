import { createSelector } from "reselect";

import { createGetBetsByParams, getError } from "src/selectors";

export const createGetOffset = (nextId: number) =>
  createSelector(
    [
      createGetBetsByParams([nextId], [], ["confirmed"]),
      createGetBetsByParams([nextId], [], ["selected", "pending"]),
      getError,
    ],
    (confirmed, selected, error) => {
      if (error !== "none") {
        return 1;
      }

      if (selected.length) {
        const offset = selected.reduce((res, bet) => {
          const rate = bet.rate;

          let add = 0;

          if (bet.status !== "none") {
            if (rate < 10) {
              add = 0.05;
            } else if (rate >= 10 && rate < 50) {
              add = 0.1;
            } else if (rate >= 50 && rate < 100) {
              add = 0.3;
            } else {
              add = 0.4;
            }
          }

          res += add;

          return res > 0.8 ? 0.8 : res;
        }, 0);

        return offset < 0.3 ? 0 : offset;
      }

      if (confirmed.length) {
        return 1;
      }

      return 0;
    }
  );

import { createSelector } from "reselect";

import { Bet } from "src/types";
import { createGetBetsByParams, getError } from "src/selectors";

export const createGetOffsetDesktop = (bets: Array<Bet>) =>
  createSelector([getError], (error) => {
    if (error !== "none") {
      return 1;
    }

    if (bets.some((bet) => bet.status === "confirmed")) {
      return 0.9;
    }

    return bets.reduce((res, bet) => {
      const rate = bet.rate;
      let add = 0;

      if (bet.status !== "none") {
        if (rate < 10) {
          add = 0.079;
        } else if (rate >= 10 && rate < 50) {
          add = 0.158;
        } else if (rate >= 50 && rate < 100) {
          add = 0.237;
        } else {
          add = 0.395;
        }
      }

      res += add;

      return res > 0.79 ? 0.79 : res;
    }, 0);
  });

export const createGetOffsetV3 = (nextId: number) =>
  createSelector(
    [
      createGetBetsByParams([nextId], [], ["confirmed"]),
      createGetBetsByParams([nextId], [], ["selected", "pending"]),
      getError,
    ],
    (confirmed, selected, error) => {
      if (error !== "none") {
        return 50;
      }

      if (selected.length) {
        return selected.reduce((res, bet) => {
          const rate = bet.rate;

          let add = 0;

          if (bet.status !== "none") {
            if (rate < 10) {
              add = 5;
            } else if (rate >= 10 && rate < 50) {
              add = 10;
            } else if (rate >= 50 && rate < 100) {
              add = 15;
            } else {
              add = 20;
            }
          }

          res += add;

          return res > 35 ? 35 : res;
        }, 0);
      }

      if (confirmed.length) {
        return 45;
      }

      return 0;
    }
  );

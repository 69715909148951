import React from "react";
import cn from "classnames";
import MuiModal from "@mui/material/Modal";

import { Body, Footer, Header } from "./components";
import { useWin } from "./hooks";

import styles from "./style.module.scss";

import Shine from "src/assets/images/desktop/shine.png";

export const WinComponent = React.memo(() => {
  const { canvasRef, getModalProps, getWinProps, values } = useWin();
  return (
    <MuiModal {...getModalProps()}>
      <div {...getWinProps(cn(styles[values.content]))}>
        <canvas id="confetti" className={styles.confetti} ref={canvasRef} />
        <img className={styles.shine} src={Shine} />
        <Header />
        <Body />
        <Footer />
      </div>
    </MuiModal>
  );
});

WinComponent.displayName = "WinComponent";

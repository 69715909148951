import React from "react";

import { SxProps } from "@mui/material";
import { useSelector } from "react-redux";

import { getMessage, getModal } from "src/selectors";
import { modalActions } from "src/slices";
import { useAppDispatch, useContent } from "src/hooks";

export const useAbort = () => {
  const content = useContent();

  const dispatch = useAppDispatch();

  const message = useSelector(getMessage);
  const modal = useSelector(getModal);

  const timerRef = React.useRef(true);
  const mountRef = React.useRef(false);

  React.useEffect(() => {
    if (!timerRef.current && modal.abort) {
      dispatch(modalActions.modalUpdated({ modal: "abort", value: false }));
    }

    if (!mountRef.current) {
      mountRef.current = true;

      setTimeout(() => {
        timerRef.current = false;
      }, 5000);
    }
  }, [dispatch, message, modal.abort]);

  const getModalProps = React.useCallback(
    () => ({
      open: modal.abort,
      sx: { backdropFilter: "blur(5px)" } as SxProps,
    }),
    [modal.abort]
  );

  const values = React.useMemo(
    () => ({
      content,
    }),
    [content]
  );

  return { getModalProps, values };
};

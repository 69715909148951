import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { AudioContext } from "src/contexts";
import { betRemovedThunk } from "src/thunks";
import {
  createGetBetWin,
  getBackground,
  getStatus,
  getTitle,
} from "src/widgets/coupons/model";
import { getDataTestId } from "src/utils";
import { getGameId } from "src/selectors";
import { Props } from "./types";
import { useAppDispatch, useContent } from "src/hooks";

import styles from "./style.module.scss";

export const useCoupon = (props: Props) => {
  const content = useContent();

  const dispatch = useAppDispatch();

  const gameId = useSelector(getGameId);

  const win = useSelector(createGetBetWin(props.bet));

  const { play } = React.useContext(AudioContext);

  const isLoading = props.bet.status === "pending";
  const isConfirmed = props.bet.status === "confirmed";
  const isSelected = props.bet.status === "selected";
  const isWin = props.bet.status === "finish" && props.bet.win > 0;
  const isLoss = props.bet.status === "finish" && props.bet.win === 0;

  const background = getBackground(props.bet);
  const status = getStatus(props.bet, gameId);
  const title = getTitle(props.bet);

  const view = React.useMemo(
    () => (props.bet.gameId === gameId ? "curr" : "next"),
    [gameId, props.bet.gameId]
  );

  const getContainerProps = React.useCallback(
    () => ({
      className: cn(styles[content], {
        [styles.selected]: isSelected,
        [styles.pending]: isLoading,
        [styles.confirmed]: isConfirmed,
        [styles.win]: isWin,
        [styles.loss]: isLoss,
      }),
      ...getDataTestId(`coupon-${view}`),
    }),
    [content, isConfirmed, isLoading, isLoss, isSelected, isWin, view]
  );

  const getDataProps = React.useCallback(
    () => ({
      className: cn(styles.data, {
        [styles.sumtop2]: props.bet.type === "sumtop2",
        [styles.sumtop3]: props.bet.type === "sumtop3",
      }),
    }),
    [props.bet.type]
  );

  const getButtonProps = React.useCallback(
    () => ({
      className: cn(styles.button),
      disabled: props.bet.status !== "selected",
      onClick: () => {
        play("control");
        dispatch(betRemovedThunk(props.bet));
      },
      ...getDataTestId(`button-coupon-${view}-remove`),
    }),
    [dispatch, play, props.bet, view]
  );

  const getLineProps = React.useCallback(
    (line: number) => ({
      className: cn(styles.line, {
        [styles.odd]:
          props.bet.type === "sumtop2" ||
          props.bet.type === "sumtop3" ||
          line % 2 !== 0,
      }),
    }),
    [props.bet.type]
  );

  const getLinesProps = React.useCallback(
    () => ({
      className: styles.lines,
      style: { background } as React.CSSProperties,
      ...getDataTestId(`div-coupon-${view}-positions`),
    }),
    [background, view]
  );

  const getTitleProps = React.useCallback(
    () => ({
      className: cn(styles.title),
      ...getDataTestId(`h6-coupon-${view}-type`),
    }),
    [view]
  );

  const getRateProps = React.useCallback(
    () => ({
      className: styles.rate,
      ...getDataTestId(`coupon-${view}-rate`),
    }),
    [view]
  );

  const getWinProps = React.useCallback(
    () => ({
      className: cn(styles.title),
      style: { fontWeight: 400 } as React.CSSProperties,
      ...getDataTestId(`p-coupon-${view}-win`),
    }),
    [view]
  );

  const getSpinnerProps = React.useCallback(
    () => ({
      className: cn(styles.spinner, {
        [styles.spin]: isLoading,
      }),
    }),
    [isLoading]
  );

  const values = React.useMemo(
    () => ({
      status,
      title,
      win,
    }),
    [status, title, win]
  );

  return {
    getButtonProps,
    getContainerProps,
    getDataProps,
    getLineProps,
    getLinesProps,
    getRateProps,
    getSpinnerProps,
    getTitleProps,
    getWinProps,
    values,
  };
};

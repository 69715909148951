import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { getGameIdByView } from "src/widgets/coupons/model";
import { useContent } from "src/hooks";

import styles from "./style.module.scss";
import { createGetGameById } from "src/selectors";

export const useBody = () => {
  const content = useContent();

  const gameId = useSelector(getGameIdByView);

  const game = useSelector(createGetGameById(gameId));

  const getBodyProps = React.useCallback(
    () => ({
      className: cn(styles[content]),
    }),
    [content]
  );

  const values = React.useMemo(
    () => ({
      content,
      game,
      styles,
    }),
    [content, game]
  );

  return { getBodyProps, values };
};

import React from "react";

import { getLocale } from "src/utils";
import { Props } from "./types";
import { useContent } from "src/hooks";

import Competition from "src/assets/icons/cups-competition-updated.svg";
import Cup1 from "src/assets/icons/cup-1-updated.svg";
import Cup2 from "src/assets/icons/cup-2-updated.svg";
import Cup3 from "src/assets/icons/cup-3-updated.svg";
import Cup4 from "src/assets/icons/cup-4-updated.svg";
import Cup5 from "src/assets/icons/cup-5-updated.svg";
import Cup6 from "src/assets/icons/cup-6-updated.svg";
import Top2 from "src/assets/icons/cups-2-empty-updated.svg";
import Top3 from "src/assets/icons/cups-3-empty-updated.svg";
import Sum12 from "src/assets/icons/sum-1-2-empty-updated.svg";
import Sum123 from "src/assets/icons/sum-1-2-3-empty-updated.svg";

const PERSONAL: Record<number, string> = {
  1: Cup1,
  2: Cup2,
  3: Cup3,
  4: Cup4,
  5: Cup5,
  6: Cup6,
};

export const useBet = (props: Props) => {
  const content = useContent();

  const win = getLocale(props.bet.win, 1);

  const src = React.useMemo(() => {
    switch (props.bet.type) {
      case "competition":
        return Competition;
      case "personal": {
        const racer = props.bet.racers[0];
        return PERSONAL[racer] || Cup1;
      }
      case "top2":
        return Top2;
      case "top3":
        return Top3;
      case "sumtop2":
        return Sum12;
      case "sumtop3":
        return Sum123;
      default:
        return Cup1;
    }
  }, [props.bet.racers, props.bet.type]);

  const getCupProps = React.useCallback(
    () => ({
      src,
    }),
    [src]
  );

  const isSum = props.bet.type === "sumtop2" || props.bet.type === "sumtop3";

  const getLinesProps = React.useCallback(
    (className: string) => ({
      className,
      style: { background: isSum ? "#070E4A" : "" } as React.CSSProperties,
    }),
    [isSum]
  );

  const getLineProps = React.useCallback(
    (className: string) => ({
      className,
      style: { color: isSum ? "#ffffff" : "" } as React.CSSProperties,
    }),
    [isSum]
  );

  const values = React.useMemo(
    () => ({
      content,
      win,
    }),
    [content, win]
  );

  return { getCupProps, getLineProps, getLinesProps, values };
};

import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { betsAddedByTypeThunk } from "src/thunks";
import {
  createGetBetsByParams,
  createGetIsAnyGame,
  getNextId,
  getTheme,
  getViewId,
} from "src/selectors";
import {
  createGetSums,
  createGetTypes,
  getIsPlaying,
} from "src/widgets/markets/model";
import { sort } from "./utils";
import { Type } from "src/types";
import { useAppDispatch } from "src/hooks";

import styles from "./styles.module.scss";

export const useV3 = () => {
  const dispatch = useAppDispatch();

  const isPlaying = useSelector(getIsPlaying);
  const nextId = useSelector(getNextId);
  const viewId = useSelector(getViewId);
  const theme = useSelector(getTheme);

  const bets = useSelector(
    createGetBetsByParams([viewId], ["sumtop2", "sumtop3"], [])
  );
  const isNextTechBreak = useSelector(createGetIsAnyGame([nextId], ["tech_break"]));
  const sums = useSelector(createGetSums(viewId));
  const types = useSelector(createGetTypes(viewId));

  const getMarketProps = React.useCallback(
    () => ({
      className: cn(styles.market, styles[theme]),
    }),
    [theme]
  );

  const getSumProps = React.useCallback(
    (sum: number) => ({
      className: cn(styles[`sum-${sum}`], {
        [styles.light]: !isPlaying || sums.includes(sum),
      }),
    }),
    [isPlaying, sums]
  );

  const getButtonProps = React.useCallback(
    (type: Type) => ({
      className: cn(styles[type], {
        [styles.light]: !isPlaying || types.includes(type),
      }),
      onClick: () => dispatch(betsAddedByTypeThunk(type)),
      disabled: isNextTechBreak,
    }),
    [dispatch, isNextTechBreak, isPlaying, types]
  );

  const getLimitProps = React.useCallback(
    () => ({
      className: cn(styles.limit, { [styles.filter]: isPlaying }),
    }),
    [isPlaying]
  );

  const { sumtop2, sumtop3 } = sort(bets);

  const values = React.useMemo(
    () => ({
      styles,
      sumtop2,
      sumtop3,
    }),
    [sumtop2, sumtop3]
  );

  return { getButtonProps, getLimitProps, getMarketProps, getSumProps, values };
};

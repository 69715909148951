import React from "react";

import { Props } from "./types";
import { useLimit } from "./hooks";

export const LimitComponent = (props: Props) => {
  const { getLimitsProps, values } = useLimit(props);

  let view;

  switch (values.content) {
    case "desktop":
      view = (
        <div {...getLimitsProps()}>
          <span style={{ color: "#ECEAFF" }}>{values.max}&nbsp;₽</span>
          <span>MAX. СТАВКА</span>
        </div>
      );
      break;
    case "v3":
      view = (
        <div {...getLimitsProps()}>
          <span>MAX&nbsp;</span>
          <span>{values.max}&nbsp;₽</span>
        </div>
      );
      break;
    default:
      view = null;
      break;
  }

  return view;
};

import React from "react";
import cn from "classnames";

import { NEW_CUPS, OLD_CUPS } from "./constants";
import { Props } from "./types";
import { useContent } from "src/hooks";

import styles from "./style.module.scss";

export const useCup = (props: Props) => {
  const content = useContent();

  const src = React.useMemo(
    () => (props.variant === "new" ? NEW_CUPS[props.type] : OLD_CUPS[props.type]),
    [props.type, props.variant]
  );

  const getCupProps = React.useCallback(
    () => ({
      className: cn(styles[content], styles[props.type]),
      src,
      style: props.style,
    }),
    [content, src, props.style, props.type]
  );

  return { getCupProps };
};

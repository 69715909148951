export const PLAYER_SETTINGS = {
  auth: {
    provider: "cupis",
    platform: "cupis",
    merchant: "liverace.tech",
    projectId: "cupis",
    account: "carracing",
  },
  el: "player",
  exchangeUrl: ["https://cdn.cloud.atmosfera.cam/player/v2/config"],
  uiStylesGlobal: `
    .ui-custom > .loader {
      display: block;
    }
    video {
      object-fit: cover;
    }
  `,
  uiTheme: "custom",
};

import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { AudioContext } from "src/contexts";
import {
  createGetBetsByParams,
  getGameId,
  getNextId,
  getTheme,
  getViewId,
} from "src/selectors";
import { Props } from "./types";
import { useAppDispatch } from "src/hooks";
import { v3Actions } from "src/slices";

import styles from "./style.module.scss";

export const useTab = (props: Props) => {
  const dispatch = useAppDispatch();

  const gameId = useSelector(getGameId);
  const nextId = useSelector(getNextId);
  const viewId = useSelector(getViewId);
  const theme = useSelector(getTheme);

  const bets = useSelector(
    createGetBetsByParams([props.game.id], [], ["confirmed", "selected"])
  );

  const { play } = React.useContext(AudioContext);

  const getTabProps = React.useCallback(
    () => ({
      className: cn(styles.tab, styles[theme], {
        [styles.selected]: props.game.id === viewId,
      }),
      onClick: () => {
        play("tab");
        dispatch(v3Actions.viewIdUpdated(props.game.id));
      },
      style: props.style,
    }),
    [dispatch, play, props, theme, viewId]
  );

  const getTotalProps = React.useCallback(
    () => ({
      className: cn(styles.filter, {
        [styles.selected]: props.game.id === viewId || bets.length,
      }),
    }),
    [bets.length, props.game.id, viewId]
  );

  const getPositionsProps = React.useCallback(
    () => ({
      className: cn(styles.filter, {
        [styles.selected]: props.game.id === viewId,
      }),
    }),
    [props.game.id, viewId]
  );

  const getStatusProps = React.useCallback(
    () => ({
      className: cn(styles.filter, {
        [styles.selected]:
          props.game.id === viewId ||
          props.game.id === nextId ||
          (props.game.id === gameId && bets.length),
      }),
    }),
    [bets.length, gameId, nextId, props.game.id, viewId]
  );

  const values = React.useMemo(
    () => ({
      styles,
    }),
    []
  );

  return {
    getPositionsProps,
    getStatusProps,
    getTabProps,
    getTotalProps,
    values,
  };
};

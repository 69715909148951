import React from "react";

import { useSelector } from "react-redux";

import { createGetBetsByParams, getNextId } from "src/selectors";
import { sort } from "./utils";

import styles from "./style.module.scss";

export const useDesktop = () => {
  const nextId = useSelector(getNextId);

  const sumtop2 = useSelector(createGetBetsByParams([nextId], ["sumtop2"], []));
  const sumtop3 = useSelector(createGetBetsByParams([nextId], ["sumtop3"], []));

  const values = React.useMemo(
    () => ({
      styles,
      sums: [sort(sumtop2), sort(sumtop3)],
    }),
    [sumtop2, sumtop3]
  );

  return { values };
};

import React from "react";

import { Player, Positions, Status } from "src/components";
import { useBody } from "./hooks";

import styles from "./style.module.scss";

export const BodyComponent = React.memo(() => {
  const { values } = useBody();
  return (
    <div className={styles[values.content]}>
      <div className={styles.info}>
        {values.game && <Positions game={values.game} />}
        {values.game && <Status game={values.game} />}
      </div>
      <Player />
    </div>
  );
});

BodyComponent.displayName = "BodyComponent";

import every from "lodash/every";
import filter from "lodash/filter";

import { createSelector } from "reselect";

import { getCars, getGameId } from "src/selectors";

export const getIsAllFinished = createSelector(
  [getGameId, getCars],
  (gameId, cars) => {
    const playing = filter(cars, { gameId });
    return every(playing, (car) => car.ms);
  }
);

import { Middleware, MiddlewareAPI, isAnyOf } from "@reduxjs/toolkit";

import { coreActions, createBetsThunk, v3Actions } from "src/slices";
import { getGameId, getNextId, getViewId } from "src/selectors";
import { socketActions } from "src/actions";

export const v3Middleware: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    const response = next(action);

    const isAnyOfActions = isAnyOf(
      coreActions.betsUpdated,
      coreActions.betsUndo,
      createBetsThunk.pending,
      socketActions.messageInit,
      socketActions.messageStart
    );

    if (isAnyOfActions(action)) {
      const state = api.getState();

      const gameId = getGameId(state);
      const nextId = getNextId(state);
      const viewId = getViewId(state);

      const viewIdHandler = () => api.dispatch(v3Actions.viewIdUpdated(nextId));

      switch (action.type) {
        case coreActions.betsUpdated.type:
        case coreActions.betsUndo.type:
        case createBetsThunk.pending.type:
        case socketActions.messageInit.type: {
          viewIdHandler();
          break;
        }
        case socketActions.messageStart.type: {
          const ms = gameId === viewId ? 2000 : 0;
          setTimeout(viewIdHandler, ms);
          break;
        }
      }
    }

    return response;
  };

import React from "react";

import { useWinAnimation } from "./hooks";

import styles from "./style.module.scss";

export const AnimationComponent = React.memo(() => {
  const { values } = useWinAnimation();
  return (
    <div className={styles[values.content]}>
      <div className={styles.road} />
      <div className={styles.cars} />
      <div className={styles.money} />
    </div>
  );
});

AnimationComponent.displayName = "AnimationComponent";

import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { betsAddedByPersonalThunk } from "src/thunks";
import {
  createGetBetsByParams,
  createGetIsAnyGame,
  getNextId,
  getTheme,
  getViewId,
} from "src/selectors";
import {
  createGetPlaces,
  createGetRacers,
  createGetTypes,
  getIsPlaying,
} from "src/widgets/markets/model";
import { sort } from "./utils";
import { Type } from "src/types";
import { useAppDispatch } from "src/hooks";

import styles from "./styles.module.scss";

export const useV3 = () => {
  const dispatch = useAppDispatch();

  const isPlaying = useSelector(getIsPlaying);
  const nextId = useSelector(getNextId);
  const viewId = useSelector(getViewId);
  const theme = useSelector(getTheme);

  const bets = useSelector(createGetBetsByParams([viewId], ["personal"], []));
  const isNextTechBreak = useSelector(createGetIsAnyGame([nextId], ["tech_break"]));
  const places = useSelector(createGetPlaces(viewId));
  const racers = useSelector(createGetRacers(viewId));
  const types = useSelector(createGetTypes(viewId));

  const getMarketProps = React.useCallback(
    () => ({
      className: cn(styles.market, styles[theme]),
    }),
    [theme]
  );

  const getButtonProps = React.useCallback(
    (type: Type, line: number, mode: "places" | "racers") => ({
      className: cn(
        styles[type],
        styles[`${mode === "places" ? "col" : "row"}-${line}`],
        {
          [styles.light]:
            !isPlaying ||
            (types.includes(type) &&
              (mode === "places" ? places.includes(line) : racers.includes(line))),
        }
      ),
      onClick: () => dispatch(betsAddedByPersonalThunk(line, mode)),
      disabled: isNextTechBreak,
    }),
    [dispatch, isNextTechBreak, isPlaying, places, racers, types]
  );

  const getLimitProps = React.useCallback(
    () => ({
      className: cn(styles.limit, { [styles.filter]: isPlaying }),
    }),
    [isPlaying]
  );

  const values = React.useMemo(
    () => ({
      styles,
      places: sort(bets),
    }),
    [bets]
  );

  return { getButtonProps, getLimitProps, getMarketProps, values };
};

import get from "lodash/get";

import { createSlice, EntityId, PayloadAction, Update } from "@reduxjs/toolkit";
import { v4 } from "uuid";

import {
  abortHandler,
  carFinishHandler,
  carProgressHandler,
  endTechBreakHandler,
  finishHandler,
  initHandler,
  raceFinishHandler,
  raceStartHandler,
  startExpectedHandler,
  startHandler,
  techBreakHandler,
  winHandler,
} from "./utils";
import { Bet, Car, Error, Market } from "src/types";
import { betsAdapter, carsAdapter, gamesAdapter } from "./adapters";
import { betsSelectors } from "./selectors";
import { createBetsThunk } from "./thunks";
import { DEFAULT_LIMITS } from "./constants";
import { socketActions } from "src/actions";
import { State, Undo } from "./types";

const STATE: State = {
  bets: betsAdapter.getInitialState({}),
  cars: carsAdapter.getInitialState({}),
  error: "none",
  gameId: 0,
  games: gamesAdapter.getInitialState(),
  kind: "none",
  limit: { bet: null, uuid: "" },
  limits: DEFAULT_LIMITS,
  loading: false,
  market: "result",
  prev: betsAdapter.getInitialState({}),
  step: 1,
  undo: [],
};

export const coreSlice = createSlice({
  name: "core",
  initialState: STATE,
  reducers: {
    betUpdated: (state, action: PayloadAction<Update<Bet>>) => {
      betsAdapter.updateOne(state.bets, action.payload);
    },
    betsUpdated: (state, action: PayloadAction<Array<Update<Bet>>>) => {
      const limits = state.limits;

      const updates: Array<Update<Bet>> = [];

      const undos: Array<Undo> = [];

      action.payload.forEach((update) => {
        const bet = betsSelectors.selectById(state, update.id);
        const limit = get(limits, bet?.type || "");

        if (bet && limit) {
          if (limit.max < (update.changes.amount || 0)) {
            update.changes.amount = limit.max;

            state.limit.bet = { uuid: update.id };
            state.limit.uuid = v4();
          }

          const updated = bet.amount !== update.changes.amount;

          if (limit.min <= (update.changes.amount || 0) && updated) {
            updates.push(update);

            const undo: Undo = {
              amount: bet.amount,
              status: bet.status,
              uuid: bet.uuid,
            };

            undos.push(undo);
          }
        }
      });

      if (undos.length) {
        state.undo.push(undos);
      }

      betsAdapter.updateMany(state.bets, updates);
    },
    betsRemoved: (state, action: PayloadAction<Array<EntityId>>) => {
      betsAdapter.removeMany(state.bets, action.payload);
    },
    betsUndo: (state) => {
      const updates: Array<Update<Bet>> = [];

      const undos = state.undo.pop();

      undos?.forEach((undo) => {
        const update: Update<Bet> = { id: undo.uuid, changes: {} };
        update.changes.amount = undo.amount;
        update.changes.status = undo.status;
        updates.push(update);
      });

      betsAdapter.updateMany(state.bets, updates);
    },
    carUpdated: (state, action: PayloadAction<Update<Car>>) => {
      carsAdapter.updateOne(state.cars, action.payload);
    },
    carsRemoved: (state, action: PayloadAction<Array<EntityId>>) => {
      carsAdapter.removeMany(state.cars, action.payload);
    },
    gamesRemoved: (state, action: PayloadAction<Array<EntityId>>) => {
      gamesAdapter.removeMany(state.games, action.payload);
    },
    limitRemoved: (state) => {
      state.limit.bet = null;
      state.limit.uuid = "";
    },
    marketUpdated: (state, action: PayloadAction<Market>) => {
      state.limit.bet = null;
      state.limit.uuid = "";
      state.market = action.payload;
    },
    errorUpdated: (state, action: PayloadAction<Error>) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(socketActions.messageInit, initHandler)
      .addCase(socketActions.messageStart, startHandler)
      .addCase(socketActions.messageRaceStart, raceStartHandler)
      .addCase(socketActions.messageCarProgress, carProgressHandler)
      .addCase(socketActions.messageCarFinish, carFinishHandler)
      .addCase(socketActions.messageRaceFinish, raceFinishHandler)
      .addCase(socketActions.messageFinish, finishHandler)
      .addCase(socketActions.messageWin, winHandler)
      .addCase(socketActions.messageStartExpected, startExpectedHandler)
      .addCase(socketActions.messageAbort, abortHandler)
      .addCase(socketActions.messageTechBreak, techBreakHandler)
      .addCase(socketActions.messageEndTechBreak, endTechBreakHandler)
      .addCase(createBetsThunk.pending, (state) => {
        const bets = betsSelectors.selectAll(state);

        bets.forEach((bet) => {
          const update: Update<Bet> = { id: bet.uuid, changes: {} };
          switch (bet.status) {
            case "selected": {
              update.changes.status = "pending";
              break;
            }
          }
          betsAdapter.updateOne(state.bets, update);
        });

        state.limit.bet = null;
        state.limit.uuid = "";
        state.loading = true;
      })
      .addCase(createBetsThunk.fulfilled, (state, action) => {
        const bets = action.payload?.bets || [];

        bets.forEach((bet, index) => {
          const update: Update<Bet> = { id: bet.uuid, changes: {} };
          update.changes.id = action.payload.ids[index] || 0;
          update.changes.status = "confirmed";
          betsAdapter.updateOne(state.bets, update);
        });

        state.loading = false;
      })
      .addCase(createBetsThunk.rejected, (state, action) => {
        const playId = state.gameId;

        const nextId = playId + 1;

        const bets = action.payload?.bets || [];

        bets.forEach((bet) => {
          const update: Update<Bet> = { id: bet.uuid, changes: {} };
          update.changes.status = bet.gameId === nextId ? "selected" : "none";
          betsAdapter.updateOne(state.bets, update);
        });

        state.error = action.payload?.error || "none";
        state.loading = false;
      })
      .addCase(socketActions.close, () => STATE);
  },
});

export const coreActions = {
  betUpdated: coreSlice.actions.betUpdated,
  betsUpdated: coreSlice.actions.betsUpdated,
  betsRemoved: coreSlice.actions.betsRemoved,
  betsUndo: coreSlice.actions.betsUndo,
  carUpdated: coreSlice.actions.carUpdated,
  carsRemoved: coreSlice.actions.carsRemoved,
  errorUpdated: coreSlice.actions.errorUpdated,
  gamesRemoved: coreSlice.actions.gamesRemoved,
  limitRemoved: coreSlice.actions.limitRemoved,
  marketUpdated: coreSlice.actions.marketUpdated,
};

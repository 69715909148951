import React from "react";
import cn from "classnames";

import { PopupIcon, Props } from "./types";

import styles from "./style.module.scss";

import NominalsIcon from "src/assets/icons/nominals.svg";
import RepeatIcon from "src/assets/icons/repeat.svg";
import WalletIcon from "src/assets/icons/wallet.svg";

const ICON: Record<PopupIcon, string> = {
  none: "",
  nominals: NominalsIcon,
  repeat: RepeatIcon,
  wallet: WalletIcon,
};

export const PopupError = (props: Props) => (
  <div className={cn(styles.popup, { [styles.show]: props.show })}>
    <div className={styles.icon}>
      <img className={styles.wallet} src={ICON[props.icon]} draggable="false" />
    </div>
    <span>{props.text}</span>
  </div>
);

import React from "react";

import { Cup, Line } from "src/shared/components";
import { Odd } from "src/components";
import { RACERS } from "src/constants";
import { useMobile } from "./hooks";

const CUPS = [
  [
    <Cup key="cup1" type="cup1" variant="new" />,
    <Cup key="cup2" type="cup2" variant="new" />,
    <Cup key="cup3" type="cup3" variant="new" />,
  ],
  [
    <Cup key="cup4" type="cup4" variant="new" />,
    <Cup key="cup5" type="cup5" variant="new" />,
    <Cup key="cup6" type="cup6" variant="new" />,
  ],
  [
    <Cup key="cups2" type="cups2" variant="new" />,
    <Cup key="cups3" type="cups3" variant="new" />,
  ],
];

const LINES = RACERS.map((racer) => <Line key={racer} line={racer} />);

export const MobileComponent = () => {
  const { getBetsProps, values } = useMobile();
  return (
    <div className={values.styles[values.content]}>
      <div className={values.styles.body}>
        {values.results.map((result, index) => (
          <div key={index} className={values.styles.data}>
            <div className={values.styles.cups} />
            <div className={values.styles.cups}>{CUPS[index] || []}</div>
            <div className={values.styles.lines}>{LINES}</div>
            <div {...getBetsProps(index)}>
              {result.map((bet, index) => (
                <Odd key={index} odd={bet} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

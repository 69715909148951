import React from "react";

import { Amounts, Bet, Step } from "src/components";
import { Controls, Light, TutorialButton } from "./components";
import { Speedometer, Version } from "src/components";
import { useV3 } from "./hooks";

export const V3Component = React.memo(() => {
  const { getAmountsProps, getPanelProps, getStepProps } = useV3();
  return (
    <div {...getPanelProps()}>
      <Light />
      <Speedometer />
      <TutorialButton />
      <Controls />
      <Bet />
      <Step {...getStepProps()} />
      <Amounts {...getAmountsProps()} />
      <Version />
    </div>
  );
});

V3Component.displayName = "V3Component";

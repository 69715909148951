import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { AudioContext } from "src/contexts";
import { betsAddedByLineThunk } from "src/thunks";
import {
  createGetBetsByParams,
  createGetIsAnyGame,
  getNextId,
  getTheme,
  getViewId,
} from "src/selectors";
import {
  createGetFirst,
  createGetSecond,
  createGetTypes,
  getIsPlaying,
} from "src/widgets/markets/model";
import { getDataTestId } from "src/utils";
import { metrikaActions } from "src/actions";
import { sort } from "./utils";
import { Type } from "src/types";
import { useAppDispatch } from "src/hooks";

import styles from "./styles.module.scss";

export const useV3 = () => {
  const dispatch = useAppDispatch();

  const isPlaying = useSelector(getIsPlaying);
  const nextId = useSelector(getNextId);
  const viewId = useSelector(getViewId);
  const theme = useSelector(getTheme);

  const bets = useSelector(createGetBetsByParams([viewId], ["competition"], []));
  const first = useSelector(createGetFirst(viewId));
  const isNextTechBreak = useSelector(createGetIsAnyGame([nextId], ["tech_break"]));
  const second = useSelector(createGetSecond(viewId));
  const types = useSelector(createGetTypes(viewId));

  const { play } = React.useContext(AudioContext);

  const getMarketProps = React.useCallback(
    () => ({
      className: cn(styles.market, styles[theme]),
    }),
    [theme]
  );

  const getButtonProps = React.useCallback(
    (type: Type, line: number, invert: boolean) => ({
      className: cn(styles[type], styles[`${invert ? "col" : "row"}-${line}`], {
        [styles.light]:
          !isPlaying ||
          (types.includes(type) &&
            (invert ? second.includes(line) : first.includes(line))),
      }),
      onClick: () => {
        play("odd");
        dispatch(betsAddedByLineThunk(line, invert));
        dispatch(metrikaActions.send({ target: "LineRowClick" }));
      },
      disabled: isNextTechBreak,
      ...getDataTestId(`button-line-${invert ? "col" : "row"}-${line}`),
    }),
    [dispatch, first, isNextTechBreak, isPlaying, play, second, types]
  );

  const getLimitProps = React.useCallback(
    () => ({
      className: cn(styles.limit, { [styles.filter]: isPlaying }),
    }),
    [isPlaying]
  );

  const values = React.useMemo(
    () => ({
      styles,
      competitions: sort(bets),
    }),
    [bets]
  );

  return { getButtonProps, getLimitProps, getMarketProps, values };
};

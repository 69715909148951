import { Middleware } from "@reduxjs/toolkit";

import { Player } from "src/services";
import { playerActions } from "src/actions";

export const playerMiddleware =
  (player: Player): Middleware =>
  () =>
  (next) =>
  (action) => {
    const response = next(action);

    switch (action.type) {
      case playerActions.start.type: {
        player.play();
        break;
      }
      case playerActions.stop.type: {
        player.stop();
        break;
      }
    }

    return response;
  };

import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { coreActions } from "src/slices";
import { getIsLimit, getMax } from "src/components/limit-mobile/model";
import { getLimit } from "src/selectors";
import { useAppDispatch, useContent } from "src/hooks";

import styles from "./style.module.scss";

export const useLimit = () => {
  const content = useContent();

  const dispatch = useAppDispatch();

  const isLimit = useSelector(getIsLimit);
  const max = useSelector(getMax);

  const limit = useSelector(getLimit);

  React.useEffect(() => {
    let limitTimeout: NodeJS.Timeout;

    if (isLimit) {
      limitTimeout = setTimeout(() => dispatch(coreActions.limitRemoved()), 2000);
    }

    return () => {
      if (limitTimeout) {
        clearTimeout(limitTimeout);
      }
    };
  }, [dispatch, isLimit, limit.uuid]);

  const getLimitProps = React.useCallback(
    () => ({
      className: cn(styles[content], {
        [styles.active]: isLimit,
      }),
    }),
    [content, isLimit]
  );

  const values = React.useMemo(
    () => ({
      max,
      styles,
    }),
    [max]
  );

  return { getLimitProps, values };
};

import React from "react";
import cn from "classnames";

import { getDataTestId } from "src/utils";

import styles from "./style.module.scss";

export const useContent = () => {
  const getContentProps = React.useCallback(
    () => ({
      className: cn(styles.content),
      ...getDataTestId("app-ready"),
    }),
    []
  );

  return { getContentProps };
};

import React from "react";

import { Content } from "./components";
import { Status } from "src/components";
import { useBroadcast } from "./hooks";

export const DefaultComponent = React.memo(() => {
  const { values } = useBroadcast();
  return (
    <div className={values.styles.info}>
      {values.game && <Status game={values.game} />}
      <Content />
    </div>
  );
});

DefaultComponent.displayName = "Default";

import React from "react";

import { Cup } from "src/shared/components";
import { Odd } from "src/components";
import { SUMS } from "src/constants";
import { useMobile } from "./hooks";

const CUPS = [
  <Cup key="sum12" type="sum12" variant="new" />,
  <Cup key="sum123" type="sum123" variant="new" />,
];

const LINES = SUMS.map((sum) => <span key={sum}>{sum}</span>);

export const MobileComponent = () => {
  const { values } = useMobile();
  return (
    <div className={values.styles[values.content]}>
      <div className={values.styles.body}>
        <div className={values.styles.data}>
          <div className={values.styles.cups} />
          <div className={values.styles.cups}>{CUPS || []}</div>
          <div className={values.styles.lines}>{LINES}</div>
          <div className={values.styles.bets}>
            {SUMS.map((sum) => (
              <React.Fragment key={sum}>
                <div>{values.sumtop2[sum] && <Odd odd={values.sumtop2[sum]} />}</div>
                <div>{values.sumtop3[sum] && <Odd odd={values.sumtop3[sum]} />}</div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

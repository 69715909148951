import React from "react";

import { useSelector } from "react-redux";

import { createGetBets } from "./selectors";
import { getGameId } from "src/selectors";
import { useContent } from "src/hooks";

export const useBets = () => {
  const content = useContent();

  const gameId = useSelector(getGameId);

  const bets = useSelector(createGetBets(gameId));

  const getBetsProps = React.useCallback(
    (className: string) => ({
      className,
    }),
    []
  );

  const values = React.useMemo(
    () => ({
      bets,
      content,
    }),
    [bets, content]
  );

  return { getBetsProps, values };
};

import React from "react";

import { Props } from "./types";
import { useCoupon } from "./hooks";

import Spinner from "src/assets/icons/spinner.svg";

export const CouponComponent = (props: Props) => {
  const {
    getButtonProps,
    getContainerProps,
    getDataProps,
    getLineProps,
    getLinesProps,
    getRateProps,
    getSpinnerProps,
    getTitleProps,
    getWinProps,
    values,
  } = useCoupon(props);
  return (
    <div {...getContainerProps()}>
      <div {...getDataProps()}>
        <div {...getLinesProps()}>
          {props.bet.racers.map((racer) => (
            <span key={racer} {...getLineProps(racer)}>
              {racer}
            </span>
          ))}
        </div>
        <span {...getTitleProps()}>{values.title}</span>
        <span {...getRateProps()}>{props.bet.rate.toFixed(2)}</span>
        <span {...getWinProps()}>{values.win}</span>
      </div>
      <button {...getButtonProps()}>
        <img {...getSpinnerProps()} src={Spinner} height="24" width="24" />
        {values.status}
      </button>
    </div>
  );
};

import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { getLive } from "src/selectors";
import { useContent } from "src/hooks";

import styles from "./style.module.scss";

import ArrowUp from "src/widgets/info/assets/images/mobile/arrow-up.svg";

export const useArrow = () => {
  const content = useContent();

  const live = useSelector(getLive);

  const getArrowProps = React.useCallback(
    () => ({
      className: cn(styles[content], {
        [styles.rotate]: live,
      }),
      src: ArrowUp,
    }),
    [content, live]
  );

  return { getArrowProps };
};

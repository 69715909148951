import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { socketActions } from "src/actions";
import { State } from "./types";

const STATE: State = {
  tutorial: false,
};

export const tutorialSlice = createSlice({
  name: "tutorial",
  initialState: STATE,
  reducers: {
    tutorialUpdated: (state, action: PayloadAction<boolean>) => {
      state.tutorial = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(socketActions.close, () => STATE);
  },
});

export const tutorialActions = {
  tutorialUpdated: tutorialSlice.actions.tutorialUpdated,
};

import React from "react";
import cn from "classnames";

import { useContent } from "src/hooks";

import styles from "./style.module.scss";

export const useTechBreak = () => {
  const content = useContent();

  const getTechBreakProps = React.useCallback(
    () => ({
      className: cn(styles[content]),
    }),
    [content]
  );

  return { getTechBreakProps };
};

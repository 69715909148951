import React from "react";

import { Control } from "src/components";

import styles from "./style.module.scss";

export const ControlsComponent = React.memo(() => {
  return (
    <div className={styles.controls}>
      <Control type="double" />
      <Control type="undo" />
      <Control type="repeat" />
    </div>
  );
});

ControlsComponent.displayName = "ControlsComponent";

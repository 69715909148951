import { createSelector } from "reselect";

import { RootState } from "src/types";

/**
 * Селекторы хранилища номинала
 */
const getRoot = (state: RootState) => state.amount;

/**
 * Селектор номинала
 */
export const getAmount = createSelector([getRoot], (root) => root.amount);

import React from "react";

import { Props } from "./types";
import { useAmounts } from "./hooks";

export const AmountsComponent = (props: Props) => {
  const { getAmountsProps, getButtonProps, values } = useAmounts(props);
  return (
    <div {...getAmountsProps()}>
      {values.amounts.map((amount, index) => (
        <button
          key={index}
          {...getButtonProps({
            value: amount,
          })}
        >
          <span>{amount}</span>
        </button>
      ))}
    </div>
  );
};

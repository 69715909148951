import React from "react";

import { Amounts, Bet } from "src/components";
import { Controls } from "./components";
import { Speedometer, Version } from "src/components";
import { useDefault } from "./hooks";

export const DefaultComponent = React.memo(() => {
  const { getAmountsProps, values } = useDefault();
  return (
    <div className={values.styles.panel}>
      <div className={values.styles.board}>
        <Amounts {...getAmountsProps()} />
        <Controls />
      </div>
      <Bet />
      <Speedometer />
      <Version />
    </div>
  );
});

DefaultComponent.displayName = "DefaultComponent";

import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";
import { useSpring } from "@react-spring/web";
import { useSwipeable } from "react-swipeable";

import { coreActions } from "src/slices";
import { getMarket } from "src/selectors";
import { Props } from "./types";
import { useAppDispatch, useContent, useWindowSize } from "src/hooks";

import styles from "./style.module.scss";

export const useMarketsSwipe = (props: Props) => {
  const content = useContent();

  const { handlers, refPassthrough, x } = useSwipe(props);

  const getSwipeProps = React.useCallback(
    () => ({
      ...handlers,
      className: cn(styles[content]),
      ref: refPassthrough,
      style: { x },
    }),
    [content, handlers, refPassthrough, x]
  );

  return { getSwipeProps };
};

export const useSwipe = (props: Props) => {
  const dispatch = useAppDispatch();

  const market = useSelector(getMarket);

  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const [, width] = useWindowSize();

  const index = props.markets.indexOf(market);

  const position = React.useMemo(() => index * width * -1, [index, width]);
  const treshold = React.useMemo(() => width * 0.16, [width]);

  const [{ x }, api] = useSpring(() => ({ x: position }), [market, position]);

  const handlers = useSwipeable({
    onSwiped: ({ absX, deltaX }) => {
      api.start({ x: position + deltaX, immediate: true });

      if (deltaX > 0 && absX > treshold && index > 0) {
        dispatch(coreActions.marketUpdated(props.markets[index - 1]));
      } else if (deltaX < 0 && absX > treshold && index < props.markets.length - 1) {
        dispatch(coreActions.marketUpdated(props.markets[index + 1]));
      } else {
        api.start({ x: position, immediate: true });
      }
    },
    onSwiping: ({ deltaX, dir }) => {
      const isBorder = Math.abs(deltaX) > width * 0.2;
      const isLeft = props.markets[0] === market && dir === "Right";
      const isRight = props.markets[2] === market && dir === "Left";
      if (isBorder && (isLeft || isRight)) {
        return;
      }
      if (dir === "Left" || dir === "Right") {
        api.start({ x: position + deltaX, immediate: true });
      }
    },
    trackMouse: true,
  });

  const refPassthrough = (el: HTMLDivElement) => {
    handlers.ref(el);
    containerRef.current = el;
  };

  return { handlers, refPassthrough, x };
};

const uri = `${window.location.protocol === "https:" ? "https://" : "http://"}${
  window.location.host
}/api`;

export const createBets = async (params: unknown) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const body: Record<string, unknown> = {
    jsonrpc: "2.0",
    method: "bet.create",
    id: (~~(Math.random() * 1e8)).toString(16),
  };

  params && (body["params"] = params);

  const options: RequestInit = {
    method: "POST",
    credentials: "same-origin",
    headers,
    body: JSON.stringify(body),
  };

  return await fetch(uri, options);
};

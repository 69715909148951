import { Middleware } from "@reduxjs/toolkit";

import { Logger } from "src/services";
import { loggerActions, socketActions } from "src/actions";

export const loggerMiddleware =
  (logger: Logger): Middleware =>
  () =>
  (next) =>
  (action) => {
    const response = next(action);

    switch (action.type) {
      case loggerActions.log.type: {
        logger.log(action.payload);
        break;
      }
      case socketActions.open.type: {
        logger.log("CONNECTED");
        break;
      }
      case socketActions.close.type: {
        logger.log("DISCONNECTED");
        break;
      }
      case socketActions.message.type: {
        logger.log(`MESSAGE ${action.payload.kind}`, action.payload);
        break;
      }
    }

    return response;
  };

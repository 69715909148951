import { Settings } from "src/types";

export const DEFAULT_LIMITS: Settings["betLimits"] = {
  competition: {
    max: 0,
    min: 0,
  },
  personal: {
    max: 0,
    min: 0,
  },
  dynamic: {
    max: 0,
    min: 0,
    step: 0,
  },
  sumtop2: {
    max: 0,
    min: 0,
  },
  sumtop3: {
    max: 0,
    min: 0,
  },
  top1: {
    max: 0,
    min: 0,
  },
  top2: {
    max: 0,
    min: 0,
  },
  top3: {
    max: 0,
    min: 0,
  },
  even: {
    max: 0,
    min: 0,
  },
  odd: {
    max: 0,
    min: 0,
  },
  bottom: {
    max: 0,
    min: 0,
  },
  upper: {
    max: 0,
    min: 0,
  },
  winLimit: 0,
};

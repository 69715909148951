import React from "react";

import { animated } from "@react-spring/web";

import { Player, Statistics } from "src/components";
import { useContent } from "./hooks";

export const ContentComponent = React.memo(() => {
  const { getContentProps, getPlayerProps, getStatisticsProps, values } =
    useContent();
  return (
    <div {...getContentProps()}>
      <animated.div {...getPlayerProps()}>
        {values.isMonitor && <Player />}
      </animated.div>
      <animated.div {...getStatisticsProps()}>
        <Statistics />
      </animated.div>
    </div>
  );
});

ContentComponent.displayName = "ContentComponent";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { socketActions } from "src/actions";
import { State } from "./types";

const STATE: State = {
  amount: 0,
};

export const amountSlice = createSlice({
  name: "amount",
  initialState: STATE,
  reducers: {
    amountUpdated: (state, action: PayloadAction<number>) => {
      state.amount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(socketActions.messageInit, (state, action) => {
        const betSums = action.payload.payload.settings.betSums;

        state.amount = betSums[0] || 0;
      })
      .addCase(socketActions.close, () => STATE);
  },
});

export const amountActions = {
  amountUpdated: amountSlice.actions.amountUpdated,
};

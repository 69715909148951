import React from "react";

import { animated } from "@react-spring/web";

import { Coupon, Hint } from "./components";
import { Props } from "./types";
import { useList } from "./hooks";

export const ListComponent = (props: Props) => {
  const { scrollRef, values } = useList(props);
  return (
    <div className={values.styles[values.content]} ref={scrollRef}>
      <Hint gameId={props.gameId} />
      {values.coupons((style, coupon) => (
        <animated.div className={values.styles.wrapper} style={style}>
          <Coupon bet={coupon} />
        </animated.div>
      ))}
    </div>
  );
};

import { createSelector } from "reselect";

import { createGetBetsByParams, getGameId, getViewId } from "src/selectors";

export const getIsPlaying = createSelector(
  [getGameId, getViewId],
  (gameId, viewId) => gameId === viewId
);

export const createGetBets = (gameId: number) =>
  createSelector(
    [createGetBetsByParams([gameId], ["bottom", "odd", "upper", "even"], [])],
    (bets) => bets
  );

export const createGetCars = (gameId: number) =>
  createSelector([createGetBets(gameId)], (bets) =>
    bets.reduce((res, cur) => {
      if (cur.status !== "none") {
        res.push(...cur.racers);
      }
      return res;
    }, [] as Array<number>)
  );

export const createGetConfirmed = (gameId: number) =>
  createSelector(
    [createGetBetsByParams([gameId], [], ["confirmed", "finish"])],
    (bets) => bets
  );

export const createGetTypes = (gameId: number) =>
  createSelector([createGetConfirmed(gameId)], (bets) =>
    bets.map((bet) => bet.type)
  );

export const createGetSumTops = (gameId: number) =>
  createSelector([createGetConfirmed(gameId)], (bets) =>
    bets.filter((bet) => bet.type === "sumtop2" || bet.type === "sumtop3")
  );

export const createGetSums = (gameId: number) =>
  createSelector([createGetSumTops(gameId)], (bets) =>
    bets.map((bet) => bet.racers[0])
  );

export const createGetCompetition = (gameId: number) =>
  createSelector([createGetConfirmed(gameId)], (bets) =>
    bets.filter((bet) => bet.type === "competition")
  );

export const createGetFirst = (gameId: number) =>
  createSelector([createGetCompetition(gameId)], (bets) =>
    bets.map((bet) => bet.racers[0])
  );

export const createGetSecond = (gameId: number) =>
  createSelector([createGetCompetition(gameId)], (bets) =>
    bets.map((bet) => bet.racers[1])
  );

export const createGetPersonal = (gameId: number) =>
  createSelector([createGetConfirmed(gameId)], (bets) =>
    bets.filter((bet) => bet.type === "personal")
  );

export const createGetRacers = (gameId: number) =>
  createSelector([createGetPersonal(gameId)], (bets) =>
    bets.map((bet) => bet.racers[0])
  );

export const createGetPlaces = (gameId: number) =>
  createSelector([createGetPersonal(gameId)], (bets) =>
    bets.map((bet) => bet.places[0])
  );

import React from "react";

import { animated } from "@react-spring/web";

import { Progress, Result } from "./components";
import { Props } from "./types";
import { useCar } from "./hooks";

export const CarComponent = (props: Props) => {
  const { getCarProps } = useCar(props);
  return (
    <animated.div {...getCarProps()}>
      <Result car={props.car} />
      <Progress car={props.car} />
    </animated.div>
  );
};

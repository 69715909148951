import React from "react";

import { Props } from "./types";
import { useStart } from "./hooks";

export const BetComponent = (props: Props) => {
  const { getCountProps, getButtonProps, getIconProps, values } = useStart(props);

  let content;

  switch (values.status) {
    case "basic":
    case "loading": {
      content = (
        <>
          {values.status === "loading" && <img {...getIconProps()} />}
          <div className={values.styles.info}>
            <span className={values.styles.count}>ПОДТВЕРДИТЬ</span>
            <span {...getCountProps("bets-count")}>{values.count}</span>
            <span {...getCountProps("bets-label")}>{values.label}</span>
          </div>
        </>
      );
      break;
    }
    case "disabled": {
      content = (
        <div className={values.styles.info}>
          <span className={values.styles.count}>{values.single}</span>
        </div>
      );
      break;
    }
    case "error": {
      content = <span className={values.styles.message}>{values.message}</span>;
      break;
    }
    default:
      break;
  }

  return <button {...getButtonProps()}>{content}</button>;
};

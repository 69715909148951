import { createSelector } from "reselect";

import { Bet } from "src/types";
import { getLocale } from "src/utils";
import { getGameId, getNextId, getSettings, getView } from "src/selectors";

/**
 * Генератор селектора получения суммы ставки
 * @param bet - ставка
 * @returns - сумма
 */
export const createGetBetSum = (bet: Bet) =>
  createSelector([getSettings], (settings) => {
    const limit = settings.betLimits.winLimit;
    const sum = Math.round(bet.amount * bet.rate);
    return sum > limit ? limit : sum;
  });

export const createGetBetWin = (bet: Bet) =>
  createSelector([createGetBetSum(bet)], (sum) => {
    const strA = getLocale(bet.amount, bet.balanceId);
    const strB = getLocale(sum, 1);

    return `${strA} → ${strB}`;
  });

export const getGameIdByView = createSelector(
  [getGameId, getNextId, getView],
  (gameId, nextId, view) => (view === "curr" ? gameId : nextId)
);

import React from "react";

import { Gears } from "./components";
import { Props } from "./types";
import { useHint } from "./hooks";

export const HintComponent = (props: Props) => {
  const { getButtonProps, getHintProps, values } = useHint(props);

  let view = null;

  if (!values.game) {
    return view;
  }

  switch (values.game.status) {
    case "expected":
    case "pending":
      view = (
        <div {...getHintProps()}>
          <span className={values.styles.title}>СТАВКИ НА СЛЕДУЮЩУЮ ГОНКУ</span>
          <button {...getButtonProps()} />
        </div>
      );
      break;
    case "started":
    case "play":
    case "finished":
      view = (
        <div {...getHintProps()}>
          <span className={values.styles.title}>АКТИВНЫЕ СТАВКИ БУДУТ ЗДЕСЬ</span>
          <button {...getButtonProps()} />
        </div>
      );
      break;
    case "tech_break":
      view = (
        <div {...getHintProps()}>
          <Gears />
          <span className={values.styles.title}>ДАЛЕЕ ТЕХНИЧЕСКИЙ ПЕРЕРЫВ</span>
          <span className={values.styles.description}>
            Мы возобновим прием ставок после его завершения
          </span>
        </div>
      );
      break;
    default:
      break;
  }

  return view;
};

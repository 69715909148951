import React from "react";

import { Limit } from "src/components";
import { Line } from "src/shared/components";
import { Odd } from "src/components";
import { RACERS } from "src/constants";
import { useDesktop } from "./hooks";

const LINES = (
  propsGetter: (
    racer: number,
    invert: boolean
  ) => React.ButtonHTMLAttributes<HTMLButtonElement>,
  invert: boolean
) =>
  RACERS.map((racer) => (
    <button key={racer} {...propsGetter(racer, invert)}>
      <Line line={racer} />
    </button>
  ));

export const DesktopComponent = () => {
  const { getButtonProps, values } = useDesktop();
  return (
    <>
      <div className={values.styles.market}>
        <div className={values.styles.body}>
          <div className={values.styles.cups} />
          <div className={values.styles.lines}>{LINES(getButtonProps, true)}</div>
          <div className={values.styles.lines}>{LINES(getButtonProps, false)}</div>
          <div className={values.styles.bets}>
            {RACERS.map((second) =>
              RACERS.map((first) => (
                <div key={first}>
                  {values.competitions[second] &&
                    values.competitions[second][first] && (
                      <Odd odd={values.competitions[second][first]} />
                    )}
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      <Limit market="competition" types={["competition"]} />
    </>
  );
};

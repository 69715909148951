import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { getGame, getLive } from "src/selectors";
import { useContent } from "src/hooks";

import styles from "./style.module.scss";

export const useMobile = () => {
  const content = useContent();

  const game = useSelector(getGame);
  const live = useSelector(getLive);

  const getInfoProps = React.useCallback(
    () => ({
      className: cn(styles[content], {
        [styles.open]: live,
      }),
    }),
    [content, live]
  );

  const values = React.useMemo(
    () => ({
      game,
      styles,
    }),
    [game]
  );

  return { getInfoProps, values };
};

import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { AudioContext } from "src/contexts";
import { betsAddedThunk } from "src/thunks";
import { createGetIsAnyGame, getGameId, getNextId, getTheme } from "src/selectors";
import {
  createGetMaxMinRate,
  createGetQuotation,
  getPosition,
  getTypeId,
  getTypes,
} from "src/components/odd/model";
import { getDataTestId, getLocale } from "src/utils";
import { metrikaActions } from "src/actions";
import { Props } from "./types";
import { Quotation } from "src/types";
import { useAppDispatch, useContent } from "src/hooks";

import styles from "./style.module.scss";

export const useOdd = (props: Props) => {
  const content = useContent();

  const dispatch = useAppDispatch();

  const nextId = useSelector(getNextId);
  const gameId = useSelector(getGameId);
  const theme = useSelector(getTheme);

  const amount = getLocale(props.odd.amount, props.odd.balanceId);
  const position = getPosition(props.odd, content);
  const typeId = getTypeId(props.odd);
  const types = getTypes(props.odd, content);
  const win = getLocale(props.odd.win, 1);

  const [max, min] = useSelector(createGetMaxMinRate(props.odd, types));
  const quotation = useSelector(createGetQuotation(props.odd));
  const isNextTechBreak = useSelector(createGetIsAnyGame([nextId], ["tech_break"]));

  const { play } = React.useContext(AudioContext);

  const isMax = max === min ? false : props.odd.rate === max;
  const isMin = max === min ? false : props.odd.rate === min;

  const isNext = props.odd.gameId === nextId;
  const isPlaying = props.odd.gameId === gameId;
  const isLoading = props.odd.status === "pending";
  const isConfirmed = props.odd.status === "confirmed";
  const isSelected = props.odd.status === "selected";
  const isWin = props.odd.status === "finish" && props.odd.win > 0;
  const isLoss = props.odd.status === "finish" && props.odd.win === 0;

  const isDisabled =
    isLoading || isConfirmed || isNextTechBreak || isPlaying || isWin || isLoss;

  const isUp = quotation === Quotation.Up;
  const isDown = quotation === Quotation.Down;

  const onClick = React.useCallback(() => {
    play("odd");
    dispatch(betsAddedThunk([props.odd]));
    dispatch(metrikaActions.send({ target: "OddClick" }));
  }, [dispatch, play, props.odd]);

  const getButtonProps = React.useCallback(
    () => ({
      className: cn(styles[content], styles[theme], styles[position], {
        [styles.max]: isMax && isNext,
        [styles.min]: isMin && isNext,
        [styles.selected]: isSelected,
        [styles.disabled]: isDisabled,
        [styles.loading]: isLoading,
        [styles.confirmed]: isConfirmed,
        [styles.win]: isWin,
        [styles.loss]: isLoss,
      }),
      ["data-selected"]: props.odd.status !== "none",
      disabled: isDisabled,
      onClick,
      ...getDataTestId(`button-rate_${typeId}`),
    }),
    [
      content,
      isConfirmed,
      isDisabled,
      isLoading,
      isLoss,
      isMax,
      isMin,
      isNext,
      isSelected,
      isWin,
      onClick,
      position,
      props.odd,
      theme,
      typeId,
    ]
  );

  const getCornerProps = React.useCallback(
    () => ({
      className: cn(styles.corner, {
        [styles.hidden]: isPlaying || isNextTechBreak,
        [styles.round]: position === "right-top" || position === "top",
        [styles.max]: isMax,
        [styles.min]: isMin,
      }),
    }),
    [isMax, isMin, isNextTechBreak, isPlaying, position]
  );

  const getRateProps = React.useCallback(
    () => ({
      className: cn(styles.rate),
      ...getDataTestId(`bet-rate_${typeId}`),
    }),
    [typeId]
  );

  const getSumProps = React.useCallback(
    () => ({
      className: cn(styles.sum, {
        [styles.hidden]: props.odd.status === "none",
      }),
      ...getDataTestId(`bet-sum_${typeId}`),
    }),
    [props.odd.status, typeId]
  );

  const getPrevProps = React.useCallback(
    () => ({
      className: cn(styles.quotation, {
        [styles.hidden]:
          quotation === Quotation.None || isPlaying || isNextTechBreak,
        [styles.up]: isUp,
        [styles.down]: isDown,
      }),
    }),
    [isDown, isNextTechBreak, isPlaying, isUp, quotation]
  );

  const values = React.useMemo(
    () => ({
      amount,
      isWin,
      styles,
      win,
    }),
    [amount, isWin, win]
  );

  return {
    getButtonProps,
    getCornerProps,
    getPrevProps,
    getRateProps,
    getSumProps,
    values,
  };
};

import { Middleware } from "@reduxjs/toolkit";

import { Metrika } from "src/services";
import { metrikaActions } from "src/actions";

export const metrikaMiddleware =
  (metrika: Metrika): Middleware =>
  () =>
  (next) =>
  (action) => {
    const response = next(action);

    switch (action.type) {
      case metrikaActions.send.type: {
        metrika.send(action.payload);
        break;
      }
    }

    return response;
  };

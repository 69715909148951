import find from "lodash/find";
import get from "lodash/get";
import last from "lodash/last";

import { Update } from "@reduxjs/toolkit";

import { AppDispatch, Bet, RootState, Type } from "./types";
import { coreActions, getHashCode } from "./slices";
import { createGetBetsByParams, getAmount, getNextId, getPrev } from "./selectors";
import { getBalanceId } from "./utils";

export const betsAddedByTypeThunk =
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (type: Type) => () => {
    /* DISABLED TEMPORARLY  */
    /*
    const state = getState();

    const nextId = getNextId(state);

    const bets = createGetBetsByParams([nextId], [type], [])(state);

    dispatch(betsAddedThunk(bets));
    */
  };

export const betsAddedByPersonalThunk =
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (line: number, type: "places" | "racers") => () => {
    /* DISABLED TEMPORARLY  */
    /*
    const state = getState();

    const nextId = getNextId(state);

    const bets = createGetBetsByParams([nextId], ["personal"], [])(state);

    const result: Array<Bet> = [];

    for (let i = 6; i > 0; i--) {
      const bet = bets.find((bet) =>
        type === "places"
          ? bet.racers[0] === i && bet.places[0] === line
          : bet.racers[0] === line && bet.places[0] === i
      );
      if (bet) {
        result.push(bet);
      }
    }

    dispatch(betsAddedThunk(result));
    */
  };

export const betsAddedByLineThunk =
  (line: number, invert: boolean) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();

    const nextId = getNextId(state);

    const bets = createGetBetsByParams([nextId], ["competition"], [])(state);

    const result: Array<Bet> = [];

    for (let i = 6; i > 0; i--) {
      const bet = bets.find((bet) =>
        invert
          ? bet.racers[0] === i && bet.racers[1] === line
          : bet.racers[0] === line && bet.racers[1] === i
      );
      if (bet) {
        result.push(bet);
      }
    }

    dispatch(betsAddedThunk(result));
  };

export const betsAddedThunk =
  (bets: Array<Bet>) => (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();

    const amount = getAmount(state);

    const balanceId = getBalanceId();
    const now = Date.now();

    const updates: Array<Update<Bet>> = [];

    bets.forEach((bet) => {
      const update: Update<Bet> = { id: bet.uuid, changes: {} };
      switch (bet.status) {
        case "none": {
          update.changes.added = now;
          update.changes.amount = amount;
          update.changes.balanceId = balanceId;
          update.changes.status = "selected";
          update.changes.updated = now;
          break;
        }
        case "selected": {
          if (bet.balanceId === balanceId) {
            update.changes.amount = amount + bet.amount;
          }
          break;
        }
      }
      updates.push(update);
    });

    dispatch(coreActions.betsUpdated(updates));
  };

export const betsAddedX2Thunk =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();

    const nextId = getNextId(state);

    const bets = createGetBetsByParams([nextId], [], ["selected"])(state);

    const updates: Array<Update<Bet>> = [];

    bets.forEach((bet) => {
      const update: Update<Bet> = { id: bet.uuid, changes: {} };
      update.changes.amount = bet.amount * 2;
      updates.push(update);
    });

    dispatch(coreActions.betsUpdated(updates));
  };

export const betsRepeatedThunk =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();

    const nextId = getNextId(state);
    const prev = getPrev(state);

    const bets = createGetBetsByParams([nextId], [], ["none"])(state);

    const balanceId = getBalanceId();
    const now = Date.now();

    const updates: Array<Update<Bet>> = [];

    bets.forEach((bet) => {
      const gameId = get(last(prev), "gameId", 0);

      const hashCode = getHashCode(gameId, bet.type, bet.places, bet.racers);

      const repeat = find(prev, { hashCode });

      if (repeat) {
        const update: Update<Bet> = { id: bet.uuid, changes: {} };
        update.changes.added = now;
        update.changes.amount = repeat.amount;
        update.changes.balanceId = balanceId;
        update.changes.status = "selected";
        update.changes.updated = now;
        updates.push(update);
      }
    });

    dispatch(coreActions.betsUpdated(updates));
  };

export const betRemovedThunk = (bet: Bet) => (dispatch: AppDispatch) => {
  const update: Update<Bet> = { id: bet.uuid, changes: {} };

  update.changes.added = 0;
  update.changes.amount = 0;
  update.changes.limit = false;
  update.changes.status = "none";

  dispatch(coreActions.betUpdated(update));
};

export const betsUndoThunk = () => (dispatch: AppDispatch) => {
  dispatch(coreActions.betsUndo());
};

import React from "react";
import MuiModal from "@mui/material/Modal";

import { useAbort } from "./hooks";

import styles from "./style.module.scss";

const TEXTS = [
  "НЕПОЛАДКИ НА ТРАССЕ",
  "Ставки будут возвращены. Новая гонка скоро начнется.",
];

export const AbortComponent = React.memo(() => {
  const { getModalProps, values } = useAbort();
  return (
    <MuiModal {...getModalProps()}>
      <div className={styles[values.content]}>
        <div className={styles.content}>
          <span className={styles.title}>{TEXTS[0]}</span>
          <span className={styles.description}>{TEXTS[1]}</span>
        </div>
      </div>
    </MuiModal>
  );
});

AbortComponent.displayName = "AbortComponent";

import React from "react";

import { BaseProps } from "src/types";
import { useSvg } from "./hooks";

export const UndoComponent = (props: BaseProps) => {
  const { getSvgProps } = useSvg(props);
  return (
    <svg {...getSvgProps()}>
      <g filter="url(#filter0_i_1037_1187)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.75834 27.7157H12.7583C15.9616 27.7157 18.8694 25.1377 19.2532 21.9575L20.2714 13.5187C20.6552 10.3385 18.3695 7.76047 15.1662 7.76047H7.51182L12.4694 3.36514L10.2288 0.837891L0.405023 9.54751L8.12687 18.2571L10.9774 15.7299L7.08056 11.3345H14.735C15.95 11.3345 16.817 12.3124 16.6714 13.5187L15.6532 21.9575C15.5076 23.1638 14.4046 24.1416 13.1896 24.1416H3.1896L2.75834 27.7157Z"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_1037_1187"
          x="0.405022"
          y="0.837891"
          width="19.9084"
          height="27.5688"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.690909" />
          <feGaussianBlur stdDeviation="0.345455" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.105882 0 0 0 0 0.133333 0 0 0 0 0.223529 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_1037_1187"
          />
        </filter>
      </defs>
    </svg>
  );
};

import React from "react";

import { animated } from "@react-spring/web";

import { useHeader } from "./hooks";
import { View } from "src/types";

const VIEWS: Array<View> = ["curr", "next"];

const TEXTS: Array<string> = ["В ИГРЕ", "СЛЕДУЮЩАЯ ИГРА"];

export const HeaderComponent = () => {
  const { getButtonProps, getGameProps, getHeaderProps, values } = useHeader();

  let view;

  switch (values.content) {
    case "desktop":
      view = (
        <div className={values.styles[values.content]}>
          <div className={values.styles.games}>
            {VIEWS.map((view, index) => (
              <div key={view} {...getGameProps(view)}>
                <span>{TEXTS[index]}</span>
              </div>
            ))}
          </div>
          {values.queue((style, game) => (
            <animated.button
              {...getButtonProps(game.id === values.gameId ? "curr" : "next")}
              style={style}
            ></animated.button>
          ))}
        </div>
      );
      break;
    case "mobile":
    case "laptop":
      view = (
        <div {...getHeaderProps()}>
          <div className={values.styles.games}>
            {VIEWS.map((view, index) => (
              <div key={view} {...getGameProps(view)}>
                <span>{TEXTS[index]}</span>
                <span>{values.counts[index]}</span>
              </div>
            ))}
          </div>
          {values.queue((style, game) => (
            <animated.button
              {...getButtonProps(game.id === values.gameId ? "curr" : "next")}
              style={style}
            ></animated.button>
          ))}
        </div>
      );
      break;
    default:
      view = null;
      break;
  }

  return view;
};

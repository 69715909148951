import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { State, Update } from "./types";
import { socketActions } from "src/actions";

const STATE: State = {
  modal: {
    abort: false,
    live: false,
    offline: false,
    tech_break: false,
    win: false,
  },
};

export const modalSlice = createSlice({
  name: "modal",
  initialState: STATE,
  reducers: {
    modalUpdated: (state, action: PayloadAction<Update>) => {
      state.modal[action.payload.modal] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(socketActions.open, (state) => {
        state.modal = { ...STATE.modal, offline: false };
      })
      .addCase(socketActions.close, (state) => {
        state.modal = { ...STATE.modal, offline: true };
      })
      .addCase(socketActions.messageInit, (state, action) => {
        const status = action.payload.payload.status;

        switch (status) {
          case "abort":
            state.modal.abort = true;
            break;
          case "tech_break":
            state.modal.tech_break = true;
            break;
        }
      })
      .addCase(socketActions.messageAbort, (state) => {
        state.modal.abort = true;
      })
      .addCase(socketActions.messageTechBreak, (state) => {
        state.modal.tech_break = true;
      })
      .addCase(socketActions.messageEndTechBreak, (state) => {
        state.modal.tech_break = false;
      })
      .addCase(socketActions.messageWin, (state) => {
        state.modal.win = true;
      });
  },
});

export const modalActions = {
  modalUpdated: modalSlice.actions.modalUpdated,
};

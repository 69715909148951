import React from "react";

import { useButton } from "./hooks";

export const TutorialButtonComponent = React.memo(() => {
  const { getButtonProps } = useButton();
  return (
    <button {...getButtonProps()}>
      <span>?</span>
    </button>
  );
});

TutorialButtonComponent.displayName = "TutorialButtonComponent";

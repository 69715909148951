import { RACERS } from "src/constants";
import { Bet, Content, Type } from "src/types";

export const getTypeId = (bet: Bet): string => {
  switch (bet.type) {
    case "competition":
      return `${bet.type}_${bet.racers[0]}_${bet.racers[1]}`;
    case "personal":
      return `${bet.type}_${bet.racers[0]}_${bet.places[0]}`;
    case "sumtop2":
    case "sumtop3":
    case "top1":
    case "top2":
    case "top3":
      return `${bet.type}_${bet.racers[0]}`;
    case "bottom":
    case "even":
    case "odd":
    case "upper":
      return `${bet.type}_${bet.racers[0]}_${bet.racers[1]}_${bet.racers[2]}`;
    default:
      return "";
  }
};

export const getTypes = (bet: Bet, content: Content): Array<Type> => {
  switch (content) {
    case "desktop":
    case "mobile":
    case "laptop": {
      switch (bet.type) {
        case "sumtop2":
          return ["sumtop2"];
        case "sumtop3":
          return ["sumtop3"];
        case "personal":
        case "top2":
        case "top3":
          return ["personal", "top2", "top3"]; // TODO: MAX BY MARKET
        case "bottom":
        case "even":
        case "odd":
        case "upper":
          return ["bottom", "even", "odd", "upper"];
        default:
          return [bet.type];
      }
    }
    case "v3": {
      switch (bet.type) {
        case "sumtop2":
          return ["sumtop2"];
        case "sumtop3":
          return ["sumtop3"];
        case "personal":
          return ["personal"];
        case "top2":
        case "top3":
          return ["top2", "top3"]; // TODO: MAX BY MARKET
        case "bottom":
        case "even":
        case "odd":
        case "upper":
          return ["bottom", "even", "odd", "upper"];
        default:
          return [bet.type];
      }
    }
    default:
      return [bet.type];
  }
};

export const getPosition = (bet: Bet, content: Content): string => {
  if (content === "desktop") {
    return "center-center";
  }

  if (content === "mobile" || content === "laptop") {
    if (bet.places[0] === 1 && bet.racers[0] === 1 && bet.type === "personal") {
      return "left-top";
    }

    if (bet.places[0] === 4 && bet.racers[0] === 1 && bet.type === "personal") {
      return "left-top";
    }

    if (bet.places[0] === 3 && bet.racers[0] === 1 && bet.type === "personal") {
      return "right-top";
    }

    if (bet.places[0] === 6 && bet.racers[0] === 1 && bet.type === "personal") {
      return "right-top";
    }

    if (bet.places[0] === 4 && bet.racers[0] === 6 && bet.type === "personal") {
      return "left-bottom";
    }

    if (bet.places[0] === 1 && bet.racers[0] === 6 && bet.type === "personal") {
      return "left-bottom";
    }

    if (bet.places[0] === 3 && bet.racers[0] === 6 && bet.type === "personal") {
      return "right-bottom";
    }

    if (bet.places[0] === 6 && bet.racers[0] === 6 && bet.type === "personal") {
      return "right-bottom";
    }

    if (bet.racers[0] === 1 && bet.type === "top2") {
      return "left-top";
    }

    if (bet.racers[0] === 1 && bet.type === "top3") {
      return "right-top";
    }

    if (bet.racers[0] === 6 && bet.type === "top2") {
      return "left-bottom";
    }

    if (bet.racers[0] === 6 && bet.type === "top3") {
      return "right-bottom";
    }

    if (bet.type === "sumtop2" && bet.racers[0] === 3) {
      return "left-top";
    }

    if (bet.type === "sumtop3" && bet.racers[0] === 15) {
      return "right-bottom";
    }

    if (bet.type === "competition") {
      if (
        (bet.racers[0] === 1 && bet.racers[1] === 2) ||
        (bet.racers[1] === 1 && RACERS.includes(bet.racers[0]))
      ) {
        return "top";
      }

      if (
        (bet.racers[0] === 6 && bet.racers[1] === 5) ||
        (bet.racers[1] === 6 && RACERS.includes(bet.racers[0]))
      ) {
        return "bottom";
      }
    }

    return "center-center";
  }

  if (content === "v3") {
    if (bet.places[0] === 1 && bet.racers[0] === 1 && bet.type === "personal") {
      return "left-top";
    }

    if (bet.places[0] === 6 && bet.racers[0] === 1 && bet.type === "personal") {
      return "right-top";
    }

    if (bet.places[0] === 1 && bet.racers[0] === 6 && bet.type === "personal") {
      return "left-bottom";
    }

    if (bet.places[0] === 6 && bet.racers[0] === 6 && bet.type === "personal") {
      return "right-bottom";
    }

    if (bet.racers[0] === 1 && bet.type === "top2") {
      return "left-top";
    }

    if (bet.racers[0] === 1 && bet.type === "top3") {
      return "right-top";
    }

    if (bet.racers[0] === 6 && bet.type === "top2") {
      return "left-bottom";
    }

    if (bet.racers[0] === 6 && bet.type === "top3") {
      return "right-bottom";
    }

    if (bet.type === "competition" && bet.racers[0] === 1 && bet.racers[1] === 6) {
      return "right-top";
    }

    if (bet.type === "competition" && bet.racers[0] === 6 && bet.racers[1] === 1) {
      return "left-bottom";
    }

    if (bet.type === "sumtop2" && bet.racers[0] === 3) {
      return "left-top";
    }

    if (bet.type === "sumtop3" && bet.racers[0] === 15) {
      return "right-bottom";
    }

    return "center-center";
  }

  return "center-center";
};

export const getFixed = (rate: number, index: number) => {
  const rates = rate.toFixed(2).split(".");
  const value = rates[index] || "0";
  return value;
};

import React from "react";

import { animated } from "@react-spring/web";

import { useDefault } from "./hooks";

import Speedometer from "src/components/speedometer/assets/images/desktop/speedometer.png";

export const DefaultComponent = () => {
  const { getGradientProps, getIndicatorProps, getPathProps, values } = useDefault();
  return (
    <div className={values.styles.speedometer}>
      <svg
        className={values.styles.svg}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 465 265"
      >
        <defs>
          <linearGradient id="speedometer-gradient" gradientTransform="rotate(2.8)">
            <stop {...getGradientProps()} offset="0%" />
            <stop {...getGradientProps()} offset="71%" />
            <stop stopColor="#D02D3C" offset="72%" />
          </linearGradient>
          <clipPath id="shape" transform="translate(-25, -25)">
            <path
              d="M109.17 55H140.14L143.71 15H112.74L109.18 55H109.17ZM145.13 55H176.1L179.66 15H148.69L145.13 55ZM45.59 23.62C45.59 23.62 30.99 44.67 24.58 53.84C22.62 56.65 21.41 59.91 21.08 63.33C20.36 70.7 19.22 83 19.22 83L74.2 83.09C77.5 67.5 90.5 56.5 104.16 55.31L107.73 15.01H62.02C55.46 15.01 49.31 18.23 45.58 23.63L45.59 23.62ZM6.46999 203.82L61.34 203.91L67.13 149.13L12.35 149.04L6.46999 203.82ZM1.00002 254C0.480019 259.85 5.05 265 10.92 265H45.85C51.03 265 55.3501 260.69 55.8101 255.54L60.89 208.91L6.02004 208.82L1.00002 254ZM12.79 144.03L67.57 144.12L73.47 88.08L18.78 87.99L12.79 144.03ZM181.09 55H212.06L215.62 15H184.65L181.08 55H181.09ZM360.9 55H391.87L396.77 0H365.8L360.9 55ZM334.47 15H328.5L324.94 55H355.91L360.81 0H355.96C352.87 8.79 344.36 15 334.46 15H334.47ZM288.98 55H319.95L323.52 15H292.55L288.98 55ZM217.06 55H248.03L251.59 15H220.62L217.05 55H217.06ZM253.02 55H283.99L287.56 15H256.59L253.02 55Z"
              fill="#151C32"
            />
            <path
              d="M109.17 55H140.14L143.71 15H112.74L109.18 55H109.17ZM145.13 55H176.1L179.66 15H148.69L145.13 55ZM45.59 23.62C45.59 23.62 30.99 44.67 24.58 53.84C22.62 56.65 21.41 59.91 21.08 63.33C20.36 70.7 19.22 83 19.22 83L74.2 83.09C77.5 67.5 90.5 56.5 104.16 55.31L107.73 15.01H62.02C55.46 15.01 49.31 18.23 45.58 23.63L45.59 23.62ZM6.46999 203.82L61.34 203.91L67.13 149.13L12.35 149.04L6.46999 203.82ZM1.00002 254C0.480019 259.85 5.05 265 10.92 265H45.85C51.03 265 55.3501 260.69 55.8101 255.54L60.89 208.91L6.02004 208.82L1.00002 254ZM12.79 144.03L67.57 144.12L73.47 88.08L18.78 87.99L12.79 144.03ZM181.09 55H212.06L215.62 15H184.65L181.08 55H181.09ZM360.9 55H391.87L396.77 0H365.8L360.9 55ZM334.47 15H328.5L324.94 55H355.91L360.81 0H355.96C352.87 8.79 344.36 15 334.46 15H334.47ZM288.98 55H319.95L323.52 15H292.55L288.98 55ZM217.06 55H248.03L251.59 15H220.62L217.05 55H217.06ZM253.02 55H283.99L287.56 15H256.59L253.02 55Z"
              fill="url(#paint0_linear_2879_135709)"
              fillOpacity="0.1"
            />
            <path d="M466.01 55V0H401.76L396.86 55H466.01Z" fill="#151C32" />
            <path
              d="M466.01 55V0H401.76L396.86 55H466.01Z"
              fill="url(#paint1_linear_2879_135709)"
              fillOpacity="0.1"
            />
          </clipPath>
        </defs>

        <g>
          <image xlinkHref={Speedometer} />
        </g>

        <symbol id="filler" clipPath="url(#shape)">
          <animated.g transform="translate(-45, -40)" {...getPathProps()}>
            <path
              d="M486 45.5C342.036 45.5 258.588 45.5 130.052 45.5C96.3581 45.5 68.2379 71.2464 65.2887 104.811L49.5 284.5"
              stroke="url(#speedometer-gradient)"
              strokeWidth="80"
              pathLength="1"
            />
          </animated.g>
        </symbol>

        <use xlinkHref="#filler" x="26.5" y="27.5" />

        <symbol id="indicator">
          <animated.g transform="translate(-45, -40)" {...getPathProps()}>
            <animated.rect
              className={values.styles.rect}
              fill="white"
              height="5"
              width="60"
              x="-28"
              y="-2"
              {...getIndicatorProps()}
            />
          </animated.g>
        </symbol>

        <g>
          <use xlinkHref="#indicator" x="26.5" y="27.5" />
        </g>
      </svg>
    </div>
  );
};

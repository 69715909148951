import React from "react";

import { usePlayer } from "./hooks";

import styles from "./style.module.scss";

export const PlayerComponent = React.memo(() => {
  const { values } = usePlayer();
  return <div className={styles[values.content]} id="player" />;
});

PlayerComponent.displayName = "PlayerComponent";

import filter from "lodash/filter";
import find from "lodash/find";
import get from "lodash/get";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import range from "lodash/range";
import some from "lodash/some";

import { v4 } from "uuid";

import {
  Bet,
  Car,
  Game,
  MessageCarFinish,
  MessageCarProgress,
  MessageInit,
  MessageRaceFinish,
  MessageStart,
  MessageStartExpected,
  MessageWin,
  Odds,
  Type,
} from "src/types";
import { betsAdapter, carsAdapter, gamesAdapter } from "./adapters";
import { betsSelectors, carsSelectors, gamesSelectors } from "./selectors";
import { CaseReducer, PayloadAction, Update } from "@reduxjs/toolkit";
import { getContractor } from "src/utils";
import { RACERS } from "src/constants";
import { State } from "./types";

/**
 * Функция формирования тела для запроса
 * @param bets - массив исходов
 * @returns - тело для запроса подтверждения исходов
 */
export const getBody = (odds: Array<Bet>, gameId: number) => {
  const bets = odds.map((bet) => {
    const result: Record<string, unknown> = {
      amount: bet.amount,
      rate: bet.rate,
      type: bet.type,
      balanceId: bet.balanceId,
    };

    switch (bet.type) {
      case "sumtop2":
      case "sumtop3":
      case "top1":
      case "top2":
      case "top3": {
        const [first] = bet.racers;
        if (first) {
          result["line"] = first;
        }
        break;
      }
      case "competition": {
        const [first, second] = bet.racers;
        if (first && second) {
          result["line"] = first;
          result["second"] = second;
        }
        break;
      }
      case "personal": {
        const [first] = bet.racers;
        const [place] = bet.places;
        if (first && place) {
          result["line"] = first;
          result["second"] = place;
        }
        break;
      }
      default:
        break;
    }

    return result;
  });

  const contractor = getContractor();

  const body = {
    bets,
    contractor,
    game_id: gameId,
    token: sessionStorage.getItem("token") || "",
    uuid: v4(),
  };

  return body;
};

/**
 * Функция получения гонщиков
 * @param type - тип ставки
 * @param first - первый гонщик
 * @param second - второй гонщик
 */
export const getRacers = (type: Type, first?: number, second?: number) => {
  switch (type) {
    case "bottom":
      return [1, 2, 3];
    case "even":
      return [2, 4, 6];
    case "odd":
      return [1, 3, 5];
    case "upper":
      return [4, 5, 6];
    case "sumtop2":
    case "sumtop3":
    case "top1":
    case "top2":
    case "top3":
      return [first || 0];
    case "competition":
      return [first || 0, second || 0];
    case "personal":
      return [first || 0];
    default:
      return [];
  }
};

/**
 * Функция получения призовых мест
 * @param type - тип ставки
 * @param first - первый гонщик
 * @param second - второй гонщик
 */
export const getPlaces = (type: Type, first?: number, second?: number) => {
  switch (type) {
    case "personal":
      return [second || 0];
    default:
      return [];
  }
};

/**
 * Функция генерации хэшкода ставки
 * @param value - значение
 * @returns - uuid v5
 */
export const getHashCode = (
  gameId: number,
  type: Type,
  places: Array<number>,
  racers: Array<number>
) => {
  const value = `${gameId}${type}${places}${racers}`;

  let hash = 0;

  for (let i = 0; i < value.length; i++) {
    const code = value.charCodeAt(i);
    hash = (hash << 5) - hash + code;
    hash = hash & hash; // Convert to 32bit integer
  }

  return hash;
};

/**
 * Функция генерации исхода
 * @param type - тип ставки
 * @param rate - коэффициент
 * @param gameId - идентификатор игры
 * @param racers - массив гонщиков в исходе
 * @returns - сгенерированный исход
 */
export const getBet = (
  type: Type,
  rate: number,
  gameId: number,
  places: Array<number>,
  racers: Array<number>
): Bet => ({
  added: 0,
  amount: 0,
  balanceId: 0,
  gameId,
  hashCode: getHashCode(gameId, type, places, racers),
  id: 0,
  limit: false,
  places,
  racers,
  rate,
  status: "none",
  type,
  updated: 0,
  uuid: v4(),
  win: 0,
});

/**
 * Функция получения исходов из коэффициентов
 * @param odds - коэффициенты
 * @param prev - предыдущие коэффициенты
 * @param gameId - идентификатор игры
 * @returns - массив исходов
 */
export const getBets = (
  gameId: number,
  odds: Odds | null,
  init: MessageInit["payload"]["bets"]
) => {
  const bets: Array<Bet> = [];

  RACERS.forEach((second, secondIndex) => {
    RACERS.forEach((first, firstIndex) => {
      if (secondIndex !== firstIndex) {
        const odd = get(odds, ["competition", firstIndex, secondIndex], 0);
        const places = [] as Array<number>;
        const racers = getRacers("competition", first, second);
        bets.push(getBet("competition", odd, gameId, places, racers));
      }
    });
  });

  RACERS.forEach((racer, racerIndex) => {
    RACERS.forEach((place, placeIndex) => {
      const odd = get(odds, ["personal", racerIndex, placeIndex], 0);
      const places = getPlaces("personal", 0, place);
      const racers = getRacers("personal", racer, 0);
      bets.push(getBet("personal", odd, gameId, places, racers));
    });
  });

  const ODDS: Array<"bottom" | "even" | "odd" | "upper"> = [
    "bottom",
    "even",
    "odd",
    "upper",
  ];

  ODDS.forEach((type) => {
    const odd = get(odds, [type], 0);
    const places = [] as Array<number>;
    const racers = getRacers(type, 0, 0);
    bets.push(getBet(type, odd, gameId, places, racers));
  });

  const TOPS: Array<Type> = ["top1", "top2", "top3"];

  TOPS.forEach((type) => {
    RACERS.forEach((first, firstIndex) => {
      const odd = get(odds, [type, firstIndex], 0);
      const places = [] as Array<number>;
      const racers = getRacers(type, first, 0);
      bets.push(getBet(type, odd, gameId, places, racers));
    });
  });

  range(3, 12).forEach((sum) => {
    const odd = get(odds, ["sumtop2", sum], 0);
    const places = [] as Array<number>;
    const racers = getRacers("sumtop2", sum, 0);
    bets.push(getBet("sumtop2", odd, gameId, places, racers));
  });

  range(6, 16).forEach((sum) => {
    const odd = get(odds, ["sumtop3", sum], 0);
    const places = [] as Array<number>;
    const racers = getRacers("sumtop3", sum, 0);
    bets.push(getBet("sumtop3", odd, gameId, places, racers));
  });

  if (init) {
    init.forEach((value) => {
      const gameId = value.game_id;
      const type = value.type;

      const racers = getRacers(value.type, value.line, value.second);
      const places = getPlaces(value.type, value.line, value.second);

      const hashCode = getHashCode(gameId, type, places, racers);

      const bet = bets.find((bet) => bet.hashCode === hashCode);

      if (bet) {
        bet.amount = value.amount;
        bet.balanceId = value.balance_id;
        bet.id = value.id;
        bet.status = "confirmed";
        bet.rate = value.rate;
      }
    });
  }

  return bets;
};

/**
 * Функция генерации машинки
 * @param gameId - идентификатор игры
 * @param line - номер линии
 * @returns сгенерированная машинка
 */
export const getCar = (gameId: number, line: number): Car => ({
  gameId,
  line,
  ms: 0,
  position: 0,
  progress: 0,
  status: "none",
  uuid: v4(),
});

/**
 * Функция получения машинок игры
 * @param gameId - идентификатор игры
 * @returns массив машинок
 */
export const getCars = (gameId: number) => {
  const cars: Array<Car> = [];

  RACERS.forEach((racer) => {
    cars.push(getCar(gameId, racer));
  });

  return cars;
};

/**
 * Функция добавления коэффициентов в хранилище
 * @param state - хранилище core
 * @param gameIds - идентификаторы игр
 * @param odds - коэффициенты
 * @param init - инициализация ставок
 */
const addBets = (
  state: State,
  gameIds: Array<number>,
  odds: Array<Odds | null>,
  init: MessageInit["payload"]["bets"]
) => {
  gameIds.forEach((gameId, index) => {
    const bets = getBets(gameId, odds[index], init);

    betsAdapter.addMany(state.bets, bets);
  });
};

/**
 * Функция добавления гонщиков в хранилище
 * @param state - хранилище core
 * @param gameIds - идентификаторы игр
 * @param odds - коэффициенты
 * @param init - инициализация ставок
 */
const addCars = (state: State, gameIds: Array<number>) => {
  gameIds.forEach((gameId) => {
    const cars = getCars(gameId);

    carsAdapter.addMany(state.cars, cars);
  });
};

/**
 * Функция добавления игр в хранилище
 * @param state - хранилище core
 * @param gameIds - идентификаторы игр
 * @param odds - коэффициенты
 * @param init - инициализация ставок
 */
const addGames = (state: State, gameIds: Array<number>) => {
  gameIds.forEach((gameId) => {
    const game: Game = {
      id: gameId,
      expect: 0,
      status: "pending",
      win: 0,
    };

    gamesAdapter.addOne(state.games, game);
  });
};

/**
 * Функция инициализации статуса
 * @param state - хранилище core
 */
const initStatus = (state: State, message: MessageInit) => {
  const playId = message.game_id;

  const nextId = playId + 1;

  const status = message.payload.status;

  const play: Update<Game> = { id: playId, changes: {} };
  const next: Update<Game> = { id: nextId, changes: {} };

  switch (status) {
    case "abort": {
      state.kind = "abort";
      play.changes.status = "abort";
      break;
    }
    case "tech_break": {
      state.kind = "tech_break";
      play.changes.status = "tech_break";
      break;
    }
    case "normal": {
      const now = new Date().getTime();
      const expect = new Date(message.payload.start_expected).getTime();
      if (now - expect < 0) {
        state.kind = "start_expected";
        play.changes.status = "finished";
        next.changes.status = "expected";
        next.changes.expect = expect;
      } else {
        state.kind = "init";
        play.changes.status = "unknown";
      }
      break;
    }
  }

  const isTechBreakExpected = message.payload.tech_break_expected;

  if (isTechBreakExpected) {
    next.changes.status = "tech_break";
  }

  gamesAdapter.updateMany(state.games, [play, next]);
};

/**
 * Обработка сообщения init
 * @param state - хранилище core
 * @param action - данные сообщения init
 */
export const initHandler: CaseReducer<State, PayloadAction<MessageInit>> = (
  state,
  action
) => {
  const playId = action.payload.game_id;
  const limits = action.payload.payload.settings.betLimits;
  const init = action.payload.payload.bets;
  const odds = action.payload.payload.odds;
  const prev = action.payload.payload.prev_odds;

  const nextId = playId + 1;
  const lastId = nextId + 1;

  addBets(state, [playId, nextId], [prev, odds, null], init);
  addCars(state, [playId, nextId, lastId]);
  addGames(state, [playId, nextId, lastId]);

  initStatus(state, action.payload);

  state.limits = limits;
  state.gameId = playId;
};

/**
 * Функция добавления ставок для повтора
 * @param state - хранилище core
 */
const setPrev = (state: State) => {
  const bets = betsSelectors.selectAll(state);

  const pending = filter(bets, { status: "pending" });
  const confirmed = filter(bets, { status: "confirmed" });

  if (pending.length || confirmed.length) {
    betsAdapter.setAll(state.prev, [...pending, ...confirmed]);
  }
};

/**
 * Функция очистки выбранных ставок
 * @param state - хранилище core
 */
const clearSelected = (state: State) => {
  const bets = betsSelectors.selectAll(state);

  const selected = filter(bets, { status: "selected" });

  selected.forEach((bet) => {
    const update: Update<Bet> = { id: bet.uuid, changes: {} };
    update.changes.status = "none";
    betsAdapter.updateOne(state.bets, update);
  });
};

/**
 * Функция запуска следующей игры
 * @param state - хранилище core
 * @param message - данные сообщения start
 */
const startGame = (state: State, message: MessageStart) => {
  const isTechBreakExpected = message.tech_break_expected;
  const playId = message.game_id;

  const nextId = playId + 1;

  const play: Update<Game> = { id: playId, changes: {} };
  const next: Update<Game> = { id: nextId, changes: {} };

  play.changes.status = "started";

  if (isTechBreakExpected) {
    next.changes.status = "tech_break";
  }

  gamesAdapter.updateMany(state.games, [play, next]);
};

/**
 * Обработка сообщения start
 * @param state - хранилище core
 * @param action - данные сообщения start
 */
export const startHandler: CaseReducer<State, PayloadAction<MessageStart>> = (
  state,
  action
) => {
  const playId = action.payload.game_id;
  const odds = action.payload.payload.odds;

  const nextId = playId + 1;
  const lastId = nextId + 1;

  addBets(state, [nextId], [odds], []);
  addCars(state, [lastId]);
  addGames(state, [lastId]);

  setPrev(state);

  clearSelected(state);

  startGame(state, action.payload);

  state.undo = [];
  state.gameId = playId;
  state.kind = "start";
};

/**
 * Обработка сообщения race_start
 * @param state - хранилище core
 * @param action - данные сообщения race_start
 */
export const raceStartHandler: CaseReducer<State> = (state) => {
  const playId = state.gameId;

  const update: Update<Game> = { id: playId, changes: {} };
  update.changes.status = "started";
  gamesAdapter.updateOne(state.games, update);

  state.kind = "race_start";
};

/**
 * Функция обновления позиций гонщиков
 * @param state - хранилище core
 * @param progress - прогресс гонщиков
 */
const updateCars = (
  state: State,
  progress: MessageCarProgress["payload"]["progress"]
) => {
  const playId = state.gameId;

  const cars = carsSelectors.selectAll(state);

  cars.forEach((car) => {
    if (car.gameId === playId) {
      const data = find(progress, { line: car.line });

      if (data) {
        const progress = data.progress;
        const update: Update<Car> = { id: car.uuid, changes: {} };
        update.changes.progress = progress;
        update.changes.status = progress === 100 ? "finish" : "progress";
        carsAdapter.updateOne(state.cars, update);
      }
    }
  });
};

/**
 * Функция сортировки позиций гонщиков
 * @param state - хранилище core
 * @param step - счетчик сообщения car_progress
 */
const sortCars = (state: State, step: number) => {
  const playId = state.gameId;

  const cars = carsSelectors.selectAll(state);

  if (step % 5 === 0) {
    const played = filter(cars, { gameId: playId });
    const sorted = orderBy(played, ["progress", "ms"], ["desc", "asc"]);

    sorted.forEach((car, index) => {
      const update: Update<Car> = { id: car.uuid, changes: {} };
      update.changes.position = index + 1;
      carsAdapter.updateOne(state.cars, update);
    });
  }
};

/**
 * Функция обновления статуса игры во время гонки
 * @param state - хранилище core
 * @param step - счетчик сообщения car_progress
 */
const updateGame = (
  state: State,
  progress: MessageCarProgress["payload"]["progress"]
) => {
  const playId = state.gameId;

  const isAnyFinished = some(progress, { progress: 100 });

  const update: Update<Game> = { id: playId, changes: {} };
  update.changes.status = isAnyFinished ? "finished" : "play";
  gamesAdapter.updateOne(state.games, update);
};

/**
 * Обработка сообщения car_progress
 * @param state - хранилище core
 * @param action - данные сообщения car_progress
 */
export const carProgressHandler: CaseReducer<
  State,
  PayloadAction<MessageCarProgress>
> = (state, action) => {
  const step = state.step;

  const progress = action.payload.payload.progress;

  updateCars(state, progress);
  sortCars(state, step);

  updateGame(state, progress);

  state.step = step + 1;
  state.kind = "car_progress";
};

/**
 * Функция финиша гонщика
 * @param state - хранилище core
 * @param line - номер гонщика
 * @param ms - время финиша
 */
const finishCar = (state: State, line: number, ms: number) => {
  const playId = state.gameId;

  const cars = carsSelectors.selectAll(state);

  cars.forEach((car) => {
    if (car.gameId === playId && car.line === line) {
      const update: Update<Car> = { id: car.uuid, changes: {} };
      update.changes.ms = ms;
      update.changes.progress = 100;
      update.changes.status = "finish";
      carsAdapter.updateOne(state.cars, update);
    }
  });
};

/**
 * Обработка сообщения car_finish
 * @param state - хранилище core
 * @param action - данные сообщения car_finish
 */
export const carFinishHandler: CaseReducer<
  State,
  PayloadAction<MessageCarFinish>
> = (state, action) => {
  const line = action.payload.payload.line;
  const ms = action.payload.payload.race_ms;

  finishCar(state, line, ms);

  sortCars(state, 5);
};

/**
 * Функция обновления позиций на финише
 * @param state - хранилище core
 * @param positions - позиции гонщиков
 */
const finishPositions = (
  state: State,
  positions: MessageRaceFinish["payload"]["line_pos"]
) => {
  const playId = state.gameId;

  if (positions) {
    const cars = carsSelectors.selectAll(state);

    cars.forEach((car) => {
      if (car.gameId === playId) {
        const update: Update<Car> = { id: car.uuid, changes: {} };
        update.changes.position = positions.indexOf(car.line) + 1;
        carsAdapter.updateOne(state.cars, update);
      }
    });
  }

  state.step = 1;
};

/**
 * Функция обновления ставок на финише
 * @param state - хранилище core
 */
const finishBets = (state: State) => {
  const playId = state.gameId;

  const bets = betsSelectors.selectAll(state);

  bets.forEach((bet) => {
    if (bet.gameId === playId) {
      switch (bet.status) {
        case "confirmed": {
          const update: Update<Bet> = { id: bet.uuid, changes: {} };
          update.changes.status = "finish";
          betsAdapter.updateOne(state.bets, update);
          break;
        }
      }
    }
  });
};

/**
 * Обработка сообщения race_finish
 * @param state - хранилище core
 * @param action - данные сообщения race_finish
 */
export const raceFinishHandler: CaseReducer<
  State,
  PayloadAction<MessageRaceFinish>
> = (state, action) => {
  const positions = action.payload.payload.line_pos;

  finishPositions(state, positions);
  finishBets(state);
};

/**
 * Функция финиша игры
 * @param state - хранилище core
 */
const finishGame = (state: State) => {
  const playId = state.gameId;

  const update: Update<Game> = { id: playId, changes: {} };
  update.changes.status = "finished";
  gamesAdapter.updateOne(state.games, update);
};

/**
 * Обработка сообщения finish
 * @param state - хранилище core
 * @param action - данные сообщения finish
 */
export const finishHandler: CaseReducer<State> = (state) => {
  finishGame(state);

  state.kind = "finish";
};

/**
 * Функция извлечения общей суммы по выигрышам
 * @param state - хранилище core
 * @param wins - выигрыши
 */
const getWin = (state: State, wins: MessageWin["payload"]) => {
  const playId = state.gameId;

  const win = wins.reduce((sum, bet) => bet.win + sum, 0);

  const update: Update<Game> = { id: playId, changes: {} };
  update.changes.win = win;
  gamesAdapter.updateOne(state.games, update);
};

/**
 * Функция извлечения выигрышей по ставкам
 * @param state - хранилище core
 * @param wins - выигрыши
 */
const getWins = (state: State, wins: MessageWin["payload"]) => {
  const map = wins.reduce((map, bet) => map.set(bet.bet_id, bet.win), new Map());

  const bets = betsSelectors.selectAll(state);

  bets.forEach((bet) => {
    switch (bet.status) {
      case "finish": {
        if (map.has(bet.id)) {
          const update: Update<Bet> = { id: bet.uuid, changes: {} };
          update.changes.updated = Date.now();
          update.changes.win = map.get(bet.id);
          betsAdapter.updateOne(state.bets, update);
        }
        break;
      }
    }
  });
};

/**
 * Обработка сообщения win
 * @param state - хранилище core
 * @param action - данные сообщения win
 */
export const winHandler: CaseReducer<State, PayloadAction<MessageWin>> = (
  state,
  action
) => {
  getWin(state, action.payload.payload);
  getWins(state, action.payload.payload);
};

/**
 * Функция перевода следующей игры в режим ожидания
 * @param state - хранилище core
 * @param expect - время ожидания
 */
const expectGame = (state: State, expect: number) => {
  const playId = state.gameId;

  const nextId = playId + 1;

  const next: Update<Game> = { id: nextId, changes: {} };
  next.changes.status = "expected";
  next.changes.expect = expect;
  gamesAdapter.updateOne(state.games, next);
};

/**
 * Функция очищения хранилища от старых данных
 * @param state - хранилище core
 */
const clearState = (state: State) => {
  const gameId = state.gameId;

  const games = gamesSelectors.selectAll(state);
  const bets = betsSelectors.selectAll(state);
  const cars = carsSelectors.selectAll(state);

  games.forEach((game) => {
    if (game.id < gameId) {
      const betsIds = map(filter(bets, { gameId: game.id }), "uuid");
      const carsIds = map(filter(cars, { gameId: game.id }), "uuid");

      gamesAdapter.removeOne(state.games, game.id);
      betsAdapter.removeMany(state.bets, betsIds);
      carsAdapter.removeMany(state.cars, carsIds);
    }
  });
};

/**
 * Обработка сообщения start_expected
 * @param state - хранилище core
 * @param action - данные сообщения start_expected
 */
export const startExpectedHandler: CaseReducer<
  State,
  PayloadAction<MessageStartExpected>
> = (state, action) => {
  const expect = new Date(action.payload.start_expected).getTime();

  finishGame(state);
  expectGame(state, expect);

  clearState(state);

  state.kind = "start_expected";
};

/**
 * Обработка сообщения abort
 * @param state - хранилище core
 * @param action - данные сообщения abort
 */
export const abortHandler: CaseReducer<State> = (state) => {
  const playId = state.gameId;

  const update: Update<Game> = { id: playId, changes: {} };
  update.changes.status = "abort";
  gamesAdapter.updateOne(state.games, update);

  state.kind = "abort";
};

/**
 * Обработка сообщения tech_break
 * @param state - хранилище core
 * @param action - данные сообщения tech_break
 */
export const techBreakHandler: CaseReducer<State> = (state) => {
  const playId = state.gameId;

  const update: Update<Game> = { id: playId, changes: {} };
  update.changes.status = "tech_break";
  gamesAdapter.updateOne(state.games, update);

  state.kind = "tech_break";
};

/**
 * Функция завершения тех. перерыва в игре
 * @param state - хранилище core
 */
const finishTechBreakGame = (state: State) => {
  const games = gamesSelectors.selectAll(state);

  games.forEach((game) => {
    if (game.status === "tech_break") {
      const update: Update<Game> = { id: game.id, changes: {} };
      update.changes.status = "unknown";
      gamesAdapter.updateOne(state.games, update);
    }
  });
};

/**
 * Обработка сообщения end_tech_break
 * @param state - хранилище core
 * @param action - данные сообщения end_tech_break
 */
export const endTechBreakHandler: CaseReducer<State> = (state) => {
  finishTechBreakGame(state);
  finishGame(state);

  state.kind = "end_tech_break";
};

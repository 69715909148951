import React from "react";

import { useSelector } from "react-redux";

import { amountActions } from "src/slices";
import { getAmount } from "src/selectors";
import { useAppDispatch } from "src/hooks";

import styles from "./style.module.scss";

export const useDefault = () => {
  const dispatch = useAppDispatch();

  const amount = useSelector(getAmount);

  const getAmountsProps = React.useCallback(
    () => ({
      onClick: (value: number) => dispatch(amountActions.amountUpdated(value)),
      value: amount,
    }),
    [amount, dispatch]
  );

  const values = React.useMemo(
    () => ({
      styles,
    }),
    []
  );

  return { getAmountsProps, values };
};

import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { AudioContext } from "src/contexts";
import { getGame, getLive } from "src/selectors";
import { mobileActions } from "src/slices";
import { useAppDispatch, useContent } from "src/hooks";

import styles from "./style.module.scss";

export const useHeader = () => {
  const content = useContent();

  const dispatch = useAppDispatch();

  const game = useSelector(getGame);
  const live = useSelector(getLive);

  const { play } = React.useContext(AudioContext);

  const getHeaderProps = React.useCallback(
    () => ({
      className: cn(styles[content]),
      onClick: () => {
        play("tab");
        dispatch(mobileActions.liveUpdated(!live));
      },
    }),
    [content, dispatch, live, play]
  );

  const values = React.useMemo(
    () => ({
      game,
      live,
    }),
    [game, live]
  );

  return { getHeaderProps, values };
};

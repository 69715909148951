import React from "react";

import { Amounts, Bet, Control, Step } from "src/components";
import { LimitMobile } from "src/components";
import { PopupError } from "./components";
import { useMobile } from "./hooks";

import styles from "./style.module.scss";

export const MobileComponent = React.memo(() => {
  const { getAmountsProps, getPanelProps, getPopupErrorProps, getStepProps } =
    useMobile();
  return (
    <div {...getPanelProps()}>
      <div className={styles.panel}>
        <LimitMobile />
        <PopupError {...getPopupErrorProps()} />
        <div className={styles.controls}>
          <Control type="double" />
          <Control type="repeat" />
        </div>
        <Step {...getStepProps()} />
        <Bet secondary />
      </div>
      <Amounts {...getAmountsProps()} />
    </div>
  );
});

MobileComponent.displayName = "MobileComponent";

import React from "react";

import { Animation, Bets } from "./components";
import { useBody } from "./hooks";

import styles from "./style.module.scss";

export const BodyComponent = React.memo(() => {
  const { getLabelProps, values } = useBody();

  let view;

  switch (values.content) {
    case "desktop": {
      view = (
        <div className={styles[values.content]}>
          <Animation />
          <div className={styles.content}>
            <span {...getLabelProps(styles.label)}>{values.win}</span>
          </div>
        </div>
      );
      break;
    }
    case "mobile":
    case "laptop":
    case "v3": {
      view = (
        <div className={styles[values.content]}>
          <Animation />
          <div className={styles.content}>
            <Bets />
          </div>
        </div>
      );
      break;
    }
    default:
      view = <div />;
      break;
  }

  return view;
});

BodyComponent.displayName = "BodyComponent";

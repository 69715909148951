import React from "react";

import { Cup, Line } from "src/shared/components";
import { Limit } from "src/components";
import { Odd } from "src/components";
import { RACERS } from "src/constants";
import { useDesktop } from "./hooks";

const CUPS = [
  <Cup key="cup1" type="cup1" variant="old" />,
  <Cup key="cup2" type="cup2" variant="old" />,
  <Cup key="cup3" type="cup3" variant="old" />,
  <Cup key="cup4" type="cup4" variant="old" />,
  <Cup key="cup5" type="cup5" variant="old" />,
  <Cup key="cup6" type="cup6" variant="old" />,
  <Cup key="cups2" type="cups2" variant="old" />,
  <Cup key="cups3" type="cups3" variant="old" />,
];

const LINES = RACERS.map((racer) => <Line key={racer} line={racer} />);

export const DesktopComponent = () => {
  const { values } = useDesktop();
  return (
    <>
      <div className={values.styles.market}>
        <div className={values.styles.body}>
          <div />
          <div className={values.styles.cups}>{CUPS}</div>
          <div className={values.styles.lines}>{LINES}</div>
          <div className={values.styles.data}>
            {values.results.map((result, index) => (
              <div key={index} className={values.styles.bets}>
                {result.map((bet, index) => (
                  <Odd key={index} odd={bet} />
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Limit market="result" types={["personal", "top2", "top3"]} />
    </>
  );
};

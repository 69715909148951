import React from "react";

import { animated } from "@react-spring/web";

import { Props } from "./types";
import { useStatusLaps } from "./hooks";

export const LapsComponent = (props: Props) => {
  const { getFinishProps, getLapProps, getLapsProps, getStartProps, values } =
    useStatusLaps(props);

  let content;

  switch (props.game.status) {
    case "started":
      content = (
        <div className={values.styles.title}>
          <span {...getStartProps()}>СТАРТ</span>
        </div>
      );
      break;
    case "play":
      content = (
        <>
          <span className={values.styles.label}>КРУГ</span>
          <div className={values.styles.lap}>
            {values.laps((style, lap) => (
              <animated.span {...getLapProps()} style={style}>
                {lap}
              </animated.span>
            ))}
          </div>
          <span className={values.styles.slash}>/</span>
          <span className={values.styles.total}>3</span>
        </>
      );
      break;
    case "finished":
      content = (
        <div className={values.styles.title}>
          <span {...getFinishProps()}>ФИНИШ</span>
        </div>
      );
      break;
    default:
      content = <div />;
      break;
  }

  return <div {...getLapsProps()}>{content}</div>;
};

LapsComponent.displayName = "LapsComponent";

import React from "react";

import { Limit } from "src/components";
import { Odd } from "src/components";
import { RACERS } from "src/constants";
import { useV3 } from "./hooks";

import Top2 from "src/assets/icons/cups-2-updated.svg";
import Top3 from "src/assets/icons/cups-3-updated.svg";

export const V3Component = () => {
  const { getButtonProps, getLimitProps, getMarketProps, values } = useV3();
  return (
    <div {...getMarketProps()}>
      <div className={values.styles.header}>
        <button {...getButtonProps("top2")}>
          <img src={Top2} />
        </button>
        <button {...getButtonProps("top3")}>
          <img src={Top3} />
        </button>
      </div>
      <div className={values.styles.body}>
        <div className={values.styles.bets}>
          {RACERS.map((racer) => (
            <React.Fragment key={racer}>
              <div>{values.top2[racer] && <Odd odd={values.top2[racer]} />}</div>
              <div>{values.top3[racer] && <Odd odd={values.top3[racer]} />}</div>
            </React.Fragment>
          ))}
          <div {...getLimitProps()}>
            <Limit market="tops" types={["top2", "top3"]} />
          </div>
        </div>
      </div>
    </div>
  );
};

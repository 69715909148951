import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { socketActions } from "src/actions";
import { State } from "./types";
import { View } from "src/types";

const STATE: State = {
  view: "curr",
};

export const couponsSlice = createSlice({
  name: "coupons",
  initialState: STATE,
  reducers: {
    viewUpdated: (state, action: PayloadAction<View>) => {
      state.view = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(socketActions.close, () => STATE);
  },
});

export const couponsActions = {
  viewUpdated: couponsSlice.actions.viewUpdated,
};

import React from "react";

import { BaseProps } from "src/types";
import { Context } from "./types";
import { useCouponsSwipe } from "./hooks";

export const CouponsSwipeContext = React.createContext<Context>({} as Context);

export const CouponsSwipeProvider = (props: BaseProps) => {
  const { getProviderProps, getWrapperProps } = useCouponsSwipe();
  return (
    <CouponsSwipeContext.Provider {...getProviderProps()}>
      <div {...getWrapperProps()}>{props.children}</div>
    </CouponsSwipeContext.Provider>
  );
};

import React from "react";
import cn from "classnames";

import { Bet } from "./components";
import { useBets } from "./hooks";

import styles from "./style.module.scss";

export const BetsComponent = React.memo(() => {
  const { getBetsProps, values } = useBets();
  return (
    <div
      {...getBetsProps(
        cn(styles[values.content], { [styles.small]: values.bets.length < 3 })
      )}
    >
      {values.bets.map((bet, index) => (
        <Bet key={index} bet={bet} />
      ))}
    </div>
  );
});

BetsComponent.displayName = "BetsComponent";

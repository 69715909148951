import React from "react";

import { useSelector } from "react-redux";

import { getGame } from "src/selectors";
import { useContent } from "src/hooks";

export const useBody = () => {
  const content = useContent();

  const game = useSelector(getGame);

  const values = React.useMemo(
    () => ({
      content,
      game,
    }),
    [content, game]
  );

  return { values };
};

import { Middleware, MiddlewareAPI } from "@reduxjs/toolkit";

import { metrikaActions, socketActions } from "src/actions";

export const commonMiddleware: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    const response = next(action);

    switch (action.type) {
      case socketActions.messageInit.type: {
        const version = action.payload.payload.version;
        api.dispatch(
          metrikaActions.send({ target: "version", params: { version } })
        );
        break;
      }
    }

    return response;
  };

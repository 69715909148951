import { betsAdapter, carsAdapter, gamesAdapter } from "./adapters";
import { State } from "./types";

export const betsSelectors = betsAdapter.getSelectors<State>((state) => state.bets);

export const carsSelectors = carsAdapter.getSelectors<State>((state) => state.cars);

export const gamesSelectors = gamesAdapter.getSelectors<State>(
  (state) => state.games
);

export const prevSelectors = betsAdapter.getSelectors<State>((state) => state.prev);

import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { getIsAllFinished } from "src/pages/desktop/model";
import { Props } from "./types";

import styles from "./style.module.scss";

import Cups1 from "src/assets/icons/cup-1.svg";
import Cups2 from "src/assets/icons/cup-2.svg";
import Cups3 from "src/assets/icons/cup-3.svg";

const CUPS: Record<number, string> = {
  1: Cups1,
  2: Cups2,
  3: Cups3,
};

export const useResult = (props: Props) => {
  const isAllFinished = useSelector(getIsAllFinished);

  const getCupProps = React.useCallback(
    () => ({
      className: cn(styles.cup),
      src: props.car.status === "finish" ? CUPS[props.car.position] : "",
    }),
    [props.car.position, props.car.status]
  );

  const getTimeProps = React.useCallback(
    () => ({
      className: cn(styles.time, {
        [styles.active]: props.car.status === "finish" && isAllFinished,
      }),
    }),
    [isAllFinished, props.car.status]
  );

  const [sc, ms] = useTime(props.car.ms);

  const values = React.useMemo(
    () => ({
      ms,
      sc,
      styles,
    }),
    [ms, sc]
  );

  return { getCupProps, getTimeProps, values };
};

export const useTime = (time: number) => {
  const date = new Date(time);

  const sc = `${date.getSeconds()}.`;
  const ms = String(Math.round(date.getMilliseconds() / 10)).padStart(2, "0");

  return [sc, ms];
};

import React from "react";

import { playerActions } from "src/actions";
import { useAppDispatch, useContent } from "src/hooks";

export const usePlayer = () => {
  const content = useContent();

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(playerActions.start());
    return () => {
      dispatch(playerActions.stop());
    };
  }, [dispatch]);

  const values = React.useMemo(
    () => ({
      content,
    }),
    [content]
  );

  return { values };
};

import React from "react";

import { useVersion } from "./hooks";

export const VersionComponent = React.memo(() => {
  const { getVersionProps, values } = useVersion();
  return (
    <div {...getVersionProps()}>
      <span>VERSION&nbsp;</span>
      <span>{values.version}</span>
    </div>
  );
});

VersionComponent.displayName = "VersionComponent";

import React from "react";

import { modalActions } from "src/slices";
import { useAppDispatch, useContent } from "src/hooks";

export const useFooter = () => {
  const content = useContent();

  const dispatch = useAppDispatch();

  const getButtonProps = React.useCallback(
    () => ({
      onClick: () => {
        dispatch(modalActions.modalUpdated({ modal: "live", value: false }));
      },
    }),
    [dispatch]
  );

  const values = React.useMemo(
    () => ({
      content,
    }),
    [content]
  );

  return { getButtonProps, values };
};

import React from "react";

import { useContent } from "src/hooks";

import styles from "./style.module.scss";

export const useGears = () => {
  const content = useContent();

  const values = React.useMemo(
    () => ({
      content,
      styles,
    }),
    [content]
  );

  return { values };
};

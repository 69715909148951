import React from "react";

import { YMInitializer } from "react-yandex-metrika";

import { AudioProvider } from "src/contexts";
import { Content, Loader } from "./components";
import { Modal, Tutorial } from "src/widgets";
import { useApp } from "./hooks";

const Desktop = React.lazy(() => import("src/pages/desktop"));
const Mobile = React.lazy(() => import("src/pages/mobile"));
const Laptop = React.lazy(() => import("src/pages/laptop"));
const V3 = React.lazy(() => import("src/pages/v3"));

export const AppComponent = React.memo(() => {
  const { values } = useApp();

  let content;

  switch (values.content) {
    case "desktop":
      content = <Desktop />;
      break;
    case "mobile":
      content = <Mobile />;
      break;
    case "laptop":
      content = <Laptop />;
      break;
    case "v3":
      content = <V3 />;
      break;
    default:
      break;
  }

  return (
    <AudioProvider>
      <Loader />
      <Tutorial />
      <Modal />
      <Content>
        <React.Suspense>{content}</React.Suspense>
      </Content>
      <YMInitializer accounts={[97592231]} />
    </AudioProvider>
  );
});

AppComponent.displayName = "AppComponent";

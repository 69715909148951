import { EntityId, EntityState } from "@reduxjs/toolkit";

import { Bet, Car, Error, Game, Kind, Market, Settings } from "src/types";

export interface State {
  bets: EntityState<Bet>;
  cars: EntityState<Car>;
  error: Error;
  gameId: number;
  games: EntityState<Game>;
  kind: Kind;
  limit: Limit;
  limits: Settings["betLimits"];
  loading: boolean;
  market: Market;
  prev: EntityState<Bet>;
  step: number;
  undo: Array<Array<Undo>>;
}

export type Limit = {
  bet: { uuid: EntityId } | null;
  uuid: string;
};

export type Undo = {
  amount: Bet["amount"];
  status: Bet["status"];
  uuid: EntityId;
};

export type Return = {
  bets: Bet[];
  gameId: number;
  ids: Array<number>;
};

export type Result = {
  bet_ids: Array<number>;
  game_id: number;
};

export type RPCResponse = {
  error: RPCError;
  data: unknown;
  result: Result;
};

export type RPCError = {
  code: RPCErrorCode;
  message: string;
};

export enum RPCErrorCode {
  ErrorLimit = 4,
  ErrorBalance = 2,
}

export type RejectValue = {
  bets: Array<Bet>;
  error: Error;
  gameId: number;
};

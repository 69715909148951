import React from "react";

import { useGears } from "./hooks";

import GearsLg from "src/assets/icons/gear-large.svg";
import GearsSm from "src/assets/icons/gear-small.svg";

export const GearsComponent = React.memo(() => {
  const { values } = useGears();
  return (
    <div className={values.styles[values.content]}>
      <img className={values.styles.large} src={GearsLg} />
      <img className={values.styles.small} src={GearsSm} />
    </div>
  );
});

GearsComponent.displayName = "GearsComponent";

import { createSelector } from "reselect";

import { createGetBetsByParams, getPrev } from "src/selectors";

export const createGetIsRepeatDisabled = (gameId: number) =>
  createSelector(
    [
      createGetBetsByParams(
        [gameId],
        [],
        ["confirmed", "finish", "pending", "selected"]
      ),
      getPrev,
    ],
    (bets, prev) => {
      for (const bet of prev) {
        let next;
        switch (bet.type) {
          case "personal":
            next = bets.find(
              ({ places, type, racers }) =>
                type === bet.type &&
                (racers[0] || 0) === bet.racers[0] &&
                (places[0] || 0) === bet.places[0]
            );
            break;
          case "sumtop2":
          case "sumtop3":
          case "top1":
          case "top2":
          case "top3":
            next = bets.find(
              ({ type, racers }) =>
                type === bet.type && (racers[0] || 0) === bet.racers[0]
            );
            break;
          case "bottom":
          case "even":
          case "odd":
          case "upper":
            next = bets.find(({ type }) => type === bet.type);
            break;
          default:
            next = bets.find(
              ({ type, racers }) =>
                type === bet.type &&
                (racers[0] || 0) === bet.racers[0] &&
                (racers[1] || 0) === bet.racers[1]
            );
            break;
        }
        if (!next) {
          return false;
        }
      }
      return true;
    }
  );

export const createGetIsDoubleDisabled = (gameId: number) =>
  createSelector(
    [createGetBetsByParams([gameId], [], ["selected"])],
    (bets) => bets.length === 0
  );

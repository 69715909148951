import React from "react";

import { Default, V3 } from "./views";
import { useContent } from "src/hooks";

export const MarketTopComponent = React.memo(() => {
  const content = useContent();

  let view;

  switch (content) {
    case "desktop":
      view = <Default />;
      break;
    case "v3":
      view = <V3 />;
      break;
    default:
      view = null;
  }

  return view;
});

MarketTopComponent.displayName = "MarketTopComponent";

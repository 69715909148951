import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { getLive, getTops } from "src/selectors";
import { mobileActions } from "src/slices";
import { useAppDispatch, useContent } from "src/hooks";

import styles from "./style.module.scss";

import Broadcast from "src/widgets/info/assets/images/mobile/broadcast.svg";
import Last from "src/widgets/info/assets/images/mobile/last.svg";

export const useHint = () => {
  const content = useContent();

  const dispatch = useAppDispatch();

  const live = useSelector(getLive);
  const tops = useSelector(getTops);

  const [hint, setHint] = React.useState(false);

  React.useEffect(() => {
    setHint(live);
  }, [live]);

  React.useEffect(() => {
    setHint(false);
  }, [tops]);

  const getHintProps = React.useCallback(
    () => ({
      className: cn(styles[content]),
      onClick: () => dispatch(mobileActions.topsUpdated(!tops)),
    }),
    [content, dispatch, tops]
  );

  const getImgProps = React.useCallback(
    () => ({
      className: cn(styles.img, {
        [styles.hide]: hint,
      }),
      src: tops ? Broadcast : Last,
    }),
    [hint, tops]
  );

  return { getHintProps, getImgProps };
};

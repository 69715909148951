import find from "lodash/find";

import { createSelector } from "reselect";

import { Bet } from "src/types";
import { getBets, getLimit, getSettings } from "src/selectors";

export const getBet = createSelector(
  [getBets, getLimit],
  (bets, limit) => find(bets, { uuid: limit.bet?.uuid }) as Bet | undefined
);

export const getIsLimit = createSelector(
  [getBet],
  (bet) => bet && bet.status === "selected"
);

export const getMax = createSelector([getBet, getSettings], (bet, settings) => {
  let result = 0;

  if (bet && bet.type) {
    result = settings.betLimits[bet.type].max;
  }

  return result;
});

import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { coreActions } from "src/slices";
import { createGetIsLimit, createGetMax } from "src/components/limit/model";
import { getGameId, getLimit, getTheme, getViewId } from "src/selectors";
import { Props } from "./types";
import { useAppDispatch, useContent } from "src/hooks";

import styles from "./style.module.scss";

export const useLimit = (props: Props) => {
  const dispatch = useAppDispatch();

  const content = useContent();

  const gameId = useSelector(getGameId);
  const limit = useSelector(getLimit);
  const theme = useSelector(getTheme);
  const viewId = useSelector(getViewId);

  const isLimit = useSelector(createGetIsLimit(props.types));
  const max = useSelector(createGetMax(props.market, props.types));

  React.useEffect(() => {
    let limitTimeout: NodeJS.Timeout;

    if (isLimit) {
      limitTimeout = setTimeout(() => dispatch(coreActions.limitRemoved()), 2000);
    }

    return () => {
      if (limitTimeout) {
        clearTimeout(limitTimeout);
      }
    };
  }, [dispatch, isLimit, limit.uuid]);

  const getLimitsProps = React.useCallback(
    () => ({
      className: cn(styles[content], styles[theme], {
        [styles.active]: isLimit,
        [styles.filter]: gameId === viewId,
      }),
      key: `${limit.uuid}`,
    }),
    [content, gameId, isLimit, limit, theme, viewId]
  );

  const values = React.useMemo(
    () => ({
      content,
      max,
    }),
    [content, max]
  );

  return { getLimitsProps, values };
};

import { configureStore } from "@reduxjs/toolkit";

import {
  amountSlice,
  contentSlice,
  coreSlice,
  couponsSlice,
  metaSlice,
  mobileSlice,
  modalSlice,
  tutorialSlice,
  v3Slice,
} from "./slices";
import {
  commonMiddleware,
  couponsMiddleware,
  loggerMiddleware,
  metrikaMiddleware,
  playerMiddleware,
  socketMiddleware,
  v3Middleware,
} from "./middlewares";
import { Logger, Metrika, Player, Socket } from "./services";

export const store = configureStore({
  reducer: {
    amount: amountSlice.reducer,
    content: contentSlice.reducer,
    core: coreSlice.reducer,
    coupons: couponsSlice.reducer,
    meta: metaSlice.reducer,
    mobile: mobileSlice.reducer,
    modal: modalSlice.reducer,
    tutorial: tutorialSlice.reducer,
    v3: v3Slice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(
      socketMiddleware(new Socket()),
      loggerMiddleware(new Logger()),
      metrikaMiddleware(new Metrika()),
      playerMiddleware(new Player()),
      commonMiddleware,
      couponsMiddleware,
      v3Middleware
    ),
});

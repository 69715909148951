import React from "react";

import { Cup, Line } from "src/shared/components";
import { Odd } from "src/components";
import { RACERS } from "src/constants";
import { useDefault } from "./hooks";

const CUPS = [
  <Cup key="cup1" type="cup1" variant="old" />,
  <Cup key="cups2" type="cups2" variant="old" />,
  <Cup key="cups3" type="cups3" variant="old" />,
];

const LINES = RACERS.map((racer) => <Line key={racer} line={racer} />);

export const DefaultComponent = () => {
  const { values } = useDefault();
  return (
    <div className={values.styles.market}>
      <div className={values.styles.body}>
        <div />
        <div className={values.styles.cups}>{CUPS}</div>
        <div className={values.styles.lines}>{LINES}</div>
        <div className={values.styles.bets}>
          {values.tops.map((bet, index) => (
            <Odd key={index} odd={bet} />
          ))}
        </div>
      </div>
    </div>
  );
};

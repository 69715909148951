import React from "react";

import { useSelector } from "react-redux";
import { useSpring, useTransition } from "@react-spring/web";

import { Config } from "./types";
import { getGameId, getNextId, getView } from "src/selectors";
import { useContent } from "src/hooks";

import styles from "./style.module.scss";

export const useViews = () => {
  const content = useContent();

  const gameId = useSelector(getGameId);
  const nextId = useSelector(getNextId);
  const view = useSelector(getView);

  const viewsTransition = useViewsTransition(gameId, nextId);

  const { transform } = useSpring({
    transform: `translateX(${view === "curr" ? 0 : -50}%)`,
    config: { tension: 500, friction: 80, mass: 5 },
  });

  const getViewsProps = React.useCallback(
    () => ({
      className: styles[content],
      style: { transform },
    }),
    [content, transform]
  );

  const values = React.useMemo(
    () => ({
      styles,
      views: viewsTransition,
    }),
    [viewsTransition]
  );

  return { getViewsProps, values };
};

export const useViewsTransition = (gameId: number, nextId: number) => {
  const [config, setConfig] = React.useState<Config>({
    clamp: false,
    friction: 80,
    mass: 5,
  });

  React.useEffect(() => {
    setConfig({ clamp: true, friction: 160, mass: 50 });
    setTimeout(() => setConfig({ clamp: false, friction: 80, mass: 5 }), 2000);
  }, [gameId]);

  const views = React.useMemo(
    () => (gameId && nextId ? [gameId, nextId] : []),
    [gameId, nextId]
  );

  return useTransition(
    views.map((viewId, index) => ({ id: viewId, index })),
    {
      keys: views.map((viewId) => viewId),
      from: ({ index }) => ({ x: `${index * 100 + 100}%` }),
      enter: ({ index }) => ({ x: `${index * 100}%` }),
      update: ({ index }) => ({ x: `${index * 100}%` }),
      leave: ({ index }) => ({ x: `${index * 100 - 100}%` }),
      config: { tension: 500, ...config },
    }
  );
};

import find from "lodash/find";

import { createSelector } from "reselect";

import { Bet, Market, Type } from "src/types";
import { getBets, getLimit, getSettings } from "src/selectors";

export const getBet = createSelector(
  [getBets, getLimit],
  (bets, limit) => find(bets, { uuid: limit.bet?.uuid }) as Bet | undefined
);

export const createGetIsLimit = (types: Array<Type>) =>
  createSelector(
    [getBet],
    (bet) => bet && bet.type && types.includes(bet.type) && bet.status === "selected"
  );

export const createGetMax = (market: Market, types: Array<Type>) =>
  createSelector([getBet, getSettings], (bet, settings) => {
    let result = 0;

    switch (market) {
      case "3-from-6": {
        const bottom = settings.betLimits.bottom.max;
        const upper = settings.betLimits.upper.max;
        const even = settings.betLimits.even.max;
        const odd = settings.betLimits.odd.max;
        result = Math.max(bottom, upper, even, odd);
        break;
      }
      case "tops": {
        const top2 = settings.betLimits.top2.max;
        const top3 = settings.betLimits.top3.max;
        result = Math.max(top2, top3);
        break;
      }
      case "competition":
        result = settings.betLimits.competition.max;
        break;
      case "result": {
        const personal = settings.betLimits.personal.max;
        const top2 = settings.betLimits.top2.max;
        const top3 = settings.betLimits.top3.max;
        result = Math.max(personal, top2, top3);
        break;
      }
      case "personal": {
        const personal = settings.betLimits.personal.max;
        result = Math.max(personal);
        break;
      }
      case "sum": {
        const sumtop2 = settings.betLimits.sumtop2.max;
        const sumtop3 = settings.betLimits.sumtop3.max;
        result = Math.max(sumtop2, sumtop3);
        break;
      }
    }

    if (bet && bet.type && types.includes(bet.type)) {
      result = settings.betLimits[bet.type].max;
    }

    return result;
  });

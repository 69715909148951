export const RACERS: Array<number> = [1, 2, 3, 4, 5, 6];
export const PLACES: Array<number> = [1, 2, 3, 4, 5, 6];
export const SUMS: Array<number> = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
export const BALANCES: Array<string> = ["", "₽", "B"];
export const LINES_COLOR: Record<number, string> = {
  1: "#7F15BD",
  2: "#DADBF3",
  3: "#E73C27",
  4: "#FFEC19",
  5: "#09060F ",
  6: "#39ADEF",
};

import React from "react";
import cn from "classnames";

import { useSpring, useSpringRef } from "@react-spring/web";

import { Props } from "./types";

import styles from "./style.module.scss";

import Car1 from "src/widgets/cars/assets/images/car-progress-1.svg";
import Car2 from "src/widgets/cars/assets/images/car-progress-2.svg";
import Car3 from "src/widgets/cars/assets/images/car-progress-3.svg";
import Car4 from "src/widgets/cars/assets/images/car-progress-4.svg";
import Car5 from "src/widgets/cars/assets/images/car-progress-5.svg";
import Car6 from "src/widgets/cars/assets/images/car-progress-6.svg";
import Wheel1 from "src/widgets/cars/assets/images/wheel-1.png";
import Wheel2 from "src/widgets/cars/assets/images/wheel-2.png";
import Wheel3 from "src/widgets/cars/assets/images/wheel-3.png";
import Wheel4 from "src/widgets/cars/assets/images/wheel-4.png";
import Wheel5 from "src/widgets/cars/assets/images/wheel-5.png";
import Wheel6 from "src/widgets/cars/assets/images/wheel-6.png";
import Wind1 from "src/widgets/cars/assets/images/car-wind-1.png";
import Wind2 from "src/widgets/cars/assets/images/car-wind-2.png";
import Wind3 from "src/widgets/cars/assets/images/car-wind-3.png";
import Wind4 from "src/widgets/cars/assets/images/car-wind-4.png";
import Wind5 from "src/widgets/cars/assets/images/car-wind-5.png";
import Wind6 from "src/widgets/cars/assets/images/car-wind-6.png";

const CARS: Record<number, string> = {
  1: Car1,
  2: Car2,
  3: Car3,
  4: Car4,
  5: Car5,
  6: Car6,
};

const WHEELS: Record<number, string> = {
  1: Wheel1,
  2: Wheel2,
  3: Wheel3,
  4: Wheel4,
  5: Wheel5,
  6: Wheel6,
};

const WIND: Record<number, string> = {
  1: Wind1,
  2: Wind2,
  3: Wind3,
  4: Wind4,
  5: Wind5,
  6: Wind6,
};

export const useProgress = (props: Props) => {
  const progressSpring = useProgressSpring(props);
  const wheelSpring = useWheelSpring(props);

  const getCarProgressProps = React.useCallback(
    () => ({
      className: cn(styles.progress),
      style: progressSpring,
    }),
    [progressSpring]
  );

  const getCabinProps = React.useCallback(
    () => ({
      className: cn(styles[`cabin-${props.car.line}`], {
        [styles.started]: props.car.status === "progress",
      }),
      src: CARS[props.car.line],
    }),
    [props.car.line, props.car.status]
  );

  const getWheelProps = React.useCallback(
    () => ({
      className: cn(styles.wheel),
      src: WHEELS[props.car.line],
      style: wheelSpring,
    }),
    [props.car.line, wheelSpring]
  );

  const getWindProps = React.useCallback(
    () => ({
      className: cn(styles[`wind-${props.car.line}`], {
        [styles.started]: props.car.status === "progress",
      }),
      src: WIND[props.car.line],
    }),
    [props.car.line, props.car.status]
  );

  return { getCarProgressProps, getCabinProps, getWheelProps, getWindProps };
};

export const useProgressSpring = (props: Props) => {
  const carApi = useSpringRef();
  const carSpring = useSpring({
    ref: carApi,
    from: { x: "0rem" },
    config: { duration: 1100 },
  });

  React.useEffect(() => {
    switch (props.car.status) {
      case "finish":
        carApi.start({
          to: async (next) => {
            await next({ to: { x: "12rem" }, config: { duration: 500 } });
          },
        });
        break;
      case "progress":
        carApi.start({
          to: async (next) => {
            await next({
              to: { x: `${(props.car.progress * 12) / 100}rem` },
              config: { duration: 1100 },
            });
          },
        });
        break;
      case "none":
        carApi.start({
          to: async (next) => {
            await next({ to: { x: "0rem" }, config: { duration: 0 }, delay: 250 });
          },
        });
        break;
      default:
        break;
    }
  }, [carApi, props.car.progress, props.car.status]);

  return carSpring;
};

export const useWheelSpring = (props: Props) => {
  const [round, setRound] = React.useState(1);

  const wheelApi = useSpringRef();
  const wheelSpring = useSpring({
    ref: wheelApi,
    from: { filter: "blur(0px)", transform: "rotateZ(0deg)" },
    config: { duration: 0 },
  });

  React.useEffect(() => {
    switch (props.car.status) {
      case "progress": {
        switch (round) {
          case 1: {
            wheelApi.start({
              to: async (next) => {
                await next({
                  filter: "blur(0px)",
                  transform: `rotateZ(${round * 360}deg)`,
                  config: { duration: 1500 },
                });
              },
              onRest: () => setRound((value) => value + 1),
            });
            break;
          }
          case 2: {
            wheelApi.start({
              to: async (next) => {
                await next({
                  filter: "blur(0px)",
                  transform: `rotateZ(${round * 360}deg)`,
                  config: { duration: 1000 },
                });
              },
              onRest: () => setRound((value) => value + 1),
            });
            break;
          }
          default: {
            wheelApi.start({
              to: async (next) => {
                await next({
                  filter: "blur(0.5px)",
                  transform: `rotateZ(${round * 360}deg)`,
                  config: { duration: 1000 },
                });
              },
              onRest: () => setRound((value) => value + 1),
            });
            break;
          }
        }
        break;
      }
      case "finish": {
        wheelApi.start({
          to: async (next) => {
            await next({
              filter: "blur(0px)",
              transform: `rotateZ(${round * 360}deg)`,
              config: { duration: 500 },
            });
          },
        });
        break;
      }
      default:
        setRound(1);
        break;
    }
  }, [wheelApi, props.car.status, round]);

  return wheelSpring;
};

import { createSelector } from "reselect";
import { EntityId } from "@reduxjs/toolkit";

import { Bet, Game, RootState } from "src/types";
import {
  betsSelectors,
  carsSelectors,
  gamesSelectors,
  prevSelectors,
} from "src/slices";

/**
 * Селекторы хранилища ядра
 */

/**
 * core
 * @param state - хранилище
 * @returns хранилище core
 */
const getRoot = (state: RootState) => state.core;

/**
 * Селектор ставок
 */
export const getBets = createSelector([getRoot], (root) =>
  betsSelectors.selectAll(root)
);

/**
 * Селектор машинок
 */
export const getCars = createSelector([getRoot], (root) =>
  carsSelectors.selectAll(root)
);

/**
 * Селектор ошибки
 */
export const getError = createSelector([getRoot], (root) => root.error);

/**
 * Селектор идентификатора текущей игры
 */
export const getGameId = createSelector([getRoot], (root) => root.gameId);

/**
 * Селектор очереди игр
 */
export const getGames = createSelector([getRoot], (root) =>
  gamesSelectors.selectAll(root)
);

/**
 * Селектор текущего состояния игры
 */
export const getKind = createSelector([getRoot], (root) => root.kind);

/**
 * Селектор лимита
 */
export const getLimit = createSelector([getRoot], (root) => root.limit);

/**
 * Селектор состояния загрузки ставок
 */
export const getLoading = createSelector([getRoot], (root) => root.loading);

/**
 * Селектор маркета
 */
export const getMarket = createSelector([getRoot], (root) => root.market);

/**
 * Селектор корзины
 */
export const getPrev = createSelector([getRoot], (root) =>
  prevSelectors.selectAll(root)
);

/**
 * Селектор id следующей игры
 */
export const getNextId = createSelector([getGameId], (gameId) => gameId + 1);

/**
 * Описание ошибок
 */
export const getErrorMessage = createSelector([getError], (error) => {
  switch (error) {
    case "error_limit": {
      return "Номиналы изменились";
    }
    case "error_net": {
      return "Попробуйте еще раз";
    }
    case "error_cash": {
      return "Недостаточно баланса";
    }
    default:
      return "";
  }
});

/**
 * Селектор последней активной игры
 */
export const getGame = createSelector(
  [getGameId, getGames, getKind, getNextId],
  (gameId, games, kind, nextId) => {
    const game = games.find((game) => game.id === gameId);
    const next = games.find((game) => game.id === nextId);
    return kind === "start_expected" ? next : game;
  }
);

/**
 * Генератор селектора получения игры по идентификатору
 * @param gameId - идентификатор игры
 * @returns - игра
 */
export const createGetGameById = (gameId: number) =>
  createSelector([getRoot], (root) => gamesSelectors.selectById(root, gameId));

/**
 * Генератор селектора проверки наличия игры по параметрам
 * @param gameId - идентификатор игры
 * @returns - игра
 */
export const createGetIsAnyGame = (
  gameIds: Array<Game["id"]>,
  statuses: Array<Game["status"]>
) =>
  createSelector([getGames], (games) =>
    games.some((game) => gameIds.includes(game.id) && statuses.includes(game.status))
  );

/**
 * Генератор селектора поиска ставки по идентификатору
 * @param uuid - идентификатор ставки
 * @returns - ставку
 */
export const createGetBetByUuid = (uuid: EntityId) =>
  createSelector([getRoot], (root) => betsSelectors.selectById(root, uuid));

/**
 * Генератор селектора поиска ставок по параметрам
 * @param gameIds - список идентификаторов игр
 * @param types - список типов ставок
 * @param statuses - список статусов ставок
 * @returns - массив ставок
 */
export const createGetBetsByParams = (
  gameIds: Array<Bet["gameId"]>,
  types: Array<Bet["type"]>,
  statuses: Array<Bet["status"]>
) =>
  createSelector([getBets], (bets) => {
    let result = [...bets];

    if (gameIds.length) {
      result = result.filter((bet) => gameIds.includes(bet.gameId));
    }
    if (types.length) {
      result = result.filter((bet) => types.includes(bet.type));
    }
    if (statuses.length) {
      result = result.filter((bet) => statuses.includes(bet.status));
    }

    return result;
  });

/**
 * Генератор селектора проверки наличия ставки по идентификатору игры и статусу
 * @param gameId - идентификатор игры
 * @param status - статус
 * @returns - флаг наличия
 */
export const createGetIsAnyBet = (gameId: number, status: Bet["status"]) =>
  createSelector([getBets], (bets) =>
    bets.some((bet) => bet.gameId === gameId && bet.status === status)
  );

/**
 * Генератор селектора получения машинок игры
 * @param id - идентификатор игры
 * @returns - массив ставок игры
 */
export const createGetCarsByGameId = (gameId: number) =>
  createSelector([getCars], (cars) => cars.filter((car) => car.gameId === gameId));

import React from "react";

import { Market, Background, Body, Header, Swipe } from "./components";
import { useMarkets } from "./hooks";

export const MarketsComponent = () => {
  const { getMarketsProps, getMarketsV3Props, values } = useMarkets();

  let view;

  switch (values.content) {
    case "desktop":
      view = (
        <div {...getMarketsProps()}>
          <Header markets={values.markets} />
          <Body>
            <Market market={values.market} />
          </Body>
        </div>
      );
      break;
    case "mobile":
    case "laptop":
      view = (
        <div {...getMarketsProps()}>
          <Header markets={values.markets} />
          <Body>
            <Swipe markets={values.markets}>
              {values.markets.map((market) => (
                <Market key={market} market={market} />
              ))}
            </Swipe>
          </Body>
        </div>
      );
      break;
    case "v3":
      view = (
        <div {...getMarketsV3Props()}>
          <Background />
          <div className={values.styles.titles}>
            <span>СОРЕВНОВАНИЕ</span>
            <span>РЕЗУЛЬТАТ</span>
          </div>
          <div className={values.styles.rows}>
            <Market market="result" />
            <Market market="tops" />
            <Market market="competition" />
            <Market market="sum" />
          </div>
        </div>
      );
      break;
    default:
      view = null;
      break;
  }

  return view;
};

import React from "react";

import { Statistics } from "src/components";
import { useBody } from "./hooks";

export const BodyComponent = () => {
  const { getBodyProps, values } = useBody();
  return (
    <div {...getBodyProps()}>
      <div className={values.styles.wrapper}>
        <Statistics />
      </div>
    </div>
  );
};

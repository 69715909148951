import React from "react";

import { animated } from "@react-spring/web";

import { Line } from "src/shared/components";
import { Props } from "./types";
import { usePositions } from "./hooks";

export const PositionsComponent = (props: Props) => {
  const { values } = usePositions(props);
  return (
    <div className={values.styles[values.content]}>
      {values.positions((style, position) => (
        <animated.div className={values.styles.position} style={style}>
          <Line line={position.line} />
        </animated.div>
      ))}
    </div>
  );
};

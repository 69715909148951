import React from "react";

import { useFooter } from "./hooks";

import styles from "./style.module.scss";

export const FooterComponent = React.memo(() => {
  const { getButtonProps, values } = useFooter();
  return (
    <div className={styles[values.content]}>
      <button {...getButtonProps(styles.button)} />
    </div>
  );
});

FooterComponent.displayName = "FooterComponent";

/* eslint-disable @typescript-eslint/ban-ts-comment */

// @ts-nocheck

/**
 * Run confetti without typescript
 */
export const initConfetti = (canvas) => {
  const confettiCount = 100;

  // "physics" variables
  const gravityConfetti = 0.1;
  const dragConfetti = 0.015;
  const terminalVelocity = 1;

  // init other global elements
  const ctx = canvas.getContext("2d");
  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;

  // add Confetto/Sequin objects to arrays to draw them
  const confetti = [];

  // colors, back side is darker for confetti flipping
  const colors = [
    { front: "#7b5cff", back: "#6245e0" }, // Purple
    { front: "#b3c7ff", back: "#8fa5e5" }, // Light Blue
    { front: "#5c86ff", back: "#345dd1" }, // Darker Blue
  ];

  // helper function to pick a random number within a range
  const randomRange = (min, max) => Math.random() * (max - min) + min;

  // helper function to get initial velocities for confetti
  // this weighted spread helps the confetti look more realistic
  const initConfettoVelocity = (xRange, yRange) => {
    const x = randomRange(xRange[0], xRange[1]);
    const range = yRange[1] - yRange[0] + 1;
    let y =
      yRange[1] - Math.abs(randomRange(0, range) + randomRange(0, range) - range);
    if (y >= yRange[1] - 1) {
      // Occasional confetto goes higher than the max
      y += Math.random() < 0.25 ? randomRange(1, 3) : 0;
    }
    return { x: x, y: -y };
  };

  // Confetto Class
  function Confetto() {
    this.randomModifier = randomRange(0, 99);
    this.color = colors[Math.floor(randomRange(0, colors.length))];
    this.dimensions = {
      x: randomRange(1, 19),
      y: randomRange(8, 15),
    };
    this.position = {
      x: randomRange(canvas.width / 2, canvas.width / 2),
      y: randomRange(canvas.height / 2, canvas.height / 2),
    };
    this.rotation = randomRange(0, 2 * Math.PI);
    this.scale = {
      x: 1,
      y: 1,
    };
    this.velocity = initConfettoVelocity([-19, 19], [6, 11]);
  }
  Confetto.prototype.update = function () {
    // apply forces to velocity
    this.velocity.x -= this.velocity.x * dragConfetti;
    this.velocity.y = Math.min(this.velocity.y + gravityConfetti, terminalVelocity);
    this.velocity.x += Math.random() > 0.5 ? Math.random() : -Math.random();

    // set position
    this.position.x += this.velocity.x;
    this.position.y += this.velocity.y;

    // spin confetto by scaling y and set the color, .09 just slows cosine frequency
    this.scale.y = Math.cos((this.position.y + this.randomModifier) * 0.09);
  };

  // add elements to arrays to be drawn
  const initBurst = () => {
    for (let i = 0; i < confettiCount; i++) {
      confetti.push(new Confetto());
    }
  };

  // draws the elements on the canvas
  const render = () => {
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    confetti.forEach((confetto) => {
      const width = confetto.dimensions.x * confetto.scale.x;
      const height = confetto.dimensions.y * confetto.scale.y;

      // move canvas to position and rotate
      ctx.translate(confetto.position.x, confetto.position.y);
      ctx.rotate(confetto.rotation);

      // update confetto "physics" values
      confetto.update();

      // get front or back fill color
      ctx.fillStyle =
        confetto.scale.y > 0 ? confetto.color.front : confetto.color.back;

      // draw confetto
      ctx.fillRect(-width / 2, -height / 2, width, height);

      // reset transform matrix
      ctx.setTransform(1, 0, 0, 1, 0, 0);
    });

    // must be done in seperate loops to avoid noticeable flickering
    confetti.forEach((confetto, index) => {
      if (confetto.position.y >= canvas.height) {
        confetti.splice(index, 1);
      }
    });

    window.requestAnimationFrame(render);
  };

  // kick off the render loop
  initBurst();
  render();
};

import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { AudioContext } from "src/contexts";
import { coreActions, createBetsThunk } from "src/slices";
import { createGetCount, createGetStatus } from "src/components/bet/model";
import { getDataTestId } from "src/utils";
import { getErrorMessage, getNextId, getTheme } from "src/selectors";
import { getLabel } from "./utils";
import { metrikaActions } from "src/actions";
import { Props } from "./types";
import { useAppDispatch, useContent } from "src/hooks";

import styles from "./style.module.scss";

import Spinner from "src/assets/icons/spinner.svg";

export const useStart = ({ secondary }: Props) => {
  const content = useContent();

  const dispatch = useAppDispatch();

  const message = useSelector(getErrorMessage);
  const nextId = useSelector(getNextId);
  const theme = useSelector(getTheme);

  const count = useSelector(createGetCount(nextId));
  const status = useSelector(createGetStatus(nextId));

  const { play } = React.useContext(AudioContext);

  const onClick = React.useCallback(async () => {
    play("bet");

    const result = await dispatch(createBetsThunk());

    switch (result.meta.requestStatus) {
      case "fulfilled":
        play("success");
        break;
      case "rejected":
        play("error");
        setTimeout(() => dispatch(coreActions.errorUpdated("none")), 4000);
        break;
    }

    dispatch(metrikaActions.send({ target: "BetClick" }));
  }, [dispatch, play]);

  const getButtonProps = React.useCallback(
    () => ({
      className: cn(styles[content], styles[theme], {
        [styles.secondary]: secondary,
        [styles.basic]: status === "basic",
        [styles.disabled]: status === "disabled",
        [styles.error]: status === "error",
        [styles.loading]: status === "loading",
      }),
      disabled: status === "disabled" || status === "error" || status === "loading",
      onClick,
      ...getDataTestId("button-bet"),
    }),
    [content, onClick, secondary, status, theme]
  );

  const getIconProps = React.useCallback(
    () => ({
      className: cn(styles.icon),
      src: Spinner,
      ...getDataTestId("button-bet-loader"),
    }),
    []
  );

  const getCountProps = React.useCallback(
    (testId: string) => ({
      className: cn(styles.count),
      ...getDataTestId(testId),
    }),
    []
  );

  const declinations = React.useMemo(() => {
    switch (content) {
      case "desktop":
        return ["СТАВОК", "СТАВКИ", "СТАВКА", "СТАВОК"];
      case "mobile":
      case "laptop":
        return ["СТАВОК", "СТАВКИ", "СТАВКУ", "СТАВОК"];
      case "v3":
        return ["СТАВОК", "СТАВКИ", "СТАВКА", "СТАВОК"];
      default:
        return [];
    }
  }, [content]);

  const single = React.useMemo(() => {
    switch (content) {
      case "desktop":
      case "v3":
        return "СТАВОК НЕТ";
      case "mobile":
      case "laptop":
        return "ВЫБЕРИТЕ СТАВКУ";
      default:
        return [];
    }
  }, [content]);

  const label = React.useMemo(
    () => getLabel(count, declinations),
    [count, declinations]
  );

  const values = React.useMemo(
    () => ({
      content,
      count,
      label,
      message,
      single,
      status,
      styles,
    }),
    [content, count, label, message, single, status]
  );

  return {
    getButtonProps,
    getCountProps,
    getIconProps,
    values,
  };
};

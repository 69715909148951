import React from "react";

import { useSelector } from "react-redux";

import { getDataTestId } from "src/utils";
import { getTheme } from "src/selectors";
import { tutorialActions } from "src/slices";
import { useAppDispatch, useContent } from "src/hooks";

export const useCombined = () => {
  const content = useContent();

  const dispatch = useAppDispatch();

  const theme = useSelector(getTheme);

  const getButtonProps = React.useCallback(
    (className: string) => ({
      className,
      onClick: () => dispatch(tutorialActions.tutorialUpdated(false)),
      ...getDataTestId("button-faq-close"),
    }),
    [dispatch]
  );

  const values = React.useMemo(
    () => ({
      content,
      theme,
    }),
    [content, theme]
  );

  return { getButtonProps, values };
};

import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { getDataTestId, getIsMonitor } from "src/utils";
import { getStatistics, getTheme } from "src/selectors";
import { LINES_COLOR } from "src/constants";
import { modalActions } from "src/slices";
import { useAppDispatch, useContent } from "src/hooks";

import Cup1 from "src/assets/icons/cup-1-updated.svg";
import Cup2 from "src/assets/icons/cup-2-updated.svg";
import Cup3 from "src/assets/icons/cup-3-updated.svg";

import styles from "./style.module.scss";

const CUPS: Record<number, string> = {
  0: Cup1,
  1: Cup2,
  2: Cup3,
};

export const useStatistics = () => {
  const content = useContent();

  const dispatch = useAppDispatch();

  const statistics = useSelector(getStatistics);
  const theme = useSelector(getTheme);

  const getStatisticsProps = React.useCallback(
    () => ({
      className: cn(styles[content], styles[theme]),
    }),
    [content, theme]
  );

  const getCupProps = React.useCallback(
    (place: number) => ({
      className: cn(styles.cup),
      src: CUPS[place],
    }),
    []
  );

  const getLineProps = React.useCallback(
    (line: number, race: number) => ({
      className: cn(styles.line),
      style: {
        background: LINES_COLOR[line],
        color: line % 2 === 0 ? "black" : "white",
      } as React.CSSProperties,
      ...getDataTestId(`race-${race}-results`),
    }),
    []
  );

  const isMonitor = getIsMonitor();

  const getFooterProps = React.useCallback(
    () => ({
      className: cn(styles.footer, {
        [styles.monitor]: isMonitor,
      }),
    }),
    [isMonitor]
  );

  const getLiveProps = React.useCallback(
    () => ({
      className: cn(styles.live, {
        [styles.visible]: isMonitor,
      }),
      onClick: () => {
        dispatch(modalActions.modalUpdated({ modal: "live", value: true }));
      },
    }),
    [dispatch, isMonitor]
  );

  const values = React.useMemo(
    () => ({
      statistics,
      styles,
    }),
    [statistics]
  );

  return {
    getCupProps,
    getFooterProps,
    getLineProps,
    getLiveProps,
    getStatisticsProps,
    values,
  };
};

import React from "react";

import { Default, Mobile, V3 } from "./view";
import { useContent } from "src/hooks";

export const InfoComponent = React.memo(() => {
  const content = useContent();

  let view = null;

  switch (content) {
    case "desktop":
      view = <Default />;
      break;
    case "mobile":
    case "laptop":
      view = <Mobile />;
      break;
    case "v3":
      view = <V3 />;
      break;
  }

  return view;
});

InfoComponent.displayName = "InfoComponent";

import React from "react";
import cn from "classnames";

import { BaseProps } from "src/types";
import { useContent } from "src/hooks";

import styles from "./style.module.scss";

export const useSvg = (props: BaseProps) => {
  const content = useContent();

  const getSvgProps = React.useCallback(
    () => ({
      className: cn(styles[content], props.className),
      width: "24",
      height: "26",
      viewBox: "0 0 24 26",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
    }),
    [content, props.className]
  );

  return { getSvgProps };
};

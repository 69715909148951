import React from "react";

import { Limit } from "src/components";
import { Odd } from "src/components";
import { PLACES, RACERS } from "src/constants";
import { Type } from "src/types";
import { useV3 } from "./hooks";

import Cup1 from "src/assets/icons/cup-1-updated.svg";
import Cup2 from "src/assets/icons/cup-2-updated.svg";
import Cup3 from "src/assets/icons/cup-3-updated.svg";
import Cup4 from "src/assets/icons/cup-4-updated.svg";
import Cup5 from "src/assets/icons/cup-5-updated.svg";
import Cup6 from "src/assets/icons/cup-6-updated.svg";
import Line1Cup0 from "src/assets/icons/line-1-cup-0.svg";
import Line2Cup0 from "src/assets/icons/line-2-cup-0.svg";
import Line3Cup0 from "src/assets/icons/line-3-cup-0.svg";
import Line4Cup0 from "src/assets/icons/line-4-cup-0.svg";
import Line5Cup0 from "src/assets/icons/line-5-cup-0.svg";
import Line6Cup0 from "src/assets/icons/line-6-cup-0.svg";

const CUPS_PLACES: Record<number, string> = {
  1: Cup1,
  2: Cup2,
  3: Cup3,
  4: Cup4,
  5: Cup5,
  6: Cup6,
};

const CUPS_LINES_: Record<number, string> = {
  1: Line1Cup0,
  2: Line2Cup0,
  3: Line3Cup0,
  4: Line4Cup0,
  5: Line5Cup0,
  6: Line6Cup0,
};

const LINES = (
  propsGetter: (
    type: Type,
    racer: number,
    mode: "places" | "racers"
  ) => React.ButtonHTMLAttributes<HTMLButtonElement>,
  mode: "places" | "racers"
) =>
  RACERS.map((racer) => (
    <button key={racer} {...propsGetter("personal", racer, mode)}>
      <img src={mode === "places" ? CUPS_PLACES[racer] : CUPS_LINES_[racer]} />
    </button>
  ));

export const V3Component = () => {
  const { getButtonProps, getLimitProps, getMarketProps, values } = useV3();
  return (
    <div {...getMarketProps()}>
      <div className={values.styles.header}>
        <div className={values.styles.empty} />
        <div className={values.styles.lines}>{LINES(getButtonProps, "places")}</div>
      </div>
      <div className={values.styles.body}>
        <div className={values.styles.lines}>{LINES(getButtonProps, "racers")}</div>
        <div className={values.styles.bets}>
          {RACERS.map((racer) =>
            PLACES.map((place) => (
              <div key={`${racer}${place}`}>
                {values.places[racer] && values.places[racer][place] && (
                  <Odd odd={values.places[racer][place]} />
                )}
              </div>
            ))
          )}
          <div {...getLimitProps()}>
            <Limit market="personal" types={["personal"]} />
          </div>
        </div>
      </div>
    </div>
  );
};

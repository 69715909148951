import React from "react";

import { animated } from "@react-spring/web";

import { Positions, Status, Total } from "src/components";
import { Props } from "./types";
import { useTab } from "./hooks";

export const TabComponent = (props: Props) => {
  const { getPositionsProps, getStatusProps, getTabProps, getTotalProps, values } =
    useTab(props);
  return (
    <animated.div {...getTabProps()}>
      <div className={values.styles.header}>
        <div {...getTotalProps()}>
          <Total game={props.game} />
        </div>
      </div>
      <div className={values.styles.body}>
        <div {...getPositionsProps()}>
          <Positions game={props.game} />
        </div>
        <div {...getStatusProps()}>
          <Status game={props.game} />
        </div>
      </div>
    </animated.div>
  );
};

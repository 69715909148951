import React from "react";

import { useSelector } from "react-redux";

import { createGetBetsByParams, getNextId } from "src/selectors";
import { sort } from "./utils";
import { useContent } from "src/hooks";

import styles from "./styles.module.scss";

export const useMobile = () => {
  const content = useContent();

  const nextId = useSelector(getNextId);

  const bets = useSelector(createGetBetsByParams([nextId], ["competition"], []));

  const values = React.useMemo(
    () => ({
      content,
      styles,
      competitions: sort(bets),
    }),
    [bets, content]
  );

  return { values };
};

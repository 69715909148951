import React from "react";

import { useLight } from "./hooks";

export const LightComponent = React.memo(() => {
  const { getLightProps } = useLight();
  return <div {...getLightProps()} />;
});

LightComponent.displayName = "LightComponent";

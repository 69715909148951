import React from "react";

import { usePending } from "./hooks";

export const PendingComponent = React.memo(() => {
  const { getPendingProps } = usePending();
  return (
    <div {...getPendingProps()}>
      <span>СЛЕДУЮЩАЯ ГОНКА</span>
      <span>ОТКРЫТ ПРИЕМ СТАВОК</span>
    </div>
  );
});

PendingComponent.displayName = "PendingComponent";

import { createSelector } from "reselect";

import { RootState } from "src/types";

/**
 * Селекторы общих данных и настроек
 */

/**
 * meta
 * @param state - хранилище
 * @returns хранилище meta
 */
const getRoot = (state: RootState) => state.meta;

/**
 * Селектор последнего сообщения
 */
export const getMessage = createSelector([getRoot], (root) => root.message);

/**
 * Селектор настроек
 */
export const getSettings = createSelector([getRoot], (root) => root.settings);

/**
 * Селектор статистики
 */
export const getStatistics = createSelector([getRoot], (root) => root.statistics);

/**
 * Селектор динамического номинала
 */
export const getDynamic = createSelector(
  [getSettings],
  (settings) => settings.betLimits.dynamic
);

import React from "react";

import { Abort, Live, Offline, TechBreak, Win } from "./components";
import { useModal } from "./hooks";

export const ModalComponent = React.memo(() => {
  const { values } = useModal();
  return (
    <>
      {values.modal.live && <Live />}
      {values.modal.win && <Win />}
      {values.modal.abort && <Abort />}
      {values.modal.tech_break && <TechBreak />}
      {values.modal.offline && <Offline />}
    </>
  );
});

ModalComponent.displayName = "ModalComponent";
